import React, {useState, useEffect} from 'react'
import { spliceCookie } from '../Components/CookieHandler';
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import { getCookie , setCookie, removeCookie} from './CookieHandler';



export default function Cart_product(props) {

    function Increment(index){
        var cookie  = JSON.parse(getCookie("products"));
    
        /* props.setHappendChange(props.happendChange == "xd" ? "d" : "xd") */
        var newArr  = [];
        var toRet   = 0;
        var updateCookie = cookie.map((obj,key)=>{
            if(key == index){
                obj.count++;
                toRet = obj.count;
                String(obj.count)
            }
            newArr.push(obj);
        })
        setCookie("products", JSON.stringify(newArr));
        return toRet
    }
    
    function Decrement(index){
        var cookie  = JSON.parse(getCookie("products"));
    
        /* props.setHappendChange(props.happendChange == "xd" ? "d" : "xd") */
        var newArr  = [];
        var toRet   = 0;
        var updateCookie = cookie.map((obj,key)=>{
            if(key == index){
                obj.count--;
                toRet = obj.count;
                String(obj.count)
            }
            newArr.push(obj);
        })
        setCookie("products", JSON.stringify(newArr));
        return toRet
    }
    const [count, setCount] = useState(null)
    const [reload, setReload] = useState("")

    useEffect(() => {
        setCount(props.count)
    }, []);

    function update(){
        /* setReload(()=> reload == "d" ? setReload("xd") : setReload("d")) */
        /* props.setHappendChange(()=>props.happendChange == "d" ? props.setHappendChange("xd") : props.setHappendChange("d")) */
        props.updateList();
        /* setReload(()=> reload == "d" ? setReload("xd") : setReload("d")) */
    }

   
    return (
        <div className="my-2 mx-1 py-2 z-50 ease-in-out w-full border-b border-black select-none">
            <div className="grid grid-cols-9 pb-5 sm:mx-0 mx-2">
                <div className="col-span-4 grid grid-rows-6 ">
                    <div className="row-span-5">
                        {/* <div className="w-[140px] h-[130px] col-span-1 bg-cover bg-center bg-no-repeat p-2" style={{backgroundImage: "url(" + props.img + ")"}} /> */}
                        <div className="sm:col-span-3 col-span-5 flex justify-start bg-gray-200 my-2 xl:h-[125px] xl:w-[135px] h-[115px] w-[125px]">
                            {/* <div className=" flex justify-center items-center sm:bg-cover bg-cover bg-no-repeat w-[140px] h-[130px] bg-center  " style={{backgroundImage: "url(" + props.img + ")"}}></div> */}
                            <img className="object-cover" src={props.img}></img>
                        </div>
                    </div>

                    <div className="row-span-1 flex justify-between items-end xl:w-[135px] w-[125px]">
                        <div className={"text-black border border-black rounded-full w-6 h-6 text-[12px] py-[2px] px-[5px] cursor-pointer hover:-translate-y-1 hover:scale-110" + (parseInt(props.price) * count <= 0 ? ' hidden' : '')} onClick={()=>{setCount(Decrement(props.id)); update();}}> <div className="py-1"><AiOutlineMinus /> </div></div>
                        <div className=""><p className="py-[2px] text-[15px] font-bold">{count}</p></div>
                        <div className={"text-black border border-black rounded-full w-6 h-6 text-[12px] py-[2px] px-[5px] cursor-pointer hover:-translate-y-1 hover:scale-110" + (parseInt(props.price) * count)} onClick={()=>{setCount(Increment(props.id)); update();}}> <div className="py-1"><AiOutlinePlus /> </div></div>
                    </div>

                </div>
                    <div className="col-span-3 py-1 xl:px-2">
                        <p className="font-semibold text-sm">{props.name}</p>
                    </div>

                    <div className="col-span-2 grid grid-rows-2">
                        <div className="row-span-1 flex justify-end items-start">
                            <div onClick={()=>{spliceCookie(props.id);update()}} className="py-2 cursor-pointer hover:-translate-y-1 hover:scale-110" ><p className="py-1"><BsTrash /> </p></div>    
                        </div>
                        <div className="row-span-1 flex justify-end items-end ">
                            <div className=" text-gray-600"><p>{(parseInt(props.price) ? parseInt(props.price) * count : "").toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, " ")} kr</p></div>
                        </div>
                    </div>
            </div>


        </div>
    )
}
