import {React, useRef, useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import { getCookie , setCookie} from '../../Components/CookieHandler';
import Std_Master from '../../Master/Std_Master'
import Enox_ipad_iphon from '../../Gfx/Enox_ipad_iphon.png'
import Enox_Kassa_1 from "../../Gfx/Enox_Kassa_1.png"
import Enox_mac_1 from '../../Gfx/Enox_mac_1.png'
import Kontakt_card from '../../Components/Kontakt_card';
import { BsPlusLg } from "react-icons/bs";
import Paket_Offert from '../../Components/Paket_Offert';

import test_1 from "../../Gfx/test_1.png";
import test_222 from "../../Gfx/test_222.png";
import test_333 from "../../Gfx/test_333.png";
import test_444 from "../../Gfx/test_444.png";
import test_666 from "../../Gfx/test_666.png";
import top_image_new from "../../Gfx/top_image_new.webp";
import test_666_mobile from "../../Gfx/test_666_mobile.png";
import AI_1 from "../../Gfx/AI_1.png";
import AI_2 from "../../Gfx/AI_2.png";
import dela_nota from "../../Gfx/dela_nota.png";
import bordskarta from "../../Gfx/bordskarta.png";
import lager from "../../Gfx/lager.png";


import Big_kassa_1 from '../../Gfx/Big_kassa_1.png'
import Kitchen_2 from '../../Gfx/Kitchen_2.png'
import Kvittoskrivare_1_new from '../../Gfx/Kvittoskrivare_1_new.png'

import logo_new_text_black from '../../Gfx/logo_new_text_black.png'

import logo_new_text_white from '../../Gfx/logo_new_text_white.png'
import logo_new_text from '../../Gfx/logo_new_text.png'


import CardModalOffers from "../../Components/CardModalOffers";
import DetailCardModalOffers from "../../Components/DetailCardModalOffers";

import westpay_c100_1 from "../../Gfx/westpay_c100_1.png";

import swal from 'sweetalert';

import { Helmet } from 'react-helmet';

import { FaCheckCircle } from 'react-icons/fa';

import AOS from 'aos';
import 'aos/dist/aos.css';



export default function BetalTerminal() {
  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
      
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    });
  }, []);
    return (
        <div className="bg-white">
          <Helmet replace={true}>
            <title>Betalterminal Göteborg | Kortterminal Göteborg | Tryggt & pålitligt</title>
            <meta
              name="description"
              content='Hos oss hittar ni användarvänliga lösningar som hjälper er affärsverksamhet i Göteborg att fungera smidigare. Betalterminaler, kortterminaler, kassasystem mm'
              data-rh="true"
            />
          </Helmet>

        
        <Std_Master
        TitleIncluded = {true}
         NavCss={"text-black"} TitleCss={"text-white text-[17px] ml-12 hidden xl:block 2xl:mt-[300px] 3xl:mt-[400px] custom-opacity rounded-lg xl:top-[220px]"}
         rubrikCss={"text-white"}
         logo_1={logo_new_text_black}
         mobileNavBarCssWhenScroll={"text-white"}
        mobileNavBarCssWhenNotScroll={"text-black"}
         logo={logo_new_text_black}
         /* mobileLogo={logo_new_text_white} mobileLogo_1={logo_new_text_white} */ mobileLogo={logo_new_text_black} mobileLogo_1={logo_new_text_white}
         rubrik={"Betalterminal Göteborg | Kortterminal Göteborg | Tryggt & pålitligt"}
         Title={" Hos oss hittar ni användarvänliga lösningar som hjälper er affärsverksamhet i Göteborg att fungera smidigare. Betalterminaler, kortterminaler, kassasystem mm "} 
         img={top_image_new}
        img_mobile={test_666_mobile}
         ShowButton={true}
        inner={
          <div className="md:container md:mx-auto my-12 px-4 md:px-0" data-aos="zoom-in-up">

            <section className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center my-8 bg-white rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <div className="text-center md:text-left p-4">
                <h2 className="text-2xl sm:text-3xl md:text-2xl font-bold mb-4">Kortterminal Göteborg – restaurang, skönhet eller livsmedel</h2>
                <p className="text-base sm:text-lg md:text-lg ">Alla företag inom servicebranschen har egna utmaningar och behov. Hos oss har vi tagit fasta på vad som ofta efterfrågas och tagit fram tre olika paket var för de ovanstående verksamheterna. Vad som behövs kan skilja sig åt. På en mer exklusiv butik är det en fin detalj med en trådlös kortterminal så att säljaren kan gå till kunden när det är dags att betala. I en mindre kiosk fungerar det mycket bra med en billigare och klassisk lösning som kassakopplad kortterminal.</p>
              </div>
              <div className="p-4">
                <h2 className="text-xl sm:text-2xl md:text-2xl font-bold mb-4">Vi har tagit fram skräddarsydda lösningar för:</h2>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <div>
                    <p className="my-2 text-lg md:text-md font-semibold flex items-center space-x-2"><FaCheckCircle className="text-green-500 mx-1"/>Restaurang, café, bar och nattklubb</p>
                    <p className="text-lg md:text-md font-semibold flex items-center space-x-2"><FaCheckCircle className="mx-1 text-green-500"/>Skönhetssalonger och frisörer</p>
                  </div>
                  <div>
                    <p className="my-2 text-lg md:text-md font-semibold flex items-center space-x-2"><FaCheckCircle className="mx-1 text-green-500"/>Kiosk och livsmedelsbutiker</p>
                    <p className="text-lg md:text-md font-semibold flex items-center space-x-2"><FaCheckCircle className="mx-1 text-green-500"/>Kemtvätt</p>
                  </div>
                  <div>
                    <p className="my-2 text-lg md:text-md font-semibold flex items-center space-x-2"><FaCheckCircle className="mx-1 text-green-500"/>Servicebutik</p>
                    <p className="text-lg md:text-md font-semibold flex items-center space-x-2"><FaCheckCircle className="mx-1 text-green-500"/>Detaljhandel</p>
                  </div>
                  <div>
                    <p className="my-2 text-lg md:text-md font-semibold flex items-center space-x-2"><FaCheckCircle className="mx-1 text-green-500"/>Torghandel och gatukök</p>
                  </div>
                </div>
              </div>
            </section>

            <section className="flex flex-col md:flex-row justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Skonhet.png"} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:mr-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Smidig kortterminal för din affärsverksamhet</h2>
                <p className="text-lg mb-4 xl:mx-24">Hos oss hittar ni användarvänliga lösningar som hjälper er affärsverksamhet att fungera smidigare. Restaurang, kiosk eller hårsalong - oavsett vilken bransch det gäller har vi en skalbar lösning för er. Våra kort- och betalsystem är säkra och pålitliga och med våra betalterminaler slipper ni krångel och stressiga situationer där kunderna inte kan betala. Vi har flera olika typer av kortterminaler och paketlösningar. Börja med ett bas och uppgradera allt eftersom er verksamhet växer, eller satsa på ett premiumpaket direkt och njut av en lösning som håller oavsett kundtryck. Kontakta oss idag för att få veta mer eller för att beställa ett paket!</p>
                <div className="text-start mt-4 xl:mx-24"> {/* Added a margin-top for spacing. Adjust as per your need */}
                  <div className="">
                    <Link to="/AllOffersPage" className='flex justify-center bg-gradient-to-tr bg-[#8c52ff] text-xl text-white py-[8px] px-4 rounded-md xl:w-[260px] w-auto'> Kolla våra kortterminaler </Link>
                  </div>
                </div>
              </div>
            </section>

            <section className="flex flex-col md:flex-row-reverse justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={Enox_ipad_iphon} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:ml-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Trådlösa eller integrerade kortterminaler i Göteborg</h2>
                <p className="text-lg mb-4 xl:mx-24">Vi på Smart Cash har flera olika typer av betalterminaler. Alla är tydliga, lätta att använda och sköter transaktioner på ett tryggt och säkert sätt. Vilken typ av kortterminal som behövs varierar beroende på verksamheten. I detaljhandel eller på ett café är det vanligast att man betalar i kassan direkt när man handlar medan det på en restaurang är bekvämt att gå ut till bordet där gästerna sitter för att ta betalt.
                            Berätta för oss hur er verksamhet fungerar, vi rådgör gärna med er så att ni hittar den betalterminalen som fungerar bäst för er.</p>

                <div className="text-start mt-4 xl:mx-24"> {/* Added a margin-top for spacing. Adjust as per your need */}
                  <div className="">
                    <Link to="/AllOffersPage" className='flex justify-center bg-gradient-to-tr bg-[#8c52ff] text-xl text-white py-[8px] px-4 rounded-md xl:w-[260px] w-auto'> Kolla våra kortterminaler </Link>
                  </div>
                </div>
              </div>
            </section>

            <section className="flex flex-col md:flex-row justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/restaurang_enox_program.png"} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:mr-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Skalbara system – möjlighet till extra betalterminaler</h2>
                <p className="text-lg mb-4 xl:mx-24">Sedan vi startade vårt företag 2010 har vi lärt oss mycket. Bland annat har vi förstått att det som är enkelt och lätt att använda är det som håller i längden när det kommer till kortterminaler och kassasystem. Att slippa byta hela betalsystemet då man vill lägga till en betalterminal är mycket värt. Annars riskerar man att värdefull arbetstid går åt till att lära personalen den nya kassan. 
                            Med våra system finns möjligheten att skala upp och lägga till ytterligare betalterminaler. Det betyder att du inte behöver byta system i och med att ditt företag växer utan du kan istället bara växla upp med ytterligare en, två eller fler kortterminaler.

                </p>
                <div className="text-start mt-4 xl:mx-24"> {/* Added a margin-top for spacing. Adjust as per your need */}
                  <div className="">
                    <Link to="/AllOffersPage" className='flex justify-center bg-gradient-to-tr bg-[#8c52ff] text-xl text-white py-[8px] px-4 rounded-md xl:w-[220px] w-auto'> Se mer av våra paket </Link>
                  </div>
                </div>
              </div>
            </section>

            <section className="flex flex-col md:flex-row-reverse justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={lager} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:ml-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Öka din försäljning i Göteborg</h2>
                <p className="text-lg mb-4 xl:mx-24">Med våra användarvänliga system kommer din verksamhet att fungera smidigt utan onödiga uppehåll och hinder. Det är alltför vanligt att kortterminaler krånglar, vilket gör att flödet stannar upp. Det i sin tur kan orsaka köbildning. Vid rusningstider kan det vara rent förödande att inte kunna ta betalt då det kan leda till förlorade intäkter. Dessutom får kunderna ett dåligt intryck då betalningsmekaniskmen inte verkar fullt pålitlig.
                            Smidiga kortterminaler har istället motsatt effekt. Utan köer blir er service snabbare. På så vis har ni möjlighet att ta bättre hand om era kunder och bygga upp ett gott rykte.
                </p>
              </div>
            </section>

            <section className="flex flex-col md:flex-row justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={dela_nota} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:mr-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Moderna och klassiska lösningar</h2>
                <p className="text-lg mb-4 xl:mx-24">Bland våra produkter finns både klassiska betalterminaler och helintegrerade system med touch screen där kunder själva kan välja vad de vill ha och sedan betala. Vilket man väljer beror mycket på vad det finns för behov men också på företagets profil. 
                              När man vill bygga upp ett varumärke är det viktigt med alla bitar och detaljer. Ett användarvänligt kassasystem som fungerar bra är viktigt för att kunderna få en bra uppfattning och känsla. 
                              Hör av er till oss så hjälper vi er hitta den rätta kortterminalen för ert företag i Göteborg!
                </p>
              </div>
            </section>

            <section className="flex flex-col md:flex-row-reverse justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              
              <img src={bordskarta} alt="Göteborg Betalterminal" className="w-full md:w-1/2 p-12 object-cover rounded-lg shadow mb-4 md:mb-0 md:ml-4"/>

              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Betalterminalen som passar er</h2>
                <p className="text-lg mb-4 xl:mx-24">På Smart Cash är vi dedikerade till att hjälpa våra kunder hitta ett bra kassasystem som fungerar för dem. Vårt mål är att alltid erbjuda service i toppklass och hjälpa till om några frågor eller problem uppstår. Vi vill att ni och era kunder skall trivas med våra betalsystem och tar gärna emot feedback.
                              Låt oss hjälpa ert företag att hitta ett system som får er verksamhet att växa. Kontakta oss idag!
                </p>
              </div>
            </section>






            

            {/* Continue for the other sections */}

            <section className="mt-10 flex justify-center" data-aos="zoom-in-up">
              <button
                className="px-8 py-4 rounded-lg bg-[#8c52ff] text-white font-bold hover:bg-[#7F00FF] transition duration-200"
                onClick={() => window.location.href = "https://www.smartcash.se/kontakt"}
              >
                Kontakt oss
              </button>
            </section>

            </div>




            /* <div className="container mx-auto my-12">
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-8 items-center">
              <div className="order-2 md:order-1">
                <h2 className="font-bold md:text-4xl text-2xl mx-2 my-2 lg:text-start text-center leading-[1.5]">Smidig kortterminal för din affärsverksamhet</h2>
                <p className="my-2 text-center mx-2 md:text-left text-xl md:leading-[1.9] leading-[1.6]">Hos oss hittar ni användarvänliga lösningar som hjälper er affärsverksamhet att fungera smidigare. Restaurang, kiosk eller hårsalong - oavsett vilken bransch det gäller har vi en skalbar lösning för er. Våra kort- och betalsystem är säkra och pålitliga och med våra betalterminaler slipper ni krångel och stressiga situationer där kunderna inte kan betala. Vi har flera olika typer av kortterminaler och paketlösningar. Börja med ett bas och uppgradera allt eftersom er verksamhet växer, eller satsa på ett premiumpaket direkt och njut av en lösning som håller oavsett kundtryck. Kontakta oss idag för att få veta mer eller för att beställa ett paket!</p>
              </div>
              <div className="order-2 md:order-2">
                <img src={AI_2} alt="Göteborg Betalterminal" className="mx-auto md:mx-0 max-w-full h-auto rounded-lg"/>
              </div>
            </div>

            <div className="grid grid-cols-1 xl:grid-cols-2 gap-8 items-center">
              <div className="order-1 md:order-2 my-2">
                <h2 className="font-bold md:text-4xl text-2xl mx-2 my-2 lg:text-start text-center leading-[1.5]">Trådlösa eller integrerade kortterminaler i Göteborg</h2>
                <p className="my-2 text-center mx-2 md:text-left text-xl md:leading-[1.9] leading-[1.6]">Vi på Smart Cash har flera olika typer av betalterminaler. Alla är tydliga, lätta att använda och sköter transaktioner på ett tryggt och säkert sätt. Vilken typ av kortterminal som behövs varierar beroende på verksamheten. I detaljhandel eller på ett café är det vanligast att man betalar i kassan direkt när man handlar medan det på en restaurang är bekvämt att gå ut till bordet där gästerna sitter för att ta betalt.
                 Berätta för oss hur er verksamhet fungerar, vi rådgör gärna med er så att ni hittar den betalterminalen som fungerar bäst för er. </p>
              </div>
              <div className="order-2 md:order-2 xl:order-1 my-2">
                <img src={AI_1} alt="Göteborg Betalterminal" className="mx-auto md:mx-0 max-w-full h-auto rounded-lg"/>
              </div>
            </div>

            <div className="grid grid-cols-1 xl:grid-cols-2 gap-8 items-center">
              <div className="order-2 md:order-1">
                <h2 className="font-bold md:text-4xl text-2xl mx-2 my-2 lg:text-start text-center leading-[1.5]">Skalbara system – möjlighet till extra betalterminaler</h2>
                <p className="my-2 text-center mx-2 md:text-left text-xl md:leading-[1.9] leading-[1.6]">Sedan vi startade vårt företag 2010 har vi lärt oss mycket. Bland annat har vi förstått att det som är enkelt och lätt att använda är det som håller i längden när det kommer till kortterminaler och kassasystem. Att slippa byta hela betalsystemet då man vill lägga till en betalterminal är mycket värt. Annars riskerar man att värdefull arbetstid går åt till att lära personalen den nya kassan. Med våra system finns möjligheten att skala upp och lägga till ytterligare betalterminaler. Det betyder att du inte behöver byta system i och med att ditt företag växer utan du kan istället bara växla upp med ytterligare en, två eller fler kortterminaler. </p>
              </div>
              <div className="order-2 md:order-2">
                <img src={test_666_mobile} alt="Göteborg Betalterminal" className="mx-auto md:mx-0 max-w-full h-auto rounded-lg"/>
              </div>
            </div>

            <div className="grid grid-cols-1 xl:grid-cols-2 gap-8 items-center">
              <div className="order-1 md:order-2 my-2">
                <h2 className="font-bold md:text-4xl text-2xl mx-2 my-2 lg:text-start text-center leading-[1.5]">Öka din försäljning i Göteborg</h2>
                <p className="my-2 text-center mx-2 md:text-left text-xl md:leading-[1.9] leading-[1.6]">Med våra användarvänliga system kommer din verksamhet att fungera smidigt utan onödiga uppehåll och hinder. Det är alltför vanligt att kortterminaler krånglar, vilket gör att flödet stannar upp. Det i sin tur kan orsaka köbildning. Vid rusningstider kan det vara rent förödande att inte kunna ta betalt då det kan leda till förlorade intäkter. Dessutom får kunderna ett dåligt intryck då betalningsmekaniskmen inte verkar fullt pålitlig. Smidiga kortterminaler har istället motsatt effekt. Utan köer blir er service snabbare. På så vis har ni möjlighet att ta bättre hand om era kunder och bygga upp ett gott rykte. </p>
              </div>
              <div className="order-2 md:order-2 xl:order-1 my-2">
                <img src={test_666_mobile} alt="Göteborg Betalterminal" className="mx-auto md:mx-0 max-w-full h-auto rounded-lg"/>
              </div>
            </div>

            <div className="grid grid-cols-1 xl:grid-cols-2 gap-8 items-center">
              <div className="order-2 md:order-1">
                <h2 className="font-bold md:text-4xl text-2xl mx-2 my-2 lg:text-start text-center leading-[1.5]">Moderna och klassiska lösningar</h2>
                <p className="my-2 text-center mx-2 md:text-left text-xl md:leading-[1.9] leading-[1.6]">Bland våra produkter finns både klassiska betalterminaler och helintegrerade system med touch screen där kunder själva kan välja vad de vill ha och sedan betala. Vilket man väljer beror mycket på vad det finns för behov men också på företagets profil. När man vill bygga upp ett varumärke är det viktigt med alla bitar och detaljer. Ett användarvänligt kassasystem som fungerar bra är viktigt för att kunderna få en bra uppfattning och känsla. Hör av er till oss så hjälper vi er hitta den rätta kortterminalen för ert företag i Göteborg!</p>
              </div>
              <div className="order-2 md:order-2">
                <img src={test_666_mobile} alt="Göteborg Betalterminal" className="mx-auto md:mx-0 max-w-full h-auto rounded-lg"/>
              </div>
            </div>
            
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-8 items-center">
              <div className="order-1 md:order-2 my-2">
                <h2 className="font-bold md:text-4xl text-2xl mx-2 my-2 lg:text-start text-center leading-[1.5]">Betalterminalen som passar er</h2>
                <p className="my-2 text-center mx-2 md:text-left text-xl md:leading-[1.9] leading-[1.6]">På Smart Cash är vi dedikerade till att hjälpa våra kunder hitta ett bra kassasystem som fungerar för dem. Vårt mål är att alltid erbjuda service i toppklass och hjälpa till om några frågor eller problem uppstår. Vi vill att ni och era kunder skall trivas med våra betalsystem och tar gärna emot feedback. Låt oss hjälpa ert företag att hitta ett system som får er verksamhet att växa. Kontakta oss idag! </p>
              </div>
              <div className="order-2 md:order-2 xl:order-1 my-2">
                <img src={test_666_mobile} alt="Göteborg Betalterminal" className="mx-auto md:mx-0 max-w-full h-auto rounded-lg"/>
              </div>
            </div>
          </div> */}
         />
        </div>
    )
}
