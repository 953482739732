import React, {useState} from 'react'

import Product_card from '../../Components/Product_card'
import Std_Master from '../../Master/Std_Master'
import CardModal from '../../Components/CardModal';

import westpay_1_new from "../../Gfx/westpay_1_new.png";
import westpay_c100_1 from "../../Gfx/westpay_c100_1.png";
import Verifone_P400_1 from "../../Gfx/Verifone_P400_1.png";
import Verifone_1_new from "../../Gfx/Verifone_1_new.png";
import Verifone_2_new from "../../Gfx/Verifone_2_new.png";
import Verifone_3_new from "../../Gfx/Verifone_3_new.png";

import logo_new_text_white from "../../Gfx/logo_new_text_white.png";
import logo_new_text from "../../Gfx/logo_new_text.png";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function Kortterminaler() {
    const [isOpen, setIsOpen] = useState(false)
    const [reload, setReload] = useState('')


  const _setIsOpen = (_price, _title, _img, _desc, _payAll, _show_checkbox) =>
  {
      setPrice(_price)
      setTitle(_title)
      setImgs(_img)
      setDesc(_desc)
      setPayAll(_payAll)
      setShow_checkbox(_show_checkbox)
      setIsOpen(true);
  }

  const [price, setPrice] = useState("");
  const [title, setTitle] = useState("");
  const [imgs, setImgs] = useState([""]);
  const [desc, setDesc] = useState("");

  const [payAll, setPayAll] = useState("");

  const [show_checkbox, setShow_checkbox] = useState(false);
  
  const isPopUp = () => {
      
      if(isOpen) {
          return < CardModal key={0} hehe={imgs} hehe1={imgs} title={title} price={price} img={imgs} desc={desc} payAll={payAll} show_checkbox={show_checkbox} close={()=>{setIsOpen(false); setImgs("")}} />
      }
    }

  return (
    <div>
        {isPopUp()}

        <Std_Master logo_1={logo_new_text_white} logo={logo_new_text} mobileLogo={logo_new_text_white} mobileLogo_1={logo_new_text_white} classNameCss="w-full vh-full" vid={"https://smartcash-website.s3.eu-north-1.amazonaws.com/beauty_and_health_video.mp4"} inner={
            <div className="py-20 bg-white">
                <Helmet replace={true}>
                    <title>Smart Cash - Kortterminaler</title>
                </Helmet>
                <div className="z-10 max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <h1 className="text-4xl sm:ml-10 mx-5 font-extrabold text-warm-gray-900 sm:text-4xl lg:text-4xl">Kortterminaler</h1>
                    <p className="mt-6 sm:ml-11 ml-6 text-xl text-warm-gray-500 max-w-3xl"> Vi ser fram emot att höra från dig </p>
                </div>
                <div className="md:flex sm:flex flex-wrap -z-50 max-w-7xl mx-auto sm:px-6 lg:px-8 justify-center items-center">
                    
                    <Product_card trigger={()=>_setIsOpen("395 Kr/Mån", "Westpay C10", [westpay_1_new, "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/westpay_2.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/westpay_3.png"], <div>Integrerad kortterminal som kopplas till kassan för smidiga och snabba kortbetalningar.</div>, <div className="flex">Betala på en gång <p className=" flex border-black border-b-2 mx-2"></p></div>)}
                    update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={westpay_1_new} price="395Kr" title="Westpay C10" ribbon="395 :- /mån" description="Integrerad kortterminal som kopplas till kassan för smidiga och snabba kortbetalningar."/>

                    <Product_card trigger={()=>_setIsOpen("495 Kr/Mån", "Westpay C100", [westpay_c100_1, "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/westpay_c100_2.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/westpay_c100_3.png"], <div>Trådlös kortterminal som går att integrera till kassaprogammet och går även att användas fristående.</div>, <div className="flex">Betala på en gång <p className=" flex border-black border-b-2 mx-2"></p></div>)}
                    update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={westpay_c100_1} price="495Kr" title="Westpay C100" ribbon="495 :- /mån" description="Trådlös kortterminal som går att integrera till kassaprogammet och går även att användas fristående."/>

                    <Product_card trigger={()=>_setIsOpen("495 Kr/Mån", "Verifone v400", [Verifone_1_new, Verifone_2_new, Verifone_3_new], <div>Trådlös kortterminal som går att integrera till kassaprogammet och går även att användas fristående.</div>, <div className="flex">Betala på en gång <p className=" flex border-black border-b-2 mx-2"></p></div>)}
                    update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={Verifone_1_new} price="495Kr" title="Verifone v400" ribbon="495 :- /mån" description="Trådlös kortterminal som går att integrera till kassaprogammet och går även att användas fristående."/>

                    <Product_card trigger={()=>_setIsOpen("395 Kr/Mån", "Verifone p400", [Verifone_P400_1, "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/Verifone_P400_2.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/Verifone_P400_3.png"], <div>Integrerad kortterminal som kopplas till kassan för smidiga och snabba kortbetalningar.</div>, <div className="flex">Betala på en gång <p className=" flex border-black border-b-2 mx-2"></p></div>)}
                    update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={Verifone_P400_1} price="395Kr" title="Verifone p400" ribbon="395 :- /mån" description="Integrerad kortterminal som kopplas till kassan för smidiga och snabba kortbetalningar."/>
                    
                </div>
                <div className='lg:flex lg:gap-8 lg:justify-center'>
                    <div className="flex text-md text-gray-500 justify-center lg:mt-7 mt-2 lg:animate-bounce"> {/* Added a margin-top for spacing. Adjust as per your need */}
                        <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/BetalTerminal_Stockholm" className='flex justify-center bg-gradient-to-tr bg-[#8c52ff] opacity-80 text-md text-white py-[8px] px-4 rounded-xl xl:w-[340px] w-auto'> Kortterminaler till ditt företag i Stockholm </Link>
                    </div>
                    <div className="flex text-md text-gray-500 justify-center lg:mt-7 mt-2 lg:animate-bounce"> {/* Added a margin-top for spacing. Adjust as per your need */}
                        <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/BetalTerminal_Goteborg" className='flex justify-center bg-gradient-to-tr bg-[#8c52ff] opacity-80 text-md text-white py-[8px] px-4 rounded-xl xl:w-[340px] w-auto'> Kortterminaler till ditt företag i Göteborg </Link>
                    </div>
                    <div className="flex text-md text-gray-500 justify-center lg:mt-7 mt-2 lg:animate-bounce"> {/* Added a margin-top for spacing. Adjust as per your need */}
                        <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/BetalTerminal_Linkoping" className='flex justify-center bg-gradient-to-tr bg-[#8c52ff] opacity-80 text-md text-white py-[8px] px-4 rounded-xl xl:w-[340px] w-auto'> Kortterminaler till ditt företag i Linkoping </Link>
                    </div>

                </div>
            </div>
        } />
    </div>
  )
}
