import React from 'react'
import { AiOutlineArrowRight } from "react-icons/ai";

export default function InfoCard(props) {
    return (
        <div style={{ backgroundImage: `url(${props.img})` }} className={"grid grid-rows-3 my-4 bg-cover bg-center bg-no-repeat w-[280px] h-[320px] md:w-[350px] md:h-[450px] shadow-lg duration-300 ease-in-out hover:-translate-y-1 hover:scale-110 px-6 py-6 rounded-lg text-white mx-2 " + " " + (props.main_css)}>
            <div className={" " + (props.content_css)}>
                <div className="row-span-1 pt-12">{props.title}</div>
                <div className="row-span-1 font-bold md:text-2xl text-[15px] text-center">
                    {props.title_2}
                    <p className="font-medium text-sm ">{props.description}</p>
                </div>
            </div>
            <div className={" row-span-3 flex justify-center h-[42px] mb-8 " + " " + (props.css) + " " + (props.check === "0" ? 'hidden' : '')}>
                <button className="relative flex items-center bg-white  py-[12px] px-4 rounded-md w-[180px] font-bold text-[#8c52ff] hover:bg-[#8c52ff] hover:text-white text-center justify-center" >
                    Läs mer <div className="mt-1 ml-4"> <AiOutlineArrowRight /> </div>
                </button>
            </div>
        </div>
    )
}
