import React from 'react'
import Std_Master from '../Master/Std_Master';
import Offert_kontakt_card from '../Components/Offert_kontakt_card'
import logo_new_text from "../Gfx/logo_new_text.png";
import logo_new_text_white from "../Gfx/logo_new_text_white.png";

export default function Offert_checkout() {
    return(
        <Std_Master logo_1={logo_new_text} logo={logo_new_text} mobileLogo={logo_new_text} mobileLogo_1={logo_new_text_white} NavCss={"text-black"} Title="." mobileNavBarCss={"text-black"} inner={
          <div>
    
            <div className="z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
              <h1 className="text-4xl sm:ml-10 mx-5 font-extrabold text-warm-gray-900 sm:text-4xl lg:text-4xl">Utcheckning</h1>
              <p className="mt-6 sm:ml-11 ml-6 text-xl text-warm-gray-500 max-w-3xl "> Skicka din paket ansökan genom att fylla dina information i formularen. </p>
            </div>
            <div className="max-w-7xl mx-auto py-16 px-4 sm:py-16 sm:px-6 lg:px-8">
              <Offert_kontakt_card />
            </div>
          </div>
          
        } />
      );
}
