import React from 'react'
import { Link } from 'react-router-dom';
import Std_Master from '../../Master/Std_Master';
import InfoCard from '../../Components/InfoCard';

import logo_new_text_white from "../../Gfx/logo_new_text_white.png";
import logo_new_text from "../../Gfx/logo_new_text.png";

import Kassor from "../../Gfx/Kassor.png";
import Kortterminaler from "../../Gfx/Kortterminaler.png";
import Kvittohantering from "../../Gfx/Kvittohantering.png";

import Tillbehor from "../../Gfx/Tillbehor.png";

export default function AllProductsPage() {
  return (
    <div>
        <Std_Master selectedNavItem={1} logo_1={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/logo_new_text_white.png"} logo={logo_new_text} mobileLogo={logo_new_text} mobileLogo_1={logo_new_text_white} NavCss={"text-white"} Title="." mobileNavBarCss={"text-black"} NavBg={"bg-[#8c52ff]"} inner={
        <div className='flex items-center justify-center w-full py-20 bg-white'> {/* mt-8 */}
        <div className='py-4 grid grid-cols-2 flex-wrap justify-center gap-x-6'>

          <div className=' col-span-1 lg:relative'>
            <Link onClick={()=>window.scrollTo({top: 900, behavior: 'smooth'})} to="/Kassor">
              <InfoCard main_css={"md:w-[350px] md:h-[450px] w-[250px] h-[350px]"} css={"md:mt-52 mt-36"} content_css={"md:mt-48 mt-32"} check={"1"} img={Kassor} title_2={"KASSOR"} />
            </Link>
          </div>
          <div className=' col-span-1 lg:relative'>
            <Link onClick={()=>window.scrollTo({top: 900, behavior: 'smooth'})} to="/Kortterminaler">
              <InfoCard main_css={"md:w-[350px] md:h-[450px] w-[250px] h-[350px]"} css={"md:mt-52 mt-36"} content_css={"md:mt-48 mt-32"} check={"1"} img={Kortterminaler} title_2={"KORTTERMINALER"} />
            </Link>
          </div>
          <div className=' col-span-1 lg:relative'>
            <Link onClick={()=>window.scrollTo({top: 900, behavior: 'smooth'})} to="/Kvittohantering">
              <InfoCard main_css={"md:w-[350px] md:h-[450px] w-[250px] h-[350px]"} css={"md:mt-52 mt-36"} content_css={"md:mt-48 mt-32"} check={"1"} img={Kvittohantering} title_2={"KVITTOHANTERING"} />
            </Link>
          </div>
          <div className=' col-span-1 lg:relative'>
            <Link onClick={()=>window.scrollTo({top: 900, behavior: 'smooth'})} to="/Tillbehor">
              <InfoCard main_css={"md:w-[350px] md:h-[450px] w-[250px] h-[350px]"} css={"md:mt-52 mt-36"} content_css={"md:mt-48 mt-32"} check={"1"} img={Tillbehor} title_2={"TILLBEHÖR"} />
            </Link>
          </div>
          
          
        </div>
      </div>
} />
    </div>
    )
}
