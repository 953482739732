import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import NewCard from '../Components/NewCard'
import { FaHeart, FaRegHeart, AiOutlinePlusCircle } from 'react-icons/fa';
import { BiPlusMedical } from 'react-icons/bi';
import { getCookie , setCookie} from './CookieHandler';
import Symbol_1 from '../Gfx/Symbol_1.png'
import Symbil_2 from '../Gfx/Symbil_2.png'

import CardModal from '../Components/CardModal'

import swal from 'sweetalert';


export default function Product_card(props) {
const [isOpen,setIsOpen] = useState(false);

const isPopUp = () => {
    if(isOpen) {
      return < CardModal />
    }
  }


function sweetalertNotification(){
    swal({
        title: "Tack!",
        text: "Produkten finns nu i din varukorg",
        icon: "success",
        button: "Klar",
        timer: 2000
        })
        setTimeout(function(){
            //window.location.reload(1);
         }, 2000);
}
function sweetalertNotificatio_1(){
    swal({
        text: "Produkten finns nu i din varukorg",
        button: "Klar",
    })
}

    return (
        <NewCard>
            
        <div className="h-[450px] cursor-pointer" onClick={() =>{ props.trigger() }}>
            <div className="relative overflow-hidden">
                    {/* {!props.check ? <div className="absolute transform bg-[#8c52ff] text-center items-center pt-8 text-[13px] text-white font-bold py-2 rounded-full top-[32px] w-[90px] h-[90px]"> */}
                    {!props.check ? <div className="absolute transform -rotate-45 bg-[#8c52ff] text-center text-white font-semibold py-1 left-[-34px] top-[32px] w-[170px]">
                    {/* absolute transform -rotate-45 bg-gray-600 text-center text-white font-semibold py-1 left-[-34px] top-[32px] w-[170px] */}
                        {props.ribbon}
                    </div> : <div></div>}
                <div className="relative overflow-hidden py-4 mt-4 md:grid">
                    <div className="absolute left-0 top-0 h-16 w-16"></div>
                    <div className="w-full h-full flex justify-center items-center mt-6 col-span-1 bg-cover bg-center">
                        <img className={" w-52 h-52 object-cover object-center object-no-repeat " + props.imgCss} src={props.img} />
                    </div>
                </div>
                <div>
                    <div className=" pt-2 flex flex-col justify-center w-auto gap-y-2 bg-contain bg-center bg-no-repeat h-[190px] px-4" style={{backgroundImage: "url(" + Symbil_2 + ")"}}>
                        <h1 className="my-6 flex justify-center text-center font-bold text-xl ">{props.title}</h1>
                        <p className=" text-sm" >{props.description}</p>
                    </div>
                </div>
            </div>     
        </div>

        <div className="flex flex-row items-end justify-between h-12 px-4">
            <div className="">
                    {/* <p>{props.price}</p> */}
            </div>
        </div>
                    
        </NewCard>
    )
}
