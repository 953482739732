import React, {useState, useEffect, useRef, useCallback} from 'react';
import { Link } from 'react-router-dom';
import { IoIosArrowDown, IoIosArrowUp, IoIosClose } from "react-icons/io";
import { AiOutlineArrowRight } from "react-icons/ai";
import { MdOutlineLocalDining, MdOutlineVolunteerActivism, MdAddShoppingCart } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BsCart4 } from "react-icons/bs";

import { MdOutlinePayment } from "react-icons/md";
import { FaCashRegister } from "react-icons/fa";
import { BsReceiptCutoff } from "react-icons/bs";
import { HiOutlineShoppingBag } from "react-icons/hi";


/* Images */
import logo_new_text_white from "../Gfx/logo_new_text_white.png";
import logo_new_text from "../Gfx/logo_new_text.png";
import Footer from "../Gfx/Footer.jpg";

import Footer_logo_1_new from "../Gfx/Footer_logo_1_new.png";
import { getCookie, removeCookie, setCookie } from '../Components/CookieHandler';
import SideBar from "../Components/SideBar";
import { slideInDown, slideInUp, slideInRight, slideInLeft, fadeIn, flash } from "react-animations"
import { StyleSheet, css } from 'aphrodite';

import useWindowDimensions from "../Hooks/useWindowDimensions";

var array = ['Tryggt, hållbart och snabbt', 'Ett system inga krångel ', 'Anpassade system utan krångel', 'Få en smidigare försäljning med våra system.']

export default function Std_Master(props) {
  
  const [mobileNav, setMobileNav] = useState(false)

  const [textIndex, setTextIndex] = useState(0)

  

  /* const changeBgColor = () => {
    if(window.scrollY >= 40){
      console.log(window.scrollY)
      setNavbar(true);
    }else{
      setNavbar(false);
    }
  };

  window.addEventListener('scroll', changeBgColor); */
  
  /* useEffect(() => {
      // Anything in here is fired on component unmount.
      console.log("alhehe")
      if (!getCookie("products")){
        setCookie("products",JSON.stringify([]));
      }
  },[]) */
   function EmbedVideo () {
    return (
        <div dangerouslySetInnerHTML={{ __html: `
         <video
           loop
           muted
           autoplay
           playsinline
           src="${props.vid}"
           class="${props.classNameCss}"
           />,
       ` }}></div>
    )
 }

 const prevScrollY = useRef(0);

      const [goingUp, setGoingUp] = useState(false);
      const [scrollTheAss, setScrollTheAss] = useState(false);

      const styles = StyleSheet.create({
        slideInUp: {
            animationName: slideInUp,
            animationDuration: '1s'
        },
        slideInDown: {
          animationName: slideInDown,
          animationDuration: '1s'
      },
      slideInRight: {
        animationName: slideInRight,
        animationDuration: '1s'
    },
      fadeIn: {
        animationName: fadeIn,
        animationDuration: '2s'
    }
    })

    useEffect(() => {
      if (mobileNav) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    }, [mobileNav]);
    


      useEffect(() => {
        const handleScroll = () => {
          const currentScrollY = window.scrollY;
          if (prevScrollY.current < currentScrollY && goingUp) {
            setGoingUp(false);
            //props.setUp(false)
          }
          if (prevScrollY.current > currentScrollY && !goingUp) {
            setGoingUp(true);
            //props.setUp(true)
          }
    
          prevScrollY.current = currentScrollY;
          setScrollTheAss(currentScrollY)
        };
    
        window.addEventListener("scroll", handleScroll, { passive: true });
    
        return () => window.removeEventListener("scroll", handleScroll);
      }, [goingUp]);
      
        {/* <div className={"" + css(styles.fadeIn)} >Ett system inga krångel</div>,
        <div className={"" + css(styles.fadeIn)} >Tryggt, hållbart och snabbt</div>,
        <div className={"" + css(styles.fadeIn)} >Restaurang & Café, Livs & Frisörer</div>,
        <div className={"" + css(styles.fadeIn)} >Support, Installation & Inga dolda kostnader</div> */}
      const names = [
         'Tryggt, hållbart och snabbt', 'Ett system inga krångel ', 'Anpassade system utan krångel', 'Få en smidigare försäljningen'
    ]
    
    function MyComponent() {
        const [newName, setnewName] = useState("Tryggt, hållbart och snabbt");
    
        const shuffle = useCallback(() => {
            const index = Math.floor(Math.random() * names.length);
            setnewName(names[index]);
        }, []);
    
        useEffect(() => {
            const intervalID = setInterval(shuffle, 6400);
            return () => clearInterval(intervalID);
        }, [shuffle])
    
        return(
            <div className={"" + css(styles.fadeIn)} >{newName}</div>
        )
    }

  return (
    <div>
      
      {/*GetSideBar()*/}
      <GetSideBar></GetSideBar>
      {/* <GetSideBar_1></GetSideBar_1> */}

      {/* Navbar */}
      <div className={'bg-white ' + props.hightOfTheNavBar}>

        {/* <div className={scrollTheAss == 0  ? props.NavBg + " w-full items-center justify-center flex-wrap  fixed z-20 " : goingUp == true && !props.open ? props.NavBgOnScroll + " w-full  items-center justify-center flex-wrap sm:bg-white fixed z-20 " + css(styles.slideInDown) : " hidden w-full  items-center justify-center flex-wrap fixed z-20 bg-transparent "}> */}
        <div className={`${props.NavBg} w-full items-center justify-center flex-wrap fixed z-20 navbar ${scrollTheAss == 0 ? "" : "white-bg"}`}>
          <div className={"grid justify-center w-full col-start-1 col-end-4 grid-cols-4"}>
            <div className="col-span-1 w-full grid-cols-3 py-4 px-8 xl:px-4 hidden lg:flex justify-start items-center z-10">
              <Link onClick={()=>window.scroll(0, 0)} to="/"><img src={scrollTheAss == 0 ? props.logo_1 : props.logo}  className={'col-span-1 h-[40px] w-[200px] ' + css(styles.fadeIn)}   /></Link>
            </div>
            <div className={ window.scrollY <= 0 ? "text-white flex flex-row justify-start pb-4 items-center col-start-2 col-span-3 " + props.NavCss : "text-black flex flex-row justify-start pb-4 items-center col-start-2 col-span-3"}>
              
              <div className={" safari_only grid-cols-3 hidden lg:flex 2xl:ml-[360px] 3xl:ml-[660px] 4xl:ml-[1000px] xl:ml-[250px] lg:ml-[50px] justify-end items-center h-[40px] pt-1 blur-none z-10 fixed"}>
                <NavBar isSm={false} trigger={()=>{}} navBarNumber={props.selectedNavItem} />
                {/* NavBar(false, () => {}) */}
                </div>
                
              </div>
          </div>
        </div>

        {/* Navbar Mobile */}
        <div className={scrollTheAss == 0  ? 'absolute w-full grid-cols-3 xl:px-4 flex lg:hidden ' : goingUp == true && !props.open ? "w-full items-center justify-center flex-wrap bg-[#8c52ff] fixed z-20 h-[80px] lg:hidden  " + css(styles.slideInDown) : "hidden w-full  items-center justify-center flex-wrap fixed z-20 bg-transparent"}>
          <Link onClick={()=>window.scroll(0, 0)} to="/"><img src={scrollTheAss == 0 ? props.mobileLogo : props.mobileLogo_1} className={'absolute top-[7px] -left-[7px] my-4 mx-8 z-10 lg:hidden ' + css(styles.fadeIn)} style={{height: "40px"}} /></Link>
          <div className='w-full '>
            {/* <IoIosArrowDown className='absolute right-0 my-4 mx-6 ' color='white' size={40} onClick={() => {setMobileNav(!mobileNav)}} /> */}
            <button className={mobileNav == false && scrollTheAss > 0 ? ' absolute right-0 top-[11px] my-4 mx-6 text-white z-30 ' + props.mobileNavBarCssWhenScroll : mobileNav == false && scrollTheAss == 0 ? ' absolute right-0 top-[11px] my-4 mx-6 text-white z-30 ' + props.mobileNavBarCssWhenNotScroll : " hidden" } onClick={() => {setMobileNav(!mobileNav)}} > {/* : goingUp == true ? "absolute right-0 top-[10px] my-4 mx-6 text-white z-30" : "hidden" */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8"
                  fill="white"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            </button>
            <div className={(!mobileNav ? "hidden" : "navBarCon")}>
            <NavBar isSm={true} trigger={() => {setMobileNav(!mobileNav)}} navBarNumber={props.selectedNavItem} />
              {/* {NavBar(true, () => {setMobileNav(!mobileNav)})} */}
            </div>
          </div>
        </div>

        {/* Page name */}
        {/* <div className={mobileNav ? 'hidden absolute left-22 top-[40px] lg:top-[60px] z-10 w-full justify-center text-center text-xl md:text-4xl lg:text-5xl xl:text-6xl text-white font-bold font-lato ' + props.TitleCss : ' absolute 2xl:right-[1230px] sm:right-[230px] 2xl:top-[150px] right-[20px] p-4 top-[380px] md:top-[330px] lg:top-[280px] z-10 text-[50px] md:text-[80px] lg:text-[80px] xl:text-[100px] text-white font-bold font-lato '  + props.TitleCss} style={{marginTop: "12vw"} }>  */}
          <div className="flex justify-start">
          {/* <div className="absolute right-0 sm:right-4 md:right-8 xl:right-16 2xl:right-24 top-1/4 sm:top-1/3 md:top-1/4 lg:top-1/3 xl:top-1/4 2xl:top-1/3 z-10 text-white font-lato text-8xl sm:text-9xl xl:text-[180px] hidden xl:block">
            Göteborg
          </div> */}




        <div className={mobileNav ? 'hidden absolute left-22 top-[40px] lg:top-[60px] z-10 w-full justify-center text-center text-xl md:text-4xl lg:text-5xl xl:text-6xl text-white font-bold font-lato ' + props.TitleCss : ' absolute sm:right-[230px] xl:right-[850px] xl:top-[120px] 2xl:right-[1050px] 3xl:right-[1350px] 4xl:right-[1650px] 2xl:top-[-110px] right-[20px] p-4 top-[280px] md:top-[330px] lg:top-[280px] z-10 text-white font-lato '  + props.TitleCss} /* style={{marginTop: "12vw"} } */> {/* right-[10vw] md:right-[15vw] lg:right-[150px] xl:right-[250px] */} {/* lg:top-[500px] */}
          {/* text-[50px] md:text-[80px] lg:text-[80px] xl:text-[100px] font-bold */}
          <h1 className={`flex text-[30px] font-bold mb-4  ${props.rubrikCss}`}>{props.rubrik}</h1> {/* text-black */}
          <div className=''> 
            { props.Title ? props.Title : MyComponent() }
          </div>

          {props.ShowButton == true ? <div className="flex justify-end w-full py-8">
                <Link to="/kontakt" className='flex justify-center bg-gradient-to-tr bg-[#8c52ff] text-white py-[6px] px-4 rounded-md w-[180px]'> Jag vill veta mer </Link>
            </div> : <div></div>}
        </div>
          </div>
        

        <div className={'w-full h-[400px] bg-black inline -skew-y-6 font-lato '} >
          
          
           {/* h-[250px] sm:h-[650px] big_screen:h-[850px] */ /* vh-full  */}
            {props.img ? <> <img loading="lazy" src={props.img} className={'xl:block hidden w-full vh-full_2 ' + props.imgCssHidden} />  
             <img loading="lazy" src={props.img_mobile} className={'block xl:hidden w-full vh-full_mobile ' + props.imgCssHidden} /> </>  :
          <div className='safari_only w-full sm:h-full z-0 brightness-75 '>
              {EmbedVideo()}
          </div>}
        </div>

      </div>
      <main>
        {props.inner}
      </main>
      <footer className="relative grid lg:grid-cols-5 border-t bg-gray-200 border-gray-200 h-[300]">  {/* md:mx-36 */} {/* mt-28 */} {/* I deleted the mt becouse the bg color is gray */}
      <div className="lg:col-span-2 col-span-3 md:w-full md:h-[309px] bg-no-repeat bg-cover " style={{backgroundImage: "url(" + Footer + ")"}}>
      </div>

        <div className="my-6 lg:col-span-3 col-span-3 px-6 ">
          <div className="grid grid-cols-8 lg:grid-cols-7 font-lato gap-x-6 lg:ml-16">
            <div className="col-span-4 md:col-span-2">
              <h2 className="text-sm font-extrabold tracking-tight text-black uppercase">
                INFORMATION
              </h2>
              <p className="mt-1 font-medium text-sm text-black">
                Boka demo
              </p>
                  <h2 className="mt-6 text-sm font-extrabold tracking-tight text-black uppercase">
                    KONTAKT
                  </h2>
                  <p className="mt-1 font-medium text-sm text-black">
                    Smart Cash Sverige AB
                  </p>
                  <p className="mt-1 font-medium text-sm text-black">
                    Kiselgatan 19
                  </p>
                  <p className="mt-1 font-medium text-sm text-black">
                    602 23 Norrköping
                  </p>
                  <p className="mt-1 font-medium text-sm text-black">
                    <a className="bg-clip-text text-transparent bg-gradient-to-b from-purple-500 to-indigo-500" href="tel:PHONE_NUM">011-470 90 00</a>
                    
                  </p>
                  <p className="mt-1 font-medium text-sm text-black">
                    <a className="bg-clip-text text-transparent bg-gradient-to-b from-purple-500 to-indigo-500" href="mailto:info@smartcash.se">info@smartcash.se</a>
                  </p>
            </div>
            <div className="col-span-4 md:col-span-3 ">
                  <h2 className="text-sm font-extrabold tracking-tight text-black uppercase">
                    SUPPORT
                  </h2>
                  <p className="mt-1 font-medium text-sm text-black">
                     <a className="bg-clip-text text-transparent bg-gradient-to-b from-purple-500 to-indigo-500" href="tel:PHONE_NUM">011- 470 90 00</a>
                  </p>
                  {/* <p className="mt-1 font-medium text-sm text-black">
                    PC-Kassor: <a className="bg-clip-text text-transparent bg-gradient-to-b from-purple-500 to-indigo-500" href="tel:PHONE_NUM">011-18 47 46</a>
                  </p> */}
                  <p className="mt-1 font-medium text-sm text-black">
                    <a className="bg-clip-text text-transparent bg-gradient-to-b from-purple-500 to-indigo-500" href="mailto:support@smartcash.se">support@smartcash.se</a>
                  </p>
                  <div className="mt-12">
                      <button onClick={()=>window.scroll(0, 0)} className="flex bg-[#8c52ff] text-white py-[12px] px-4 rounded-md w-[160px]" > <Link to="/kontakt"> Kontakta oss </Link> <div className="mt-1 ml-4"> <AiOutlineArrowRight /> </div> </button>
                  </div>
              </div>
              <div className="md:col-span-2 col-span-6 mt-2">
                  <div className="lg:col-span-1 col-span-1 md:w-full md:h-[195px] bg-no-repeat bg-contain bg-center" style={{backgroundImage: "url(" + Footer_logo_1_new + ")"}}></div>
                  <h2 className="flex md:justify-center justify-start font_lato text-white font-bold text-[20px] big_screen:mr-[290px]">Hitta oss via</h2>
                  <div className="flex md:justify-center justify-start big_screen:mr-[310px]">
                    <a href="https://www.facebook.com/smartcashab" target="_blank" >
                    <div className='text-[#8c52ff] hover:text-indigo-700 mx-4 mt-[2px] cursor-pointer'>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" aria-hidden="true"><path d="M22.258 1H2.242C1.556 1 1 1.556 1 2.242v20.016c0 .686.556 1.242 1.242 1.242h10.776v-8.713h-2.932V11.39h2.932V8.887c0-2.906 1.775-4.489 4.367-4.489 1.242 0 2.31.093 2.62.134v3.037l-1.797.001c-1.41 0-1.683.67-1.683 1.653v2.168h3.362l-.438 3.396h-2.924V23.5h5.733c.686 0 1.242-.556 1.242-1.242V2.242C23.5 1.556 22.944 1 22.258 1" fill="currentColor"></path></svg>
                    </div>
                    </a>

                    <div className='text-[#8c52ff] hover:text-indigo-700 cursor-pointer'>
                      <svg className="h-7 w-7" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd"></path></svg>
                    </div>
                  </div>
                  
              </div>
              {/* <div className="">
                <script src="https://app.weply.chat/widget/aff82c3d70b807de82a9ce089dbbd0af" async></script>
              </div> */}
          </div>
        </div>
        <div className="col-span-5 bg-[#8c52ff] text-white font-lato">
          <div className="text-center">
            <p className="text-base font-medium text-white sm:text-white xl:text-center">
                © 2023 Smart Cash Sverige AB 
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

class GetSideBar extends React.Component 
{

  constructor(props){
    super(props);
    this.state = {
      isCartOpen:false
    }
  }

  render(){

    var cookie = JSON.parse(getCookie("products"))

    var paket = JSON.parse(getCookie("paket"));


    //console.log(Object.keys(paket).length)
    
    if(this.state.isCartOpen){
      {document.body.style.overflow = 'hidden'}
      return(
        <SideBar hide={()=>{this.setState({isCartOpen:false})}} inner={""}/>
      )
    }else{
      {document.body.style.overflow = 'unset'}
    }
  
    return (
      <div className="fixed lg:top-4 lg:right-[20px] bottom-3 right-3 z-50 h-fit">  {/* fixed w-full h-full z-0 ml-9 sm:ml-0 */} {/* lg:right-[800px] */}
      <div className="flex justify-center">

        {/* <div className=" cursor-pointer z-50 rounded-full w-[60px] h-[60px] bg-white shadow-md flex items-center justify-center " onClick={()=>{this.setState({isCartOpen:true})}}> */}
          <div className="relative cursor-pointer z-50 bg-gray-300 rounded-full lg:w-[60px] lg:h-[60px] w-[60px] h-[60px] flex items-center justify-center " onClick={()=>{this.setState({isCartOpen:true})}}>
            <HiOutlineShoppingBag color="black" size={38}/>{/* color="808588" */}
          
          <div className="absolute top-2 right-0">
          {cookie.length > 0 || Object.keys(paket).length > 0 ? <div className=" text-white bg-[#8c52ff] text-[16px] font-bold font-lato text-sm rounded-full w-5 h-5 text-center items-center flex justify-center">{cookie.length + Object.keys(paket).length}</div> : <div></div> } {/* <BsCart4 size={20} />*/}
          {/* {cookie.length > 0 || Object.keys(paket).length > 0 ? <div className=" text-white text-[16px] font-bold font-lato text-sm bg-[#8c52ff] rounded-full w-6 h-6 text-center items-center flex justify-center">{cookie.length + Object.keys(paket).length}</div> : <BsCart4 size={20} />} */}
          
          </div>
        </div>
        </div>
      {/* </div> */}

        {/* <Link onClick={()=>window.scroll(0, 0)} to="/check-out" className={document.URL.indexOf("https://www.smartcash.se/check-out") == 0 || document.URL.indexOf("http://localhost:3000/check-out") == 0 ? ' hidden' : ' flex justify-center bg-gradient-to-tr text-white py-[6px] px-4 rounded-md w-[180px] mx-1'}> */}
          { /* http://localhost:3000/offert ---  https://www.smartcash.se/offert */}
        {/* <div className={ paket.length == undefined ? "cursor-pointer z-50 rounded-xl  w-[250px] h-[50px] bg-[#8c52ff] shadow-md flex items-center justify-center " : "hidden"} > */}
          
          {/* <div className="">
          { <div className="text-white text-[16px] font-bold font-lato text-sm bg-[#8c52ff] rounded-full w-auto h-6 text-center items-center flex justify-center"> Fortsätt din offert </div> }
          
          </div>
        </div>                
        </Link> */}
        
      </div>
    )
  }
}

function GetSideBar_1(onButtonClick){
  if(onButtonClick){
    {document.body.style.overflow = 'hidden'}
    return(
      <SideBar hide={()=>{}} inner={""}/>
    )
  }else{
    {document.body.style.overflow = 'unset'}
  }
}

function NavBar(props)
{
  const styles = StyleSheet.create({
    slideInUp: {
        animationName: slideInUp,
        animationDuration: '1s'
    },
    slideInDown: {
      animationName: slideInDown,
      animationDuration: '1s'
    },
    slideInRight: {
      animationName: slideInRight,
      animationDuration: '1s'
    },
    slideInLeft: {
      animationName: slideInLeft,
      animationDuration: '1s'
    }
})
  var cookie = JSON.parse(getCookie("products"))
  var paket = JSON.parse(getCookie("paket"));
  const [isCartOpen, setIsCartOpen] = useState(false)
  
  const [toggle_products, setToggle_products] = useState(false)
  const [toggle_offer, setToggle_offer] = useState(false)
  const [showProductArrow, setShowProductArrow] = useState(false)
  const [showOfferAnimation, setShowOfferAnimation] = useState(false)
  
  const [productDown, setProductDown] = useState(false)
  const [regionDown, setRegionDown] = useState(false)
  
  const [showregionArrow, setShowregionArrow] = useState(false)
  const [toggle_region, setToggle_region] = useState(false)

  const { height, width } = useWindowDimensions();

 function handleClick() {
    setShowProductArrow(!showProductArrow);
}
 function handleClick_1() {
  setShowregionArrow(!showregionArrow);
}

    /* This iseEffect close the popup when we click outside of it */
      useEffect(() => {
        
        var specifiedProductElement = document.querySelector('.product_menu');
        var specifiedOfferElement = document.querySelector('.offer_menu');
        var specifiedregionElement = document.querySelector('.region_menu');

        function handleClickOutside(event) {
          if(specifiedProductElement.contains(event.target)){
            setToggle_products(true)
          }
          if(specifiedOfferElement.contains(event.target)){
            setToggle_offer(true);
          }
          if(specifiedregionElement.contains(event.target)){
            setToggle_region(true);
          }
          if(!specifiedProductElement.contains(event.target) && !specifiedOfferElement.contains(event.target) && !specifiedregionElement.contains(event.target)){
            setToggle_region(false)

            setShowregionArrow(false);
            
            setToggle_products(false)

            setShowProductArrow(false);

            setToggle_offer(false);

            setShowOfferAnimation(false);
          }
        }
        // Bind the event listener
  
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, []);

      
      
  return(
    
    <div className={"z-10 "}>
      <div className={'flex flex-cols px-4 col-span-1 w-full justify-center mt-4 ' + (props.isSm ? "hidden" : "")} style={{marginRight: "7%"}}>

        <div className="flex flex-col">

        

        {/* <div className="relative cursor-pointer z-50 bg-white rounded-full lg:w-[40px] lg:h-[40px] w-[60px] h-[60px] flex items-center justify-center" onClick={() => {setIsCartOpen(true); }}>
            <HiOutlineShoppingBag color="black" size={32}/>
          
          <div className="absolute top-2 right-0">
          {cookie.length > 0 || Object.keys(paket).length > 0 ? <div className=" text-white bg-[#8c52ff] text-[16px] font-bold font-lato text-sm rounded-full w-5 h-5 text-center items-center flex justify-center">{cookie.length + Object.keys(paket).length}</div> : <div></div> }
          </div>
        </div> */}

        

          
        {props.navBarNumber == 0 ?
          <Link to="/AllOffersPage" className={"mx-3 font-bold uppercase cursor-pointer text-[14px] my-[2px] font-lato px-1 py-1 border-b-2 border-slate-300 2xl:text-[16px] 3xl:text-[14px]" }  /* onClick={() => {setToggle_offer(!toggle_offer); setShowOfferAnimation(!showOfferAnimation);}} */ >Färdiga Paket
          </Link>
          :
          <Link to="/AllOffersPage" className={"mx-3 font-bold uppercase cursor-pointer text-[14px] font-lato 2xl:text-[16px] 3xl:text-[14px]" }  /* onClick={() => {setToggle_offer(!toggle_offer); setShowOfferAnimation(!showOfferAnimation);}} */ >Färdiga Paket
          </Link>}
          {/* Offers */}
          <div className={"w-16 overflow-hidden inline-block " + ((showOfferAnimation) ? "block ": "hidden ") + css(styles.slideInUp)} >
            <div className={"h-7 w-7 bg-white rotate-45 absolute top-[40px] left-[40px]"}></div>
          </div>
          {/* <animated.div style={offerAnimation}> */}
            <div className="mr-24 absolute top-[35px] right-[700px]">
              <div className={"offer_menu flex bg-white rounded-xl shadow-md h-auto w-[650px] absolute my-4 py-2 " + ((toggle_offer) ? "block " : "hidden ") + css(styles.slideInUp)}>
                <Link onClick={()=>window.scrollTo({top: 900, behavior: 'smooth'})} to="/restaurant-and-cafe" className='text-black mx-8 text-[14px] font-bold uppercase font-lato'>       Restaurang & Cafe    </Link>
                <Link onClick={()=>window.scrollTo({top: 900, behavior: 'smooth'})} to="/beauty-and-health" className='text-black mx-8 text-[14px] font-bold uppercase font-lato'>       Skönhet & Hälsa    </Link>
                <Link onClick={()=>window.scrollTo({top: 900, behavior: 'smooth'})} to="/grocery-stores" className='text-black mx-8 text-[14px] font-bold uppercase font-lato'>       Livsmedel & Kiosk    </Link>
              </div>
            </div>
          {/* </animated.div> */}
        </div>

        <div className="flex flex-col">
        
            <div className={" mx-3 font-bold uppercase cursor-pointer text-[14px] font-lato 2xl:text-[16px] 3xl:text-[14px]" } onClick={() => {setToggle_products(!toggle_products); handleClick();}}>Produkter</div>
          
          
            {/* Product */}
            <div className={"w-16 overflow-hidden inline-block " + ((showProductArrow) ? "block ": "hidden ") } >
              <div className={"h-7 w-7 bg-white rotate-45 absolute top-[44px] left-[190px] " }></div>
            </div>

            
                
          {/* <animated.div style={productAnimation}> */}
            <div className="absolute top-[39px] right-[380px]">
              <div className={"product_menu bg-white rounded-xl shadow-md h-[130px] w-[240px] my-4 py-5 " + 
                          (toggle_products ? "opacity-100 " : "opacity-0 invisible ") +
                          (toggle_products ? "transition ease-out duration-200" : "transition ease-in duration-150") + 
                          ((toggle_products) ? "block " : "hidden ") }> {/* + css(styles.slideInUp) */}
                <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/Kassor" className={document.URL.indexOf("https://www.smartcash.se/Kassor") == 0 || document.URL.indexOf("http://localhost:3000/Kassor") == 0 ? 'text-red-500 mx-6  text-[14px] uppercase flex justify-self-center font-lato' : 'text-black mx-6 text-[14px] uppercase flex justify-self-center font-lato'}> <div className="w-6 h-6 mr-1 pt-1"> <FaCashRegister /> </div>       Kassor     </Link>

                <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/Kortterminaler" className={document.URL.indexOf("https://www.smartcash.se/Kortterminaler") == 0 || document.URL.indexOf("http://localhost:3000/Kortterminaler") == 0 ?  ' text-red-500 mx-6 text-[14px] uppercase flex justify-self-center font-lato' : 'text-black mx-6  text-[14px] uppercase flex justify-self-center font-lato'}> <div className="w-6 h-6 mr-1 pt-1"><MdOutlinePayment/> </div>       Kortterminaler     </Link>

                <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/Kvittohantering" className={document.URL.indexOf("https://www.smartcash.se/Kvittohantering") == 0 || document.URL.indexOf("http://localhost:3000/Kvittohantering") == 0 ? 'text-red-500 mx-6  text-[14px] uppercase flex justify-self-center font-lato' : 'text-black mx-6 text-[14px] uppercase flex justify-self-center font-lato'}> <div className="w-6 h-6 mr-1 pt-1"><BsReceiptCutoff/> </div>      Kvittohantering     </Link>
                
                <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/Tillbehor" className={document.URL.indexOf("https://www.smartcash.se/Tillbehor") == 0 || document.URL.indexOf("http://localhost:3000/Tillbehor") == 0 ? 'text-red-500 mx-6  text-[14px] uppercase flex justify-self-center font-lato' : 'text-black mx-6 text-[14px] uppercase flex justify-self-center font-lato'}> <div className="w-6 h-6 mr-1 pt-1"><MdAddShoppingCart/> </div>      Tillbehör     </Link>
                {/* <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/hardware-products" className='text-black mx-6 font-bold text-[14px] uppercase flex justify-self-center font-lato'> <div className="w-6 h-6 mr-1 pt-1"> <MdOutlineLocalDining /> </div>       Köp Hårdvara     </Link> */}
              </div>
            </div>
          {/* </animated.div> */}

          </div>

          <div className="flex flex-col">
        
        <div className={" mx-3 font-bold uppercase cursor-pointer text-[14px] font-lato 2xl:text-[16px] 3xl:text-[14px]" } onClick={() => {setToggle_region(!toggle_region); handleClick_1();}}>återförsäljare</div>
      
      
        {/* Region */}
        <div className={"w-16 overflow-hidden inline-block " + ((showregionArrow) ? "block ": "hidden ") } >
          <div className={"h-7 w-7 bg-white rotate-45 absolute top-[45px] left-80 " }></div>
        </div>

        
            
      {/* <animated.div style={productAnimation}> */}
        <div className="absolute top-10 right-30">
          <div className={"region_menu bg-white rounded-xl shadow-md h-[180px] w-[280px] my-4 py-5 " + 
                      (toggle_region ? "opacity-100 " : "opacity-0 invisible ") +
                      (toggle_region ? "transition ease-out duration-200" : "transition ease-in duration-150") + 
                      ((toggle_region) ? "block " : "hidden ") }>
            <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/kassa_goteborg" className={document.URL.indexOf("https://www.smartcash.se/kassa_goteborg") == 0 || document.URL.indexOf("http://localhost:3000/kassa_goteborg") == 0 ? 'text-red-500 mx-6  text-[14px] uppercase flex justify-self-center font-lato' : 'text-black mx-6 text-[14px] uppercase flex justify-self-center font-lato'}> <div className="w-6 h-6 mr-1 pt-1"> <FaCashRegister /> </div>       Goteborg Kassa     </Link>

            <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/BetalTerminal_goteborg" className={document.URL.indexOf("https://www.smartcash.se/BetalTerminal_goteborg") == 0 || document.URL.indexOf("http://localhost:3000/BetalTerminal_goteborg") == 0 ?  ' text-red-500 mx-6 text-[14px] uppercase flex justify-self-center font-lato' : 'text-black mx-6  text-[14px] uppercase flex justify-self-center font-lato'}> <div className="w-6 h-6 mr-1 pt-1"><MdOutlinePayment/> </div>       Goteborg BetalTerminal     </Link>

            <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/kassa_Linkoping" className={document.URL.indexOf("https://www.smartcash.se/kassa_Linkoping") == 0 || document.URL.indexOf("http://localhost:3000/Kvittohantering") == 0 ? 'text-red-500 mx-6  text-[14px] uppercase flex justify-self-center font-lato' : 'text-black mx-6 text-[14px] uppercase flex justify-self-center font-lato'}> <div className="w-6 h-6 mr-1 pt-1"><FaCashRegister/> </div>      Linkoping Kassa     </Link>
            
            <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/BetalTerminal_Linkoping" className={document.URL.indexOf("https://www.smartcash.se/BetalTerminal_Linkoping") == 0 || document.URL.indexOf("http://localhost:3000/Tillbehor") == 0 ? 'text-red-500 mx-6  text-[14px] uppercase flex justify-self-center font-lato' : 'text-black mx-6 text-[14px] uppercase flex justify-self-center font-lato'}> <div className="w-6 h-6 mr-1 pt-1"><MdOutlinePayment/> </div>      Linkoping BetalTerminal     </Link>

            <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/kassa_Stockholm" className={document.URL.indexOf("https://www.smartcash.se/kassa_Stockholm") == 0 || document.URL.indexOf("http://localhost:3000/Kvittohantering") == 0 ? 'text-red-500 mx-6  text-[14px] uppercase flex justify-self-center font-lato' : 'text-black mx-6 text-[14px] uppercase flex justify-self-center font-lato'}> <div className="w-6 h-6 mr-1 pt-1"><FaCashRegister/> </div>      Stockholm Kassa     </Link>
            
            <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/BetalTerminal_Stockholm" className={document.URL.indexOf("https://www.smartcash.se/BetalTerminal_Stockholm") == 0 || document.URL.indexOf("http://localhost:3000/Tillbehor") == 0 ? 'text-red-500 mx-6  text-[14px] uppercase flex justify-self-center font-lato' : 'text-black mx-6 text-[14px] uppercase flex justify-self-center font-lato'}> <div className="w-6 h-6 mr-1 pt-1"><MdOutlinePayment/> </div>      Stockholm BetalTerminal     </Link>
          </div>
        </div>
    </div>



        
        {props.navBarNumber == 2 ?
        <Link onClick={()=>{window.scroll(0, 0)}} to="/inlosenavtal" className={'mx-3 font-bold uppercase 2xl:text-[16px] 3xl:text-[14px] text-[14px] font-lato px-1 py-1 border-b-2 border-slate-300'}>       Inlösenavtal     </Link>
        :
        <Link onClick={()=>{window.scroll(0, 0)}} to="/inlosenavtal" className={' mx-3 font-bold uppercase cursor-pointer text-[14px] font-lato 2xl:text-[16px] 3xl:text-[14px]'}>       Inlösenavtal     </Link>
        
        }
        {props.navBarNumber == 3 ?
        <Link onClick={()=>{window.scroll(0, 0)}} to="/om_oss" className={ 'mx-3 font-bold uppercase 2xl:text-[16px] 3xl:text-[14px] text-[14px] font-lato px-1 py-1 border-b-2 border-slate-300' }>      Om  oss        </Link>
        :
        <Link onClick={()=>{window.scroll(0, 0)}} to="/om_oss" className={ ' mx-3 font-bold uppercase cursor-pointer text-[14px] font-lato 2xl:text-[16px] 3xl:text-[14px] ' }>      Om  oss        </Link>
        }

        {props.navBarNumber == 4 ?
        <Link onClick={()=>{window.scroll(0, 0)}} to="/kontakt" className={ 'mx-3 font-bold uppercase 2xl:text-[16px] 3xl:text-[14px] text-[14px] font-lato px-1 py-1 border-b-2 border-slate-300'}>       Kontakt       </Link>
        :
        <Link onClick={()=>{window.scroll(0, 0)}} to="/kontakt" className={ ' mx-3 font-bold uppercase cursor-pointer text-[14px] font-lato 2xl:text-[16px] 3xl:text-[14px] ' }>       Kontakt       </Link>
        }



        {/* Region */}
    {/* <div className="flex flex-col">
        
        <div className={" mx-3 font-bold uppercase cursor-pointer text-[14px] font-lato 2xl:text-[16px] 3xl:text-[14px]" } onClick={() => {setToggle_region(!toggle_region); handleClick_1();}}>återförsäljare</div>
      
      
        
        <div className={"w-16 overflow-hidden inline-block " + ((showregionArrow) ? "block ": "hidden ") } >
          <div className={"h-7 w-7 bg-white rotate-45 absolute top-[44px] right-10 " }></div>
        </div>

        <div className="absolute top-10 -right-20">
          <div className={"region_menu bg-white rounded-xl shadow-md h-[180px] w-[280px] my-4 py-5 " + 
                      (toggle_region ? "opacity-100 " : "opacity-0 invisible ") +
                      (toggle_region ? "transition ease-out duration-200" : "transition ease-in duration-150") + 
                      ((toggle_region) ? "block " : "hidden ") }>
            <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/kassa_goteborg" className={document.URL.indexOf("https://www.smartcash.se/kassa_goteborg") == 0 || document.URL.indexOf("http://localhost:3000/kassa_goteborg") == 0 ? 'text-red-500 mx-6  text-[14px] uppercase flex justify-self-center font-lato' : 'text-black mx-6 text-[14px] uppercase flex justify-self-center font-lato'}> <div className="w-6 h-6 mr-1 pt-1"> <FaCashRegister /> </div>       Goteborg Kassa     </Link>

            <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/BetalTerminal_goteborg" className={document.URL.indexOf("https://www.smartcash.se/BetalTerminal_goteborg") == 0 || document.URL.indexOf("http://localhost:3000/BetalTerminal_goteborg") == 0 ?  ' text-red-500 mx-6 text-[14px] uppercase flex justify-self-center font-lato' : 'text-black mx-6  text-[14px] uppercase flex justify-self-center font-lato'}> <div className="w-6 h-6 mr-1 pt-1"><MdOutlinePayment/> </div>       Goteborg BetalTerminal     </Link>

            <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/kassa_Linkoping" className={document.URL.indexOf("https://www.smartcash.se/kassa_Linkoping") == 0 || document.URL.indexOf("http://localhost:3000/Kvittohantering") == 0 ? 'text-red-500 mx-6  text-[14px] uppercase flex justify-self-center font-lato' : 'text-black mx-6 text-[14px] uppercase flex justify-self-center font-lato'}> <div className="w-6 h-6 mr-1 pt-1"><BsReceiptCutoff/> </div>      Linkoping Kassa     </Link>
            
            <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/BetalTerminal_Linkoping" className={document.URL.indexOf("https://www.smartcash.se/BetalTerminal_Linkoping") == 0 || document.URL.indexOf("http://localhost:3000/Tillbehor") == 0 ? 'text-red-500 mx-6  text-[14px] uppercase flex justify-self-center font-lato' : 'text-black mx-6 text-[14px] uppercase flex justify-self-center font-lato'}> <div className="w-6 h-6 mr-1 pt-1"><MdAddShoppingCart/> </div>      Linkoping BetalTerminal     </Link>

            <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/kassa_Stockholm" className={document.URL.indexOf("https://www.smartcash.se/kassa_Stockholm") == 0 || document.URL.indexOf("http://localhost:3000/Kvittohantering") == 0 ? 'text-red-500 mx-6  text-[14px] uppercase flex justify-self-center font-lato' : 'text-black mx-6 text-[14px] uppercase flex justify-self-center font-lato'}> <div className="w-6 h-6 mr-1 pt-1"><BsReceiptCutoff/> </div>      Stockholm Kassa     </Link>
            
            <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/BetalTerminal_Stockholm" className={document.URL.indexOf("https://www.smartcash.se/BetalTerminal_Stockholm") == 0 || document.URL.indexOf("http://localhost:3000/Tillbehor") == 0 ? 'text-red-500 mx-6  text-[14px] uppercase flex justify-self-center font-lato' : 'text-black mx-6 text-[14px] uppercase flex justify-self-center font-lato'}> <div className="w-6 h-6 mr-1 pt-1"><MdAddShoppingCart/> </div>      Stockholm BetalTerminal     </Link>
          </div>
        </div>
    </div> */}
        
      </div>

      



      {/* MOBILE */}
      {/* <div className={'w-screen bg-white absolute h-screen z-10 ' + (props.isSm ? "" : "hidden ") + css(styles.slideInDown)}> */}
      {props.isSm && <div className=" fixed z-10 md:w-full w-full h-full " >

      <div className={'fixed bg-gray-100 z-10 md:w-[320px] w-[320px] h-full inset-y-0 right-0 ' + (props.isSm ? "" : "hidden ") + css(styles.slideInRight)}>
        {/* <div className="bg-[#8c52ff]">
          <div className=" h-[35px] w-full bg-contain bg-center xl:bg-contain bg-no-repeat" style={{backgroundImage: "url(" + logo_new_text_white + ")"}}> <IoIosClose color="white" size={35} className='absolute left-0 top-0 ' onClick={trigger} /></div>
        </div> */}
        <div className={' w-full items-center self-center '} style={{marginRight: "7%"}}>
        
      <div className="flex flex-col w-full">
          <div className=" mx-5 font-bold uppercase cursor-pointer flex justify-start text-gray-500 mt-7 select-none"> Färdiga Paket </div>
            <div className=" md:my-4 flex-col ">
                <Link onClick={()=>window.scrollTo({top: 830, behavior: 'smooth'})} to="/restaurant-and-cafe" className='border-b-2 text-black flex justify-self-center font-lato py-5 px-8 font-semibold text-gray-700 '>       Restaurang & Cafe    </Link>
                <Link onClick={()=>window.scrollTo({top: 830, behavior: 'smooth'})} to="/beauty-and-health" className='border-b-2 text-black flex justify-self-center font-lato py-5 px-8 font-semibold text-gray-700 '>       Skönhet & Hälsa    </Link>
                <Link onClick={()=>window.scrollTo({top: 830, behavior: 'smooth'})} to="/grocery-stores" className='border-b-2 text-black flex justify-self-center font-lato py-5 px-8 font-semibold text-gray-700 '>       Livsmedel & Kiosk    </Link>
            </div>
      </div>
      <div className="flex flex-col w-full">
          <div className=" mx-5 font-bold uppercase cursor-pointer flex justify-start text-gray-400 my-2" onClick={()=> setProductDown(!productDown)}>Produkter {productDown == false ? <div className="mx-2  text-black"><MdKeyboardArrowDown size={25} /></div> : <div className="mx-2 py-1 text-black"></div>} </div>
          {productDown == true && <div className={' md:mx-12 mx-4 my-4 rounded-lg bg-white absolute z-10 md:w-[700px] md:h-[200px] w-[260px] h-[200px] ' + productDown ? css(styles.slideInRight) : "" }>
          <div className=" h-auto w-auto grid grid-cols-1">{/* md:grid-cols-2 */}
            <div className="col-span-1 ml-4 md:my-2">
              <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/Kassor" className={document.URL.indexOf("https://www.smartcash.se/Kassor") == 0 || document.URL.indexOf("http://localhost:3000/Kassor") == 0 ? 'text-red-500 mx-6  text-[14px] uppercase flex justify-self-center font-lato' : 'text-black mx-6 text-[14px] uppercase flex justify-self-center font-lato'}> <div className="w-6 h-6 mr-1 pt-1"> <MdOutlineLocalDining /> </div>       Kassor     </Link>

              <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/Kortterminaler" className={document.URL.indexOf("https://www.smartcash.se/Kortterminaler") == 0 || document.URL.indexOf("http://localhost:3000/Kortterminaler") == 0 ?  ' text-red-500 mx-6 text-[14px] uppercase flex justify-self-center font-lato' : 'text-black mx-6  text-[14px] uppercase flex justify-self-center font-lato'}> <div className="w-6 h-6 mr-1 pt-1"><MdOutlineVolunteerActivism/> </div>       Kortterminaler     </Link>

              <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/Kvittohantering" className={document.URL.indexOf("https://www.smartcash.se/Kvittohantering") == 0 || document.URL.indexOf("http://localhost:3000/Kvittohantering") == 0 ? 'text-red-500 mx-6  text-[14px] uppercase flex justify-self-center font-lato' : 'text-black mx-6 text-[14px] uppercase flex justify-self-center font-lato'}> <div className="w-6 h-6 mr-1 pt-1"><MdAddShoppingCart/> </div>      Kvittohantering     </Link>

              <Link onClick={()=>window.scrollTo({top: 900, behavior: 'smooth'})} to="/restaurant-and-cafe" className={document.URL.indexOf("https://www.smartcash.se/restaurant-and-cafe") == 0 || document.URL.indexOf("http://localhost:3000/restaurant-and-cafe") == 0 ? 'text-red-500 mx-6  text-[14px] uppercase flex justify-self-center font-lato' : 'text-black mx-6 text-[14px] uppercase flex justify-self-center font-lato'}> <div className="w-6 h-6 mr-1 pt-1"><MdAddShoppingCart/> </div>      Restaurant & cafe paket     </Link>

              <Link onClick={()=>window.scrollTo({top: 900, behavior: 'smooth'})} to="/beauty-and-health" className={document.URL.indexOf("https://www.smartcash.se/beauty-and-health") == 0 || document.URL.indexOf("http://localhost:3000/beauty-and-health") == 0 ? 'text-red-500 mx-6  text-[14px] uppercase flex justify-self-center font-lato' : 'text-black mx-6 text-[14px] uppercase flex justify-self-center font-lato'}> <div className="w-6 h-6 mr-1 pt-1"><MdAddShoppingCart/> </div>      SKÖNHET & HÄLSA Paket     </Link>

              <Link onClick={()=>window.scrollTo({top: 900, behavior: 'smooth'})} to="/grocery-stores" className={document.URL.indexOf("https://www.smartcash.se/grocery-stores") == 0 || document.URL.indexOf("http://localhost:3000/grocery-stores") == 0 ? 'text-red-500 mx-6  text-[14px] uppercase flex justify-self-center font-lato' : 'text-black mx-6 text-[14px] uppercase flex justify-self-center font-lato'}> <div className="w-6 h-6 mr-1 pt-1"><MdAddShoppingCart/> </div>      LIVSMEDEL & KIOSK paket     </Link>

              <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/Tillbehor" className={document.URL.indexOf("https://www.smartcash.se/Tillbehor") == 0 || document.URL.indexOf("http://localhost:3000/Tillbehor") == 0 ? 'text-red-500 mx-6  text-[14px] uppercase flex justify-self-center font-lato' : 'text-black mx-6 text-[14px] uppercase flex justify-self-center font-lato'}> <div className="w-6 h-6 mr-1 pt-1"><MdAddShoppingCart/> </div>      Tillbehör     </Link>
              {/* <Link onClick={()=>window.scrollTo({top: 830, behavior: 'smooth'})} to="/restaurant-products" className='text-black flex justify-self-center my-2 font-lato border-b-2 shadow-inner py-2 px-2 mx-2 rounded-lg '> <div className="w-6 h-6 mr-1 pt-1"> <MdOutlineLocalDining /> </div>       Restaurang & Cafe     </Link>
              <Link onClick={()=>window.scrollTo({top: 830, behavior: 'smooth'})} to="/beauty-products" className='text-black flex justify-self-center my-2 font-lato border-b-2 shadow-inner py-2 px-2 mx-2 rounded-lg '> <div className="w-6 h-6 mr-1 pt-1"><MdOutlineVolunteerActivism/> </div>       Skönhet & Hälsa     </Link> */}
            {/* </div>
            <div className="col-span-1 ml-4 md:my-2"> */}
              {/* <Link onClick={()=>window.scrollTo({top: 830, behavior: 'smooth'})} to="/grocery-products" className='text-black flex justify-self-center my-2 font-lato border-b-2 shadow-inner py-2 px-2 mx-2 rounded-lg '> <div className="w-6 h-6 mr-1 pt-1"><MdAddShoppingCart/> </div>      Livsmedel & Kiosk     </Link> */}
              {/* <Link onClick={()=>window.scrollTo({top: 830, behavior: 'smooth'})} to="/hardware-products" className='text-black flex justify-self-center my-2 font-lato border-b-2 '> <div className="w-6 h-6 mr-1 pt-1"><MdOutlineVolunteerActivism/> </div>       Köp Hårdvara     </Link> */}
              {/* <Link to="/restaurant-products" className='text-black mx-6 flex justify-self-center my-1 font-lato '> <div className="w-6 h-6 mr-1 pt-1"> <MdOutlineLocalDining /> </div>       Alla produkter     </Link> */}
            </div>
          </div>
          </div> }
      </div>



      <div className="flex flex-col w-full">
          <div className=" mx-5 font-bold uppercase cursor-pointer flex justify-start text-gray-400 my-2" onClick={()=> setRegionDown(!regionDown)}>återförsäljare {regionDown == false ? <div className="mx-2  text-black"><MdKeyboardArrowDown size={25} /></div> : <div className="mx-2 py-1 text-black"></div>} </div>
          {regionDown == true && <div className={' md:mx-12 mx-4 my-4 rounded-lg bg-white absolute z-10 md:w-[700px] md:h-[200px] w-[260px] h-[200px] ' + regionDown ? css(styles.slideInRight) : "" }>
          <div className=" h-auto w-auto grid grid-cols-1">{/* md:grid-cols-2 */}
            <div className="col-span-1 ml-4 md:my-2">
            <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/kassa_goteborg" className={document.URL.indexOf("https://www.smartcash.se/kassa_goteborg") == 0 || document.URL.indexOf("http://localhost:3000/kassa_goteborg") == 0 ? 'text-red-500 mx-6  text-[14px] uppercase flex justify-self-center font-lato' : 'text-black mx-6 text-[14px] uppercase flex justify-self-center font-lato'}> <div className="w-6 h-6 mr-1 pt-1"> <FaCashRegister /> </div>       Goteborg Kassa     </Link>

            <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/BetalTerminal_goteborg" className={document.URL.indexOf("https://www.smartcash.se/BetalTerminal_goteborg") == 0 || document.URL.indexOf("http://localhost:3000/BetalTerminal_goteborg") == 0 ?  ' text-red-500 mx-6 text-[14px] uppercase flex justify-self-center font-lato' : 'text-black mx-6  text-[14px] uppercase flex justify-self-center font-lato'}> <div className="w-6 h-6 mr-1 pt-1"><MdOutlinePayment/> </div>       Goteborg BetalTerminal     </Link>

            <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/kassa_Linkoping" className={document.URL.indexOf("https://www.smartcash.se/kassa_Linkoping") == 0 || document.URL.indexOf("http://localhost:3000/Kvittohantering") == 0 ? 'text-red-500 mx-6  text-[14px] uppercase flex justify-self-center font-lato' : 'text-black mx-6 text-[14px] uppercase flex justify-self-center font-lato'}> <div className="w-6 h-6 mr-1 pt-1"><FaCashRegister/> </div>      Linkoping Kassa     </Link>

            <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/BetalTerminal_Linkoping" className={document.URL.indexOf("https://www.smartcash.se/BetalTerminal_Linkoping") == 0 || document.URL.indexOf("http://localhost:3000/Tillbehor") == 0 ? 'text-red-500 mx-6  text-[14px] uppercase flex justify-self-center font-lato' : 'text-black mx-6 text-[14px] uppercase flex justify-self-center font-lato'}> <div className="w-6 h-6 mr-1 pt-1"><MdOutlinePayment/> </div>      Linkoping BetalTerminal     </Link>

            <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/kassa_Stockholm" className={document.URL.indexOf("https://www.smartcash.se/kassa_Stockholm") == 0 || document.URL.indexOf("http://localhost:3000/Kvittohantering") == 0 ? 'text-red-500 mx-6  text-[14px] uppercase flex justify-self-center font-lato' : 'text-black mx-6 text-[14px] uppercase flex justify-self-center font-lato'}> <div className="w-6 h-6 mr-1 pt-1"><FaCashRegister/> </div>      Stockholm Kassa     </Link>

            <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/BetalTerminal_Stockholm" className={document.URL.indexOf("https://www.smartcash.se/BetalTerminal_Stockholm") == 0 || document.URL.indexOf("http://localhost:3000/Tillbehor") == 0 ? 'text-red-500 mx-6  text-[14px] uppercase flex justify-self-center font-lato' : 'text-black mx-6 text-[14px] uppercase flex justify-self-center font-lato'}> <div className="w-6 h-6 mr-1 pt-1"><MdOutlinePayment/> </div>      Stockholm BetalTerminal     </Link>
              
            </div>
          </div>
          </div> }
      </div>

      
      <div className="flex flex-col w-full ">

          {/* <a href="https://shop.smartcash.se/" target="_blank" >
            <div className={" border-b-2 text-black flex justify-self-center font-lato px-5 py-5 text-gray-500 font-bold" }>Produkter</div>
          </a> */}
          <Link to="/inlosenavtal" className='border-b-2 text-black flex justify-self-center font-lato px-5 py-5 text-gray-500 font-bold'>Inlösenavtal</Link>
          <Link to="/om_oss" className='border-b-2 text-black flex justify-self-center font-lato px-5 py-5 text-gray-500 font-bold'>Om oss</Link>
          <Link to="/kontakt" className='border-b-2 text-black flex justify-self-center font-lato px-5 py-5 text-gray-500 font-bold'>Kontakta oss</Link>
          {/* <IoIosClose size={70} className='w-full justify-center' onClick={trigger} /> */}
        </div>
      </div>
      </div>
       <div className={"w-full h-full bg-[#00000080] "} onClick={props.trigger}></div>
      </div>}
    </div>
  );
}


