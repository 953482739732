import React, {useState, useEffect} from 'react'

import Product_card from '../Components/Product_card'
import Std_Master from '../Master/Std_Master'
import CardModal from '../Components/CardModal';
import test_6 from "../Gfx/test_6.png"

import westpay_1_new from "../Gfx/westpay_1_new.png";
import westpay_c100_1 from "../Gfx/westpay_c100_1.png";
import Verifone_P400_1 from "../Gfx/Verifone_P400_1.png";
import Verifone_1_new from "../Gfx/Verifone_1_new.png";
import Verifone_2_new from "../Gfx/Verifone_2_new.png";
import Verifone_3_new from "../Gfx/Verifone_3_new.png";

import logo_new_text_white from "../Gfx/logo_new_text_white.png";
import logo_new_text from "../Gfx/logo_new_text.png";

export default function Restaurant_products() {
    const [isOpen, setIsOpen] = useState(false)
    const [reload, setReload] = useState('')


    const _setIsOpen = (_price, _title, _img, _desc, _payAll, _show_checkbox) =>
    {
        setPrice(_price)
        setTitle(_title)
        setImgs(_img)
        setDesc(_desc)
        setPayAll(_payAll)
        setShow_checkbox(_show_checkbox)
        setIsOpen(true);
    }

    const [price, setPrice] = useState("");
    const [title, setTitle] = useState("");
    const [imgs, setImgs] = useState([""]);
    const [desc, setDesc] = useState("");

    const [payAll, setPayAll] = useState("");

    const [show_checkbox, setShow_checkbox] = useState(false);

    const [isUp, setIsUp] = useState(false)

    //const [check, setCheck] = useState(true);

    const isPopUp = () => {
        
        if(isOpen) {
            for(let i = 0;i<=imgs.length;i++){
                return < CardModal key={0} hehe={imgs} hehe1={imgs} title={title} price={price} img={imgs} desc={desc} payAll={payAll} show_checkbox={show_checkbox} close={()=>{setIsOpen(false); setImgs("")}} />
            }
        }
      }

      useEffect(() => {
          if (isOpen == true) {
              document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'unset';
            }
        }, [isOpen]);
        

      //console.log(betala)

    return (
        <div>
    {/* <div>{isOpen ? <CardModal close={()=>{setIsOpen(false)}} /> : null }</div> */}
    {isPopUp()}
        {/* setUp={(value) => {setIsUp(value)}} */}
        {/* (open) => To hide the navbar when the popup for product is open .. there is an if statment in Std_Master (!props.open ?) */}
        <Std_Master open = {isOpen} logo_1={logo_new_text_white} logo={logo_new_text} mobileLogo={logo_new_text_white} mobileLogo_1={logo_new_text_white} classNameCss="w-full vh-full" vid={"https://smartcash-website.s3.eu-north-1.amazonaws.com/restaurang_video.mp4"} inner={
            <div className="mt-20">
                <div className="z-10 max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <h1 className="text-4xl sm:ml-10 mx-5 font-extrabold text-warm-gray-900 sm:text-4xl lg:text-4xl">Restaurang & Cafe</h1>
                    <p className="mt-6 sm:ml-11 ml-6 text-xl text-warm-gray-500 max-w-3xl"> Vi ser fram emot att höra från dig </p>
                </div>
                <div className="md:flex sm:flex flex-wrap -z-50 max-w-7xl mx-auto sm:px-6 lg:px-8 justify-center items-center">
                    

                    <Product_card trigger={()=>_setIsOpen("890 Kr/Mån",  "FEC 9715W", ["https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/restaueang_kassa_1.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/restaueang_kassa_1.png" ,"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/restaueang_kassa_1.png" ], "Vår senaste hårdvara som kan prestera i alla typer av restaurang miljöer samt går det att anpassa efter din verksamhet. Till exemepel kunddisplay & magnetkortläsare")} 
                    update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/restaueang_kassa_1.png"} price="890Kr" title="FEC 9715W" ribbon="890 Kr/Mån" description="Vår senaste hårdvara som kan prestera i alla typer av restaurang miljöer samt går det att anpassa efter din verksamhet. Till exemepel kunddisplay & magnetkortläsare"/>

                    <Product_card trigger={()=>_setIsOpen("279 Kr/Mån", "Sam4s ER-260", ["https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/smart_260_1.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/smart_260_2.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/smart_260_3.png"], <div>Smidig och enkel knappkassa som du kan ha upp till 15 varugrupper med betalsätt kontant, swish & kort.</div>, <div className="flex">Betala på en gång <p className=" flex border-black border-b-2 mx-2">(16 749 Kr)</p></div>, true)}
                    update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/smart_260_1.png"} price="279Kr" title="Sam4s ER-260" ribbon="279 Kr/Mån" description="Smidig och enkel knappkassa som du kan ha upp till 15 varugrupper med betalsätt kontant, swish & kort."/>

                    <Product_card trigger={()=>_setIsOpen("249 Kr/Mån", "Sam4s ER-230", ["https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/smart_230_1.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/smart_230_2.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/smart_230_3.png"], <div>Smidig kassaregister som du kan använda på torg, gatukök & butik och körs på batteri och även strömkabel. Kan ha upp till 15 varugrupper med betalsätt Kontant, swish & kort.</div>, <div className="flex">Betala på en gång <p className=" flex border-black border-b-2 mx-2">(14 949 kr)</p></div>, true)}
                     update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/smart_230_1.png"} price="249Kr" title="Sam4s ER-230" ribbon="249 Kr/Mån" description="Smidig kassaregister som du kan använda på torg, gatukök & butik och körs på batteri och även strömkabel. Kan ha upp till 15 varugrupper med betalsätt Kontant, swish & kort."/>

                    <Product_card trigger={()=>_setIsOpen("890 Kr/Mån", "TritonPOS 4", ["https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/treton_pos_1_1.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/treton_pos_2_1.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/treton_pos_3.png"], <div>Liten och smidig kassasystem som du kan ha i garderob samt vid mindre tillfällen. Hårdvaran är utrustad med Kunddisplay samt skrivare.</div>,<div className="flex">Betala på en gång <p className=" flex border-black border-b-2 mx-2"></p></div>)} 
                    update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/treton_pos_1_1.png"} price="890Kr" title="TritonPOS 4" ribbon="890 Kr/Mån" description="Liten och smidig kassasystem som du kan ha i garderob samt vid mindre tillfällen. Hårdvaran är utrustad med Kunddisplay samt skrivare."/>

                    <Product_card trigger={()=>_setIsOpen("395 Kr/Mån", "Westpay C10", [westpay_1_new, "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/westpay_2.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/westpay_3.png"], <div>Integrerad kortterminal som kopplas till kassan för smidiga och snabba kortbetalningar.</div>, <div className="flex">Betala på en gång <p className=" flex border-black border-b-2 mx-2"></p></div>)}
                    update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={westpay_1_new} price="395Kr" title="Westpay C10" ribbon="395 Kr/Mån" description="Integrerad kortterminal som kopplas till kassan för smidiga och snabba kortbetalningar."/>

                    <Product_card trigger={()=>_setIsOpen("495 Kr/Mån", "Westpay C100", [westpay_c100_1, "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/westpay_c100_2.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/westpay_c100_3.png"], <div>Trådlös kortterminal som går att integrera till kassaprogammet och går även att användas fristående.</div>, <div className="flex">Betala på en gång <p className=" flex border-black border-b-2 mx-2"></p></div>)}
                    update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={westpay_c100_1} price="495Kr" title="Westpay C100" ribbon="495 Kr/Mån" description="Trådlös kortterminal som går att integrera till kassaprogammet och går även att användas fristående."/>

                    <Product_card trigger={()=>_setIsOpen("495 Kr/Mån", "Verifone v400", [Verifone_1_new, Verifone_2_new, Verifone_3_new], <div>Trådlös kortterminal som går att integrera till kassaprogammet och går även att användas fristående.</div>, <div className="flex">Betala på en gång <p className=" flex border-black border-b-2 mx-2"></p></div>)}
                    update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={Verifone_1_new} price="495Kr" title="Verifone v400" ribbon="495 Kr/Mån" description="Trådlös kortterminal som går att integrera till kassaprogammet och går även att användas fristående."/>

                    <Product_card trigger={()=>_setIsOpen("395 Kr/Mån", "Verifone p400", [Verifone_P400_1, "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/Verifone_P400_2.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/Verifone_P400_3.png"], <div>Integrerad kortterminal som kopplas till kassan för smidiga och snabba kortbetalningar.</div>, <div className="flex">Betala på en gång <p className=" flex border-black border-b-2 mx-2"></p></div>)}
                    update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={Verifone_P400_1} price="395Kr" title="Verifone p400" ribbon="395 Kr/Mån" description="Integrerad kortterminal som kopplas till kassan för smidiga och snabba kortbetalningar."/>

                    <Product_card trigger={()=>_setIsOpen("", "GIANT100", ["https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/GIANT100_1.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/GIANT100_2.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/GIANT100_3.png"], <div>Kan användas vid kassan och som köksskrivare.</div>, <div className="flex">Betala på en gång <p className=" flex border-black border-b-2 mx-2">(2990 Kr)</p></div>, true)}
                     update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/GIANT100_1.png"} check={true} price="55Kr" title="GIANT100" description="Kan användas vid kassan och som köksskrivare."/>

                    <Product_card trigger={()=>_setIsOpen("", "U80 Printer", ["https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/Kvittoskrivare_1_new.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/Kvittoskrivare_2_new.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/Kvittoskrivare_3_new.png"], <div>Kan användas vid kassan och som köksskrivare.</div>, <div className="flex">Betala på en gång <p className=" flex border-black border-b-2 mx-2">(2990 Kr)</p></div>, true)}
                     update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/Kvittoskrivare_1_new.png"} check={true} price="55Kr" title="U80 Printer" description="Kan användas vid kassan och som köksskrivare."/>

                    <Product_card trigger={()=>_setIsOpen("", "Kassalåda", ["https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/kassa_loda_1.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/kassa_loda_2.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/kassa_loda_3.png"], <div>Öppnas av kontant köp</div>,<div className="flex">Betala på en gång <p className=" flex border-black border-b-2 mx-2">(1250 Kr)</p></div>,true)}
                     update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/kassa_loda_1.png"} check={true} price="1295Kr" title="Kassalåda" description="Öppnas av kontant köp"/>

                    {/* <Product_card trigger={()=>_setIsOpen("500Kr", "Kassa 1", ["https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/Kvitto_1.png"], <div>Kassaregister Sam4s ER-260 <br /> Kortterminal Smart C-10<br />Kontrollenhet <br />Kassalåda</div>)}
                    update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/Kvitto_1.png"} price="300kr" title="Foodora Kvittorullar (Låda innehåller 50st)" ribbon="300 Kr" description="18m x 57mm funkar perfekt till foodora maskiner"/> */}

                    <Product_card trigger={()=>_setIsOpen("325 Kr", "Foodora Kvittorullar", ["https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Foodora_Kvittorullar.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Foodora_Kvittorullar.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Foodora_Kvittorullar.png"], <div> 18M x 57 Kassa (50st/Låda) <br/> Kan användas för kassan och Foodora terminaler.</div>)}
                     update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Foodora_Kvittorullar.png"} price="325Kr" title="Foodora Kvittorullar " ribbon="325 Kr" description={<div> 18M x 57 Kassa (50st/Låda) <br/> Kan användas för kassan och Foodora terminaler.</div>}/>

                    <Product_card trigger={()=>_setIsOpen("450 Kr", "Kassaregister Kvittorullar", ["https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Kassaregister+Kvittorullar.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Kassaregister+Kvittorullar.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Kassaregister+Kvittorullar.png"], <div>57mm x 50m-(50st/Låda) <br/> Kan användas för kassan.</div>)}
                     update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Kassaregister+Kvittorullar.png"} price="450 Kr" title="Kassaregister Kvittorullar " ribbon="450 Kr" description={<div>57mm x 50m-(50st/Låda) <br/> Kan användas för kassan.</div>}/>

                    <Product_card trigger={()=>_setIsOpen("350 Kr", "Kortterminal Kvittorullar", ["https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Kortterminal_Kvittorullar_1.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Kortterminal_Kvittorullar_1.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Kortterminal_Kvittorullar_1.png"], <div>Kan användas för kortterminaler.</div>)}
                     update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Kortterminal_Kvittorullar_1.png"} price="350 Kr" title="Kortterminal Kvittorullar " ribbon="350 Kr" description={<div>18m Ej kvitto på köp (50st/Låda) <br/> Kan användas för kortterminaler.</div>}/>

                    <Product_card trigger={()=>_setIsOpen("450 Kr", "Pc-kassa Kvittorullar", ["https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Pc_kassa_Kvittorullar.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Pc_kassa_Kvittorullar.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Pc_kassa_Kvittorullar.png"], <div>Kan användas för kassan och köksskrivare.</div>)}
                     update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Pc_kassa_Kvittorullar.png"} price="450 Kr" title="Pc-kassa Kvittorullar " ribbon="450 Kr" description={<div>80mm x 57m (30st/Låda) <br/> Kan användas för kassan och köksskrivare.</div>}/>

                    {/* <InfoCard img={test_10} css="text-[#F01E82]" main_css={"w-[300px] h-[350px]"} />
                    <InfoCard img={test_7} css="text-[#F01E82]" main_css={"w-[300px] h-[350px]"} />
                    <InfoCard img={test_8} css="text-[#F01E82]" main_css={"w-[300px] h-[350px]"} />
                    <InfoCard img={test_9} css="text-[#962896]" main_css={"w-[300px] h-[350px]"} /> */}
                </div>
            </div>
        } />
        </div>
    )
}
