import {React, useRef, useEffect, useState} from 'react'
import Std_Master from '../../Master/Std_Master'
import Enox_ipad_iphon from '../../Gfx/Enox_ipad_iphon.png'
import top_image_new from "../../Gfx/top_image_new.webp";
import test_666_mobile from "../../Gfx/test_666_mobile.png";
import dela_nota from "../../Gfx/dela_nota.png";
import bordskarta from "../../Gfx/bordskarta.png";
import lager from "../../Gfx/lager.png";
import logo_new_text_black from '../../Gfx/logo_new_text_black.png'
import logo_new_text_white from '../../Gfx/logo_new_text_white.png'
import { FaCheckCircle } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';



export default function Betalterminal_Lin() {

  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
      
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    });
  }, []);
    return (
        <div className="bg-white">

          <Helmet replace={true}>
            <title>Betalterminal Linköping | Kortterminal Linköping | Stabilt & säkert</title>
          </Helmet>
        
        <Std_Master 
         NavCss={"text-black"} TitleCss={"text-white text-[17px] ml-12 hidden xl:block 2xl:mt-[300px] 3xl:mt-[400px] custom-opacity rounded-lg xl:top-[220px]"}
         rubrikCss={"text-white"}
         logo_1={logo_new_text_black}
         mobileNavBarCssWhenScroll={"text-white"}
        mobileNavBarCssWhenNotScroll={"text-black"}
         logo={logo_new_text_black}
         /* mobileLogo={logo_new_text_white} mobileLogo_1={logo_new_text_white} */ mobileLogo={logo_new_text_black} mobileLogo_1={logo_new_text_white}
         rubrik={"Betalterminal Linköping | Kortterminal Linköping | Stabilt & säkert"}
         Title={" Vi erbjuder användarvänliga betalterminaler och kortterminaler för detaljhandel, restauranger, kiosker, livsmedelsbutiker, torghandel och salonger i Linköping "} 
         img={top_image_new}
        img_mobile={test_666_mobile}
         ShowButton={true}
        inner={



          <div className="md:container md:mx-auto my-12 px-4 md:px-0" data-aos="zoom-in-up">

      

            <div className="flex flex-col md:flex-row justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Skonhet.png"} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:mr-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Kortterminal Linköping – smidigt och användarvänligt</h2>
                <p className="text-lg mb-4 xl:mx-24">Vi erbjuder pålitliga och användarvänliga betalterminaler och kortterminaler för detaljhandel, restaurangverksamhet, kiosker, livsmedelsbutiker, torghandel och salonger i Linköping. Efter att ha lyssnat på våra kunder har vi tagit fram en rad branschanpassade betallösningar som är stabila och säkra. 
                                                    Hos oss får ni alltid service i världsklass. Vi lyssnar på er och era önskemål. Kontakta oss och berätta om era behov så återkommer vi med en produkt som vi tror fungerar för er!

                </p>
                <div className="text-start mt-4 xl:mx-24"> {/* Added a margin-top for spacing. Adjust as per your need */}
                  <div className="">
                    <Link to="/AllOffersPage" className='flex justify-center bg-gradient-to-tr bg-[#8c52ff] text-xl text-white py-[8px] px-4 rounded-md xl:w-[260px] w-auto'> Kolla våra kortterminaler </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row-reverse justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={Enox_ipad_iphon} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:ml-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Mindre kö och nöjdare kunder</h2>
                <p className="text-lg mb-4 xl:mx-24">De flesta av oss har någon gång fastnat i en lång kö där man efter ett tag givit upp och gått därifrån. Som affärs- eller caféinnehavare är det förstås något som man i största möjliga mån vill undvika då det betyder förlorad inkomst och, i värsta fall, en förlorad kund. 
                                                    Våra betalterminaler är mycket stabila och klarar av ett högt tryck. När betalningen går snabbt blir det mindre köer och gladare kunder. Kortterminalerna som vi har är enkla för kunden att använda vilket gör att det sällan blir fel. Om det finns behov av service eller om ni stöter på några problem så finns alltid våra servicetekniker till hands och kan hjälpa er. 
                </p>
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/restaurang_enox_program.png"} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:mr-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Portabel och flexibel kortterminal</h2>
                <p className="text-lg mb-4 xl:mx-24">Våra betalterminaler kommer i flera olika utföranden. Alla är användarvänliga och enkla att lära sig. Att ha en trådlös kortterminal är mycket smidigt. Framförallt i affärer och restauranger med många kunder. Då kan säljare eller servicepersonal avsluta köpet där kunden är utan att de behöver ta sig till kassan. 
                                                    Att avsluta en affär snabbt kan vara bra av flera anledningar, det gör att kunden slipper vänta, vilket ger ett bättre intryck av er service och engagemang, dessutom minimerar det risken för osäkerhet vilket annars kan leda till att kunden helt enkelt ångrar sig och inte köper det som den först tänkt. 
                </p>
              </div>
            </div>

            <div className="flex flex-col md:flex-row-reverse justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={lager} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:ml-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Pålitlig betalterminal i Linköping</h2>
                <p className="text-lg mb-4 xl:mx-24">För att er verksamhet ska fungera är det viktigt att det är enkelt och tryggt för era kunder att betala. Det skall även vara okomplicerat för er att få ut tydlig data över vilka köp som gjorts under dagen samt lätt att rätta till eventuella felslag. 
                                                    Våra produkter och mjukvarusystem är alltid uppdaterade efter de senaste lagarna och direktiven som finns angående ekonomisk hantering och redovisning. Det gör det lätt att göra rätt och innebär att det är tryggt och säkert för både er och era kunder. Våra system är enkla att förstå och på så vis även enkla att handskas med.

                </p>
                <div className="text-start mt-4 xl:mx-24"> {/* Added a margin-top for spacing. Adjust as per your need */}
                  <div className="">
                    <Link to="/AllOffersPage" className='flex justify-center bg-gradient-to-tr bg-[#8c52ff] text-xl text-white py-[8px] px-4 rounded-md xl:w-[220px] w-auto'> Se mer av våra paket </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={dela_nota} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:mr-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Betalterminal Linköping – en bra affär</h2>
                <p className="text-lg mb-4 xl:mx-24">Med våra betalterminaler får ni en bra produkt till ett rimligt pris. Kortterminalerna vi tillhandahåller har hög klass. Med våra abonnemang får ni också kundfokuserad service och direkt support om det skulle dyka upp några problem eller frågor. 
                                                    Våra betalsystem går att skala upp. Det är enkelt för er att lägga till andra betalterminaler eller tillbehör allt eftersom er affärsverksamhet blir större och utvecklas. 
                                                    Kontakta oss så berättar vi mer om hur vi kan hjälpa er att hitta bra betallösningar!
                </p>
              </div>
            </div>

            <div className="flex flex-col md:flex-row-reverse justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              
              <img src={bordskarta} alt="Göteborg Betalterminal" class="w-full md:w-1/2 p-12 object-cover rounded-lg shadow mb-4 md:mb-0 md:ml-4"/>

              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">En möjlighet till bättre service </h2>
                <p className="text-lg mb-4 xl:mx-24">När betalningssystemet fungerar som det ska och kunderna kan betala utan problem så frigörs tid som du och dina medarbetare kan lägga på att ge era kunder bättre service. Blir personalen hela tiden uppbunden av kassakrångel leder det till att kunderna tröttnar. Svåra system gör att dyrbar arbetstid går åt till att lära upp nyanställda medarbetare som sedan ändå riskerar att göra fel. 
                                                    Med våra tillförlitliga kortterminaler kan både ni och era kollegor slappna av och fokusera på kunden. Med god betjäning kommer kunderna att komma tillbaka och ta med sig sina vänner och bekanta. Låt oss hjälpa er göra betalningsprocessen lätt och stabil så att ni kan ge era kunder service i världsklass!
                </p>
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={dela_nota} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:mr-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Smart Cash – en seriös partner i Linköping</h2>
                <p className="text-lg mb-4 xl:mx-24">Vårt företag grundades 2010 och sedan dess har vi arbetat med att ta fram stabila, smidiga och användarvänliga produkter och tjänster för våra kunder. Vi vet att kundnöjdhet är något av det absolut viktigaste för den som vill bygga upp ett företag som växer och frodas år efter år. 
                                                      Alla våra kunder är vitala för vår verksamhet - stora som små. Vårt mål är att alltid finnas tillgänglig för information, service och feedback. När ni väljer oss som leverantör av kortterminaler och betalningssystem kan ni lita på vårt engagemang och välvilja genom hela processen. Vi planerar för en långsiktig verksamhet och är en seriös partner från första kontakt - så länge som ni önskar!
                </p>
              </div>
            </div>






            

            {/* Continue for the other sections */}

            <div className="mt-10 flex justify-center" data-aos="zoom-in-up">
            <button
              className="px-8 py-4 rounded-lg bg-[#8c52ff] text-white font-bold hover:bg-[#7F00FF] transition duration-200"
              onClick={() => window.location.href = "https://www.smartcash.se/kontakt"}
            >
              Kontakt oss
            </button>
            </div>

            </div>

}
         />
        </div>
    )
}
