import {React, useRef, useEffect, useState} from 'react'
import Std_Master from '../../Master/Std_Master'
import Enox_ipad_iphon from '../../Gfx/Enox_ipad_iphon.png'
import top_image_new from "../../Gfx/top_image_new.webp";
import test_666_mobile from "../../Gfx/test_666_mobile.png";
import dela_nota from "../../Gfx/dela_nota.png";
import bordskarta from "../../Gfx/bordskarta.png";
import lager from "../../Gfx/lager.png";
import logo_new_text_black from '../../Gfx/logo_new_text_black.png'
import logo_new_text_white from '../../Gfx/logo_new_text_white.png'
import { FaCheckCircle } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';



export default function Betalterminal_Sto() {

  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
      
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    });
  }, []);
    return (
        <div className="bg-white">

          <Helmet replace={true}>
            <title>Betalterminal Stockholm | Kortterminal Stockholm | Smart Cash</title>
          </Helmet>
        
        <Std_Master 
         NavCss={"text-black"} TitleCss={"text-white text-[17px] ml-12 hidden xl:block 2xl:mt-[300px] 3xl:mt-[400px] custom-opacity rounded-lg xl:top-[220px]"}
         rubrikCss={"text-white"}
         logo_1={logo_new_text_black}
         mobileNavBarCssWhenScroll={"text-white"}
        mobileNavBarCssWhenNotScroll={"text-black"}
         logo={logo_new_text_black}
         /* mobileLogo={logo_new_text_white} mobileLogo_1={logo_new_text_white} */ mobileLogo={logo_new_text_black} mobileLogo_1={logo_new_text_white}
         rubrik={"Betalterminal Stockholm | Kortterminal Stockholm | Smart Cash"}
         Title={" Vårt mål är att tillhandahålla användarvänliga kortterminaler och betalterminaler som får er verksamhet i Stockholm att flyta på utan köer och krångel. "} 
         img={top_image_new}
        img_mobile={test_666_mobile}
         ShowButton={true}
        inner={



          <div className="md:container md:mx-auto my-12 px-4 md:px-0" data-aos="zoom-in-up">

            <div className="flex flex-col md:flex-row justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Skonhet.png"} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:mr-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Kortterminal Stockholm – en enkel lösning för ert företag</h2>
                <p className="text-lg mb-4 xl:mx-24">Vårt mål är att tillhandahålla användarvänliga kortterminaler och kassasystem som får er verksamhet att flyta på utan köer och krångel. Att enkelt kunna ta betalt är viktigt för alla typer av företag, oavsett bransch. Våra betalningslösningar gör det enkelt för er oavsett om ni driver en restaurang, en frisersalong eller en kiosk. Men hjälp av våra kassaexperter kommer ni hitta den perfekta lösningen för er. 
                                                    Med vårt breda sortiment av kortterminaler kan vi garantera att ni hittar en lösning som passar era önskemål och behov. Vi har terminaler som är lätta att installera och använda. Det gör att ni kan komma igång snabbt och slipper att dyrbar arbetstid går åt till att förstå ett komplicerat system.</p>
              </div>
            </div>

            <div className="flex flex-col md:flex-row-reverse justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={Enox_ipad_iphon} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:ml-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Rätt kortterminal för er verksamhet</h2>
                <p className="text-lg mb-4 xl:mx-24">Att ha en passande kortterminal är viktigt. Med rätt betalningssystem kan ni effektivisera betalningsprocessen och öka er omsättning. Varje företag är unikt. Det är därför vi är så noggranna med att förstå exakt vad som behövs. Våra tekniker, utvecklare och säljare kommer att arbeta tätt tillsammans med er för att kunna föreslå den kortterminal som bäst uppfyller era krav. 
                                                    Med våra moderna och säkra system får ni tillgång till smidiga funktioner som förenklar betalningen, och som ser till att transaktioner sker på ett tryggt sätt.
                </p>
                <div className="text-start mt-4 xl:mx-24"> {/* Added a margin-top for spacing. Adjust as per your need */}
                  <div className="">
                    <Link to="/AllOffersPage" className='flex justify-center bg-gradient-to-tr bg-[#8c52ff] text-xl text-white py-[8px] px-4 rounded-md xl:w-[220px] w-auto'> Se mer av våra paket </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/restaurang_enox_program.png"} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:mr-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Service och säkerhet för er och era kunder</h2>
                <p className="text-lg mb-4 xl:mx-24">Kundservice är något som engagerar oss. Vi vill ge er snabb, tillförlitlig support. Ett uppdaterat system borgar för trygga och säkra betalningar. För att kunna bygga förtroende och skapa långsiktiga relationer är det avgörande att våra system levererar det varje gång.
                                                    Våra kortterminaler är utformade med stabila säkerhetsfunktioner som uppfyller de lagar och direktiv som finns i Sverige och EU. De omfattande säkerhetsåtgärderna minimerar risken för bedrägerier och skyddar era kunders personliga och finansiella uppgifter. Genom att ha en säker kassa och kortterminal kan ni skapa en pålitlig och positiv profil för er verksamhet.
                                                    Ta steget mot en säkrare service för era kunder. Hör av er till oss idag för att få reda på mer om våra system!
                </p>
              </div>
            </div>

            <div className="flex flex-col md:flex-row-reverse justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={lager} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:ml-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">En betalterminal att räkna med i Stockholm</h2>
                <p className="text-lg mb-4 xl:mx-24">Vi har kortterminaler som är anpassade för Stockholms dynamiska företagsmiljö. Med våra betalningslösningar kan ni säkerställa snabba och enkla betalningar, oavsett om ni driver en kiosk, café eller kemtvätt i Stockholm. Våra terminaler är kompatibla med alla vanliga betalkort, swish och kontanter. 
                                                    När ni väljer oss som er partner får ni inte bara en bra betalterminal utan även engagerad support. Våra rutinerade tekniker finns alltid tillgängliga för att hjälpa er med installation, service och rådgivning. Vi är här för att se till att ert betalningssystem är stabilt och bekymmersfritt.
                </p>
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={dela_nota} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:mr-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Torghandel eller frisörsalong – anpassade betalningsterminaler</h2>
                <p className="text-lg mb-4 xl:mx-24">Oavsett om ni driver ett korvstånd eller restaurang, har hand om en nattklubbsgarderob eller en skönhetssalong, har vi passande betalningsterminaler som uppfyller era unika behov och gör er verksamhet ännu smidigare och effektivare.
                                                    För exempelvis torghandlare erbjuder vi portabla och robusta betalningsterminaler som drivs med batteri och har integrerade kvittoskrivare. Ni kan ge era kunder flera betalningsalternativ och ta betalt enkelt och trygg. För salonger och kiosker har vi lättanvända och kompakta kortterminaler och kassaregister som får plats oavsett utrymme.
                                                    Inom detaljhandeln är det viktigt med tillförlitlighet. Våra betalningsterminaler är utrustade med känsliga kortläsare och användarvänligt interface för att underlätta snabba och smidiga transaktioner. För café, restaurang och bar erbjuder vi betalningsterminaler som är anpassade för hantering av bordsservice och som tål ett högt tryck.
                </p>
                <div className="text-start mt-4 xl:mx-24"> {/* Added a margin-top for spacing. Adjust as per your need */}
                  <div className="">
                    <Link to="/AllOffersPage" className='flex justify-center bg-gradient-to-tr bg-[#8c52ff] text-xl text-white py-[8px] px-4 rounded-md xl:w-[220px] w-auto'> Se mer av våra paket </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row-reverse justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              
              <img src={bordskarta} alt="Göteborg Betalterminal" class="w-full md:w-1/2 p-12 object-cover rounded-lg shadow mb-4 md:mb-0 md:ml-4"/>

              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Tillbehör som förenklar</h2>
                <p className="text-lg mb-4 xl:mx-24">Vi har inte bara betalningsterminaler utan även ett stort sortiment av olika tillbehör som är utformade för att förenkla betalningsprocessen. Ett exempel på vad man kan välja till är våra populära kassalådor och sedelfack som hjälper er att hålla er kontanthantering säker. 
                                                    För er som driver en restaurang eller annan servering har vi lösningar som underlättar betalningen. Ni kan på ett lätt sätt skicka beställningar till köket. När gästerna har ätit upp och är nöjda kan ni ta emot betalningen direkt vid bordet.
                                                    För butik och detaljhandel har vi scanner för exakt och effektiv produktscanning. Våra tillbehör fungerar med de flesta POS-system och kan enkelt integreras med våra andra system.
                </p>
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={dela_nota} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:mr-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Smart Cash – dedikation och erfarenhet</h2>
                <p className="text-lg mb-4 xl:mx-24">Vi vet att pengatransaktioner kan vara riskfyllda och komplicerade. Med vår erfarenhet och innovativa teknik kan ni hantera era transaktioner med liten ansträngning och stor säkerhet.
                                                    Räkna era intäkter snabbt, eliminera fel och minska möjligheten för stöld eller bedrägeri.
                                                    Vår erfarenhet med betalningslösningar har gett oss en djup kunskap kring olika branschers behov och utmaningar.  Med Smart Cash får ni inte bara en partner som bryr sig om kortterminaler och kassaregister, utan en partner som bryr sig om er framgång. Vi är här för att hjälpa er att spara tid och resurser, optimera er hantering samt minska riskerna.
                </p>
              </div>
            </div>






            

            {/* Continue for the other sections */}

            <div className="mt-10 flex justify-center" data-aos="zoom-in-up">
            <button
              className="px-8 py-4 rounded-lg bg-[#8c52ff] text-white font-bold hover:bg-[#7F00FF] transition duration-200"
              onClick={() => window.location.href = "https://www.smartcash.se/kontakt"}
            >
              Kontakt oss
            </button>
            </div>

            </div>

}
         />
        </div>
    )
}
