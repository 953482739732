import {React, useRef, useEffect, useState} from 'react';
import { getCookie , setCookie} from './CookieHandler'
import { BiPlusMedical } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import Std_Master from '../Master/Std_Master'

/* import kort_terminal_1 from '../Gfx/kort_terminal_1.png' */
import icon_img from '../Gfx/icon_img.png'
import Kontakt_card from './Kontakt_card';
import { useNavigate } from 'react-router-dom'
import swal from 'sweetalert'


export default function Paket_Offert(props) {
  return (
    <div className="relative overflow-hidden">
        {/* <div className={"absolute transform bg-[#8c52ff] text-center items-center text-[12px] md:text-[12px] lg:text-[12px] xl:text-[10px] 2xl:text-[13px] text-white font-semibold py-2 rounded-full top-[-35px] left-[260px] sm:left-[350px] md:left-[290px] lg:left-[250px] xl:left-[260px] xl:-top-[10px] 2xl:left-[350px] 2xl:top-[10px] md:w-[65px] md:h-[65px] lg:w-[65px] lg:h-[65px] xl:w-[65px] xl:h-[65px] 2xl:w-[85px] 2xl:h-[85px] w-[85px] h-[85px] " + props.css}> */}
        <div className={"absolute transform rotate-45 bg-[#8c52ff] text-center text-white font-semibold py-1 right-[-34px] top-[32px] w-[170px] " + props.css}>
            {props.ribbon}
        </div>
        <img className={"w-[400px] p-4 bg-contain bg-center bg-no-repeat " + props.imgCss} src={props.img}/>
        <div className=" flex justify-center">
            <h2 className="text-xl font-bold text-gray-700 sm:text-2xl flex text-center">{props.title}</h2>
        </div>
        {/* <div className=" px-6 py-4"> */}
        <div className=" px-6 py-4 grid grid-cols-10">
            <div className="col-span-1 bg-cover bg-no-repeat bg-center w-5 h-5 mt-[5px] ml-2" style={{backgroundImage: "url(" + icon_img + ")"}}></div>
            <p className="col-span-9 flex text-sm text-gray-500 my-1">{props.text_1}</p>

            <div className="col-span-1 bg-cover bg-no-repeat bg-center w-5 h-5 mt-[5px] ml-2" style={{backgroundImage: "url(" + icon_img + ")"}}></div>
            <p className="col-span-9 flex text-sm text-gray-500 my-1">{props.text_2}</p>

            <div className="col-span-1 bg-cover bg-no-repeat bg-center w-5 h-5 mt-[5px] ml-2" style={{backgroundImage: "url(" + icon_img + ")"}}></div>
            <p className="col-span-9 flex text-sm text-gray-500 my-1">{props.text_3}</p>

            <div className="col-span-1 bg-cover bg-no-repeat bg-center w-5 h-5 mt-[5px] ml-2" style={{backgroundImage: "url(" + icon_img + ")"}}></div>
            <p className="col-span-9 flex text-sm text-gray-500 my-1">{props.text_4}</p>

            <div className="col-span-1 bg-cover bg-no-repeat bg-center w-5 h-5 mt-[5px] ml-2" style={{backgroundImage: "url(" + icon_img + ")"}}></div>
            <p className="col-span-9 flex text-sm text-gray-500 my-1">{props.text_5}</p>

            <div className="col-span-1 bg-cover bg-no-repeat bg-center w-5 h-5 mt-[5px] ml-2" style={{backgroundImage: "url(" + icon_img + ")"}}></div>
            <p className="col-span-9 flex text-sm text-gray-500 my-1">{props.text_6}</p>
            <p className="col-span-9 flex text-sm text-gray-500 my-1"></p>
            <div
            onClick={()=>{
                /* let customProduct = {name:props.title,img:props.img};
                if(getCookie("pakets").length>0){ //cookie?
                    //yey there was a cookie :)
                    //lets add to it
                    let products = JSON.parse(getCookie("pakets"));
                    products.push(customProduct);
                    setCookie("pakets",JSON.stringify(products));
                }
                else{
                    //there was no cookie :(
                    //lets create a cookie
                    let prod = [];
                    prod.push(customProduct);
                    setCookie("pakets",JSON.stringify(prod));
                } */
                /* props.update(); */
                
            }} className="col-span-5 flex text-sm text-gray-500 my-4 justify-center items-center duration-300 ease-in-out hover:-translate-y-1 hover:scale-110">
                <div className='flex justify-center bg-gradient-to-tr bg-[#8c52ff] text-white py-[6px] px-4 rounded-md w-[180px] mx-1' onClick={() =>{ props.trigger() }}>
                <div  className='text-white border-6 py-1 px-1 flex cursor-pointer' > Få offert </div> </div>
            </div>
            {/* <div
            onClick={()=>{
                let customProduct = {name:props.title,img:props.img};
                if(getCookie("pakets").length>0){ //cookie?
                    //yey there was a cookie :)
                    //lets add to it
                    let products = JSON.parse(getCookie("pakets"));
                    products.push(customProduct);
                    setCookie("pakets",JSON.stringify(products));
                }
                else{
                    //there was no cookie :(
                    //lets create a cookie
                    let prod = [];
                    prod.push(customProduct);
                    setCookie("pakets",JSON.stringify(prod));
                }
                props.update();
            }} className="col-span-5 flex text-sm text-gray-500 my-4 justify-center items-center duration-300 ease-in-out hover:-translate-y-1 hover:scale-110">
                <Link onClick={()=>window.scroll(0, 0)} to="/kontakt" className='flex justify-center bg-gradient-to-tr bg-[#8c52ff] text-white py-[6px] px-4 rounded-md w-[180px] mx-1'>
                <div  className='text-white border-6 py-1 px-1 flex cursor-pointer' to=""> Få offert </div>  </Link>
            </div> */}
            
            <div className="col-span-5 flex text-sm text-gray-500 my-4 justify-center items-center duration-300 ease-in-out hover:-translate-y-1 hover:scale-110">
                <div  className='flex justify-center bg-gradient-to-tr bg-[#8c52ff] text-white py-[6px] px-4 rounded-md w-[180px] mx-1 cursor-pointer' onClick={() =>{ props.trigger_2() }}>
                <div  className='text-white border-6 py-1 px-1 flex cursor-pointer' > Detaljer </div> </div>
            </div>
        </div>   
    </div>
  )
}
