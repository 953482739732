import React, {useState} from 'react'

import Product_card from '../../Components/Product_card'
import Std_Master from '../../Master/Std_Master'
import CardModal from '../../Components/CardModal';

import logo_new_text_white from "../../Gfx/logo_new_text_white.png";
import logo_new_text from "../../Gfx/logo_new_text.png";
import { Helmet } from 'react-helmet';

export default function Tillbehör() {
    const [isOpen, setIsOpen] = useState(false)
    const [reload, setReload] = useState('')


  const _setIsOpen = (_enGongPrice, _price, _title, _img, _desc, _payAll, _show_checkbox) =>
  {
      setEnGongPrice(_enGongPrice)
      setPrice(_price)
      setTitle(_title)
      setImgs(_img)
      setDesc(_desc)
      setPayAll(_payAll)
      setShow_checkbox(_show_checkbox)
      setIsOpen(true);
  }

  const [enGongPrice, setEnGongPrice] = useState("");
  const [price, setPrice] = useState("");
  const [title, setTitle] = useState("");
  const [imgs, setImgs] = useState([""]);
  const [desc, setDesc] = useState("");

  const [payAll, setPayAll] = useState("");
  const [show_checkbox, setShow_checkbox] = useState(false);
  
  const isPopUp = () => {
      
      if(isOpen) {
          return < CardModal key={0} enGong={enGongPrice} hehe={imgs} hehe1={imgs} title={title} price={price} img={imgs} desc={desc} payAll={payAll} show_checkbox={show_checkbox} close={()=>{setIsOpen(false); setImgs("")}} />
      }
    }
    
  return (
    <div>
        {isPopUp()}

        <Std_Master logo_1={logo_new_text_white} logo={logo_new_text} mobileLogo={logo_new_text_white} mobileLogo_1={logo_new_text_white} classNameCss="w-full vh-full" vid={"https://smartcash-website.s3.eu-north-1.amazonaws.com/beauty_and_health_video.mp4"} inner={
            <div className="py-20 bg-white">

                <Helmet replace={true}>
                    <title>Smart Cash - Tillbehör</title>
                </Helmet>

                <div className="z-10 max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <h1 className="text-4xl sm:ml-10 mx-5 font-extrabold text-warm-gray-900 sm:text-4xl lg:text-4xl">Tillbehör</h1>
                    <p className="mt-6 sm:ml-11 ml-6 text-xl text-warm-gray-500 max-w-3xl"> Vi ser fram emot att höra från dig </p>
                </div>
                <div className="md:flex sm:flex flex-wrap -z-50 max-w-7xl mx-auto sm:px-6 lg:px-8 justify-center items-center">

                    <Product_card trigger={()=>_setIsOpen("1995", "1995", "Handskanner", ["https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/handskanner_1.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/handskanner_2.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/handskanner_3.png"], <div>Handskanner med 1.5 meters kabel, Skanner QR coden och EAN </div>, <div className="flex">Betala på en gång <p className=" flex border-black border-b-2 mx-2">(1995 Kr)</p></div>)}
                    update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/handskanner_1.png"} /* check={true} */ price="1995 Kr" title="Handskanner" ribbon="1995 Kr" description="Handskanner med 1.5 meters kabel, Skanner QR coden och EAN"/>

                    <Product_card trigger={()=>_setIsOpen("2990" ,"2990", "GIANT100", ["https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/GIANT100_1.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/GIANT100_2.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/GIANT100_3.png"], <div>Kan användas vid kassan och som köksskrivare.</div>, <div className="flex">Betala på en gång <p className=" flex border-black border-b-2 mx-2">(2990 Kr)</p></div>)}
                     update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/GIANT100_1.png"} /* check={true} */ price="2990 Kr" title="GIANT100" ribbon="2990 Kr" description="Kan användas vid kassan och som köksskrivare."/>

                    <Product_card trigger={()=>_setIsOpen("2990", "2990", "U80 Printer", ["https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/Kvittoskrivare_1_new.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/Kvittoskrivare_2_new.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/Kvittoskrivare_3_new.png"], <div>Kan användas vid kassan och som köksskrivare.</div>, <div className="flex">Betala på en gång <p className=" flex border-black border-b-2 mx-2">(2990 Kr)</p></div>)}
                     update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/Kvittoskrivare_1_new.png"} /* check={true} */ price="2990 kr" title="U80 Printer" ribbon="2990 Kr" description="Kan användas vid kassan och som köksskrivare."/>

                    <Product_card trigger={()=>_setIsOpen("1250" ,"1250", "Kassalåda", ["https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/kassa_loda_1.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/kassa_loda_2.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/kassa_loda_3.png"], <div>Öppnas av kontant köp</div>,<div className="flex">Betala på en gång <p className=" flex border-black border-b-2 mx-2">(1250 Kr)</p></div>)}
                     update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/kassa_loda_1.png"} /* check={true} */ price="1250" title="Kassalåda" ribbon="1250 Kr" description="Öppnas av kontant köp"/>

                    <Product_card trigger={()=>_setIsOpen("12995" ,"259 Kr/Mån", "DiGi Våg", ["https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/vag_1.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/vag_1.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/vag_2.png"], <div>Kassa kopplad våg </div>, <div className="flex">Betala på en gång <p className=" flex border-black border-b-2 mx-2">(12 995 Kr)</p></div>,true)}
                    update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/vag_1.png"} price="1050Kr" title="DiGi Våg" ribbon="259 Kr/Mån" description="Kassa kopplad våg"/>

                    
                </div>
            </div>
        } />
    </div>
  )
}




















































/* import React, {useState, useEffect} from 'react'

import Product_card from '../../Components/Product_card'
import Std_Master from '../../Master/Std_Master'
import CardModal from '../../Components/CardModal';

import logo_new_text_white from "../../Gfx/logo_new_text_white.png";
import logo_new_text from "../../Gfx/logo_new_text.png";

const OPTION = ["both", "buy" , "rent"]
const PRODUCT_1 = {
"id": 1 ,
"name": "Handskanner",
"img":["https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/handskanner_1.png","https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/handskanner_2.png","https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/handskanner_3.png"],

"desc_1": "Handskanner med 1.5 meters kabel, Skanner QR coden och EAN",
"desc_2": "Handskanner med 1.5 meters kabel, Skanner QR coden och EAN1111111",
"option": OPTION[0],
"priceOption":[500, 2000],
"price": 500,
}


export default function Tillbehör() {
    const [isOpen, setIsOpen] = useState(false)
    const [reload, setReload] = useState('')

    const [checked, setChecked] = useState(false)


    const [firstProduct, setFirsProduct] = useState(PRODUCT_1)


  const _setIsOpen = (_price, _title, _img, _desc, _payAll, _show_checkbox) =>
  {
      setPrice(_price)
      setTitle(_title)
      setImgs(_img)
      setDesc(_desc)
      setPayAll(_payAll)
      setShow_checkbox(_show_checkbox)
      setIsOpen(true);
  }

  const [price, setPrice] = useState("");
  const [title, setTitle] = useState("");
  const [imgs, setImgs] = useState([""]);
  const [desc, setDesc] = useState("");

  const [payAll, setPayAll] = useState("");
  const [show_checkbox, setShow_checkbox] = useState(false);
  
  const isPopUp = () => {
      
      if(isOpen) {
          return < CardModal obj={firstProduct} setObj={(v)=>setFirsProduct(v)} close={()=>{setIsOpen(false); }} />
      }
    }

    useEffect(() => {
        console.log(checked)
        //setReload("d")
    },[checked])
    
  return (
    <div>
        {isPopUp()}

        <Std_Master logo_1={logo_new_text_white} logo={logo_new_text} mobileLogo={logo_new_text_white} mobileLogo_1={logo_new_text_white} classNameCss="w-full vh-full" vid={"https://smartcash-website.s3.eu-north-1.amazonaws.com/beauty_and_health_video.mp4"} inner={
            <div className="mt-20">
                <div className="z-10 max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <h1 className="text-4xl sm:ml-10 mx-5 font-extrabold text-warm-gray-900 sm:text-4xl lg:text-4xl">Tillbehör</h1>
                    <p className="mt-6 sm:ml-11 ml-6 text-xl text-warm-gray-500 max-w-3xl"> Vi ser fram emot att höra från dig </p>
                </div>
                <div className="md:flex sm:flex flex-wrap -z-50 max-w-7xl mx-auto sm:px-6 lg:px-8 justify-center items-center">

                    <Product_card trigger={()=> {setIsOpen(true)}}
                    update={()=> reload == "d" ? setReload("xd") : setReload("d")} obj={firstProduct}  />


                    
                </div>
            </div>
        } />
    </div>
  )
}
 */