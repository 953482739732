import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Std_Master from '../Master/Std_Master';
import Desc from '../Components/Desc';
import InfoCard from '../Components/InfoCard';
import Kontakt_card from '../Components/Kontakt_card';


import kund_1 from "../Gfx/kund_1.jpeg"
import kund_2 from "../Gfx/kund_2.jpeg"
import kund_3 from "../Gfx/kund_3.jpeg"
import kund_10 from "../Gfx/kund_10.jpg"
import kund_11 from "../Gfx/kund_11.png"
import kund_12 from "../Gfx/kund_12.png"
import kund_13 from "../Gfx/kund_13.jpg"

import Lane_3000 from "../Gfx/Lane_3000.webp"

import Partaj from "../Gfx/Partaj.jpeg"




import logo_new_text from "../Gfx/logo_new_text.png";
import logo_new_text_white from "../Gfx/logo_new_text_white.png";

import MySlider from '../Components/MySlider';
import { Helmet } from 'react-helmet';





export default function Home() {

  const [count, setCount] = useState(0);
  
  const images = [kund_1, kund_2, kund_3, kund_10, kund_11, kund_12, kund_13, kund_1, kund_2, kund_3, kund_10];
  


  function ActiveCustomer(){
    var initialDate = new Date(2007, 10, 4);
    var now = Date.now();
    var difference = now - initialDate;
    var millisecondsPerDay = 24 * 60 * 60 * 1000;
    var daysSince = Math.floor(difference / millisecondsPerDay);
    setCount(daysSince)

   
  }
  
  useEffect(() => {
    ActiveCustomer(); 
  },[]);

  return(
    <Std_Master TitleCss={" 2xl:top-[150px] text-[50px] md:text-[80px] lg:text-[80px] xl:text-[100px] font-bold mt-[200px] 2xl:mt-[400px] "} logo_1={logo_new_text_white} logo={logo_new_text} mobileLogo={logo_new_text_white} mobileLogo_1={logo_new_text_white} classNameCss="w-full vh-full"  vid={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Start_video_2.mp4"} inner={
    <div className="bg-white">
          <Helmet replace={true}>
            <title>Smart Cash - Home</title>
          </Helmet>

    <div className="pt-[60px]">
        <h2 className="relative text-center text-lg sm:text-4xl uppercase font-extrabold text-gray-900 tracking-tight "> Några av våra kunder </h2>
        <div className="flex flex-wrap gap-24 justify-center w-full " >  
            
            <div class="relative h-[250px] w-[1200px]">
              <div class="absolute inset-10 "><MySlider /></div>
            </div>
          
          
        </div>
    </div>
      
      <div className='flex items-center justify-center w-full '> {/* mt-8 */} {/* lg:pt-[100px] */}
        <div className=' flex grid-cols-5 flex-wrap justify-center gap-x-6'>
          <div className='col-start-2 col-span-1 lg:relative'>
            <Link onClick={()=>window.scrollTo({top: 900, behavior: 'smooth'})} to="/restaurant-and-cafe">
              <InfoCard main_css={"md:w-[350px] md:h-[450px] w-[250px] h-[350px]"} css={"md:mt-52 mt-36"} content_css={"md:mt-48 mt-32"} check={"1"} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Restaurang_home_page.png"} title_2={"RESTAURANG & CAFE"} />
            </Link>
          </div>
          <div className='col-start-2 col-span-1 lg:relative'>
            <Link onClick={()=>window.scrollTo({top: 900, behavior: 'smooth'})} to="/beauty-and-health">
              <InfoCard main_css={"md:w-[350px] md:h-[450px] w-[250px] h-[350px]"} css={"md:mt-52 mt-36"} content_css={"md:mt-48 mt-32"} check={"1"} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Sko%CC%88nhet_home_page_1.png"} title_2={"SKÖNHET & HÄLSA"} />
            </Link>
          </div>
          <div className='col-start-2 col-span-1 lg:relative'>
            <Link onClick={()=>window.scrollTo({top: 900, behavior: 'smooth'})} to="/grocery-stores">
              <InfoCard main_css={"md:w-[350px] md:h-[450px] w-[250px] h-[350px]"} css={"md:mt-52 mt-36"} content_css={"md:mt-48 mt-32"} check={"1"} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Livs_medel_home_page.png"} title_2={"LIVSMEDEL & KIOSK"} />
            </Link>
          </div>
        </div>
      </div>

      <div className='h-24 md:h-44' />
      
      <div className='w-full grid-cols-6 hidden xl:grid bg-gradient-to-tr bg-[#8c52ff] skew-y-12 h-[400px]'> {/* i have fixed height because i have the gif with fixed height and width */}
          <div className='col-span-2 flex items-center justify-center -skew-y-12 bg-cover bg-center bg-no-repeat w-full mt-3 h-[460px]' style={{backgroundImage: "url(" + "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/kassa_2.png" + ")"}}></div>
          
          <div className='col-span-2 items-center justify-center -skew-y-12 pt-16'>
            <Desc title={"VAD ERBJUDER VI?"} description={"Smart Cash kan som väletablerat bolag inom kassabranschen erbjuda er moderna lösningar på era betalningsbehov med en flexibilitet i service och support som är unik på marknaden oavsett storlek på företag."}/>
          </div>
          <div className='col-span-2 flex items-center justify-center -skew-y-12 2xl:bg-contain bg-cover bg-center bg-no-repeat w-full ' style={{backgroundImage: "url(" + Lane_3000 + ")"}}></div>
          

      </div>
      <div className='w-full lg:h-[450px] h-[280px] xl:hidden grid'> {/* h-[250px] */}

        <div className='w-full h-full bg-contain md:bg-cover bg-no-repeat rounded-t-2xl z-0 flex items-center justify-center' /* iphone_12_pro:w-[390px] iphone_12_pro:h-[390px]  lg:w-full lg:h-[420px]*/
             style={{backgroundImage: "url(" + "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/kassa_2.png" + ")"}}/>

        <div className="z-10 absolute mt-24 lg:mt-[400px] flex justify-center w-full">
          <Desc title={"VAD ERBJUDER VI?"} description={"Smart Cash är ett väletablerat bolag i kassabranschen och erbjuder de absolut senaste funktionerna som marknaden efterfrågar. Vi strävar alltid efter att ge våra kunder en unik kundupplevelse med förstklassig service, oavsett företagsstorlek."}/>
        </div>
      </div>

      <div className='h-24 md:h-44' />

    
    <div className="lg:my-12 border-b-2">
        <h2 className="relative text-center text-lg sm:text-4xl uppercase font-extrabold text-gray-900 tracking-tight mt-56 mb-16 md:mt-16"> {/* Några av våra kunder */} </h2>
        <div className="flex flex-wrap gap-24 justify-center w-full my-12" >  

        </div>
    </div>
        
      <div className='max-w-7xl mx-auto py-16 px-4 sm:py-16 sm:px-6 lg:px-8'>
        <Kontakt_card hidden={"hidden"} />
      </div>
    </div>
    } />
  );
}
