import React, {useState, useEffect, useRef} from 'react'
import { getCookie , setCookie, spliceCookie} from './CookieHandler';
import Inputfield_std from '../Components/Inputfield_std'
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import bg_kontakt_card_1 from '../Gfx/bg_kontakt_card_1.png'
import swal from 'sweetalert';
import Btn_std from '../Components/Btn_std'
import emailjs from '@emailjs/browser'

export default function CardModalOffers(props) {

    /* const [imgi, setImgi] = useState(0) */
    const [changeImg, setChangeImg] = useState(0)

    const [reload, setReload] = useState("")

    const [products, setProducts] = useState(props.product)

    const [paketProduct, setPaketProduct] = useState(props.productPaket)

    const [paketName, setPaketName] = useState(props.paket)


    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [company_name, setCompany_name] = useState("")
    
    function paket_info(){
        var value = paketName
        return value?.name

    }

    function product_info(){
        var value = products

        var string = ""
        value.map((obj, key)=>{
         string += obj?.count == 0 ? "" : String(obj?.name) + "<br/>" + "Total:" + String(obj?.count) + "<br/>" + "<br/>" + "<br/>" + "<br/>" + "<br/>" + "<br/>" + "<br/>"

        })
        return string
    }


    const form = useRef();
        
          const sendEmail = (e) => {
            e.preventDefault();
  
            emailjs.sendForm('service_2e8damt', 'template_j0l03jr', form.current, 'hF2Szh2vLy0l-NBUc')
              .then((result) => {
                  props.close()
              }, (error) => {
                  console.log(error.text);
              });
          };

    useEffect(() => {
        //console.log(props)
    },[])

    function sweetalertNotification(){
        swal({
            title: "Tack för din offertförfrågan!",
            text: "En säljare kommer att kontakta dig inom kort!",
            icon: "success",
            button: "Klar",
            timer: 20000
            })
            setTimeout(function(){
                //window.location.reload(1);
             }, 2000);
    }

    const [check, setCheck] = useState(false)


    function Increment(index){
        var arr = products
        arr[index].count += 1
        setProducts(arr)
        reload == "d" ? setReload("xd") : setReload("d")
    }
    
    function Decrement(index){
        var arr = products
        if(arr[index].count > 0){
            arr[index].count -= 1
        }
        setProducts(arr)
        reload == "d" ? setReload("xd") : setReload("d")
    }

    var paketProducts = JSON.parse(getCookie("paket"));
    

    return (
    <div className="fixed z-50 w-full h-full bg-[#00000050] " onClick={()=>{props.close()}}>
        <div className="sm:w-full sm:h-full flex justify-center  items-center ">
        <form ref={form} onSubmit={sendEmail}>
        <div onClick={(e) => e.stopPropagation()} className="mx-4 grid grid-rows-7 sm:w-[1000px] w-fit h-[800px] sm:h-[880px] overflow-auto bg-gray-100 shadow-md rounded-lg bg-cover " style={{backgroundImage: "url(" +  bg_kontakt_card_1 + ")"}}> {/* bg_kontakt_card_1 */}
        <div className="flex justify-center mb-4 font-semibold text-[24px] m-2 p-1 text-black border-b-2 sm:mx-[300px] my-6">{paketName.name} paket</div>
            {/* <div className="grid grid-rows-2"></div> */}
        <div className="row-span-1 grid sm:grid-cols-4 grid-cols-2 py-6">
            <div className="col-span-2 sm:mx-7 ">
                            
                        <div className="flex justify-center mb-4 font-semibold text-[23px] text-gray-500 border-b m-2 p-1 rounded-lg">Ditt paket innehåller</div>
                        <div className="shadow-lg my-2 ease-in-out rounded-lg border mx-1 sm:mx-0 w-fit sm:w-full h-auto bg-gray-50 transition duration-500 hover:shadow-2xl">
                        {paketProduct.map((obj, key)=>{
                            {return (
                                
                                <div className="flex justify-center items-center text-center sm:mx-5 mx-2 my-2" key={key}>
                                    <div className="p-2 border duration-300 ease-in-out rounded-lg h-fit w-fit transition shadow-md bg-white" > {/* hover:-translate-y-1 */} {/* p-2 shadow-lg duration-300 ease-in-out rounded-lg h-[100px] w-[450px] border */}
                                        <div className="flex text-center  ">
                                            <div className="grid grid-cols-5 h-[100px] " >
                                                <img className="col-span-2 bg-no-repeat bg-contain" src={obj.img}/> {/* sm:h-[90px] sm:w-[160px] */}
                                                <div className="col-span-2 text-gray-700 font-semibold text-start items-start mt-2 text-[14px] "> {obj.name} <p className="text-[13px] text-gray-400">{obj.desc}</p> </div>
                                                {/* <div className="col-span-2 text-gray-700 font-semibold text-center mt-2 text-[15px]"> {obj.name} </div> */}
                                                <div className="col-span-1 text-gray-700 font-semibold ml-6 mt-2 text-[15px]"> Antal <p className="">1</p> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            )}
                        })}

                </div>
            </div>

        <div className="col-span-2 overflow-auto sm:h-fit mx-2">
            <div className="flex justify-center mb-4 font-semibold text-[23px] sm:text-gray-500 text-gray-700 border-b m-2 p-1 rounded-lg">Tillval (lägg till fler produkter)</div> {/* sm:mx-32 */}
            <div className="shadow-lg my-2 ease-in-out rounded-lg border bg-gray-50 transition duration-500 hover:shadow-2xl mx-1"> {/* mt-16 */}
                    {products.map((obj, key)=>{
                        if(obj.id <= 5)

                    {return (
                            
                        <div className="p-2 duration-300 ease-in-out hover:-translate-y-1 rounded-lg h-fit w-fit my-2 mx-2 sm:w-fit flex justify-center  shadow-md bg-white select-none" key={key}>
                                <div className="grid grid-cols-3">
                                    <img className="col-span-1 bg-no-repeat bg-contain " src={obj.img}/>
                                    <div className="col-span-1 text-gray-700 font-semibold text-start items-start "> {obj.name} <p className="text-[12px] text-gray-400">{obj.desc}</p> </div>
                                    {/* <div className="col-span-1 text-gray-700 font-semibold text-center items-center flex"> {obj.count * 123}  </div> */}
                                    {/* <div className=" col-span-1 grid grid-rows-3 ml-12"> */}
                                    <div className="grid grid-rows-2 items-center text-center ">
                                        <div className="grid grid-cols-4 row-span-1 items-center text-center ">
                                            <div className={"sm:ml-[53px] ml-[27px] col-span-1 shadow-xl rounded-full w-8 h-8 py-1 px-2 cursor-pointer hover:-translate-y-1 hover:scale-110"} onClick={()=>{Decrement(obj.id)}}> <div className="py-1"><AiOutlineMinus /> </div></div>
                                            <div className="sm:ml-[53px] ml-[40px]"><p>{obj.count}</p></div>
                                            <div className={"sm:ml-9 ml-[27px] col-span-1 shadow-xl rounded-full w-8 h-8 py-1 px-2 cursor-pointer hover:-translate-y-1 hover:scale-110"} onClick={()=>{{Increment(obj.id);}}}> <div className="py-1"><AiOutlinePlus /> </div></div>
                                        </div>
                                        <div className="row-span-1 items-center text-center ">
                                            <div className={obj.count > 0 ? "sm:ml-[53px] ml-[40px] text-[15px]" : "hidden"}><p>{parseInt(obj.price) * obj.count} :-</p></div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                            )}
                    })}

            </div>
        </div>

        {/* Förmular */}

        {/* <div className=" grid grid-cols-4 w-full mt-4 bg-cover bg-center bg-no-repeat relative col-span-7 justify-center ease-in-out rounded-lg " > */}{/* container mx-auto px-20 */}
            <div className="col-span-2 grid grid-cols-4 bg-gray-50 sm:w-[500px] sm:mx-[250px] w-fit mx-3 ease-in-out shadow-2xl rounded-lg mt-7">
                <div className="relative col-span-4 text-sm font-medium flex justify-center text-center text-black text-[20px] py-4">Fyll i formuläret så kontaktar vi dig direkt</div>
                    <div className=" col-span-2 px-2 w-full pb-4"> 
                    <input type="text" value={product_info()} name="info" placeholder="hehe" className="hidden w-full text-xs shadow-md focus:ring-gray-300 focus:border-gray-300 border-gray-100 rounded-md py-2 px-2" />
                    <input type="text"  value={paket_info()} name="paket" placeholder="hehe" className="hidden w-full text-xs shadow-md focus:ring-gray-300 focus:border-gray-300 border-gray-100 rounded-md py-2 px-2" />
                        <Inputfield_std name="user_name"  value={(e)=>{setName(e)}} placeholder="Namn" className="w-full text-xs rounded-md px-6" />
                    </div>
                    <div className="col-span-2 px-2 w-full">    
                        <Inputfield_std name="company_name"  value={(e)=>{setCompany_name(e)}} placeholder="Företagsnamn" className="w-full text-xs rounded-md px-6 " />
                    </div>
                    <div className="col-span-2 px-2 w-full">
                        <Inputfield_std name="user_email"  value={(e)=>{setEmail(e)}} placeholder="E-post" className="w-full text-xs rounded-md px-6" />
                    </div>
                    <div className="col-span-2 px-2 w-full pb-12">
                        <Inputfield_std name="user_phone"  value={(e)=>{setPhone(e)}} placeholder="Telefonnummer" className="w-full text-xs rounded-md px-6" />
                    </div>
            </div>
        {/* </div> */}
    </div>
         
            <div className="row-span-1 grid grid-cols-3">
                

                <div className="col-span-3 mb-12">                 
                    <div onClick={()=>{
                        
                        }} className="col-span-5 flex text-sm text-gray-500 my-4 justify-center items-center duration-300 ease-in-out "> {/* hover:-translate-y-1 hover:scale-110 */}
                        {/* <div onClick={()=>{sweetalertNotification(); props.close()}} className='flex justify-center bg-gradient-to-tr bg-white text-white py-[6px] px-4 rounded-md w-[180px] mx-1'> */}
                        <Btn_std type={"submit"} true={name.length  && phone.length && email.length && company_name.length != 0 ? false :true}
                                value={"Send"}
                                ClassName={name.length && phone.length && email.length && company_name.length != 0 ? " flex text-gray-600 bg-gray-200 py-[6px] px-4 rounded-md w-[200px] font-medium pt-2 " : " text-gray-600 bg-gray-200 opacity-75 cursor-not-allowed "}
                                trigger={ name.length  && phone.length && email.length && company_name.length != 0 ? () =>  {sweetalertNotification()} : ()=>{}} inner={"Få kostnadsfri offert"} /> {/* </div> */}
                        
                    </div>
                                            
                    <div onClick={()=>{props.close()}} className="col-span-5 flex text-sm text-gray-500 justify-center items-center duration-300 ease-in-out"> {/* hover:-translate-y-1 hover:scale-110 */}
                        <div  className=' flex justify-center text-gray-600 bg-gray-200 px-4 rounded-md w-[180px] border-6 py-[10px] cursor-pointer' to=""> Avbryt </div>  
                    </div>
                </div>
                <div className="col-span-1"></div>
            </div>
        </div>
        </form>
    </div>
</div>
        
        
    )
}


