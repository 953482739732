import React from 'react';
import PropTypes from 'prop-types';

const TrustPilot = ({ locale, templateId, businessunitId, theme, minReviewCount }) => (
    <div className=" flex-col py-52 min-h-screen space-y-10">
        <div className="text-4xl text-center">
            Bli den första att lämna ett omdöme om oss på
        </div>
        <div className="trustpilot-widget max-w-7xl mx-auto py-16 px-4 sm:py-16 sm:px-6 lg:px-24 text-4xl" 
            data-locale={locale} 
            data-template-id={templateId} 
            data-businessunit-id={businessunitId} 
            data-style-height="24px" 
            data-style-width="100%" 
            data-theme={theme} 
            data-min-review-count={minReviewCount} 
            data-style-alignment="center">
            <a href={`https://se.trustpilot.com/review/${businessunitId}`} 
            target="_blank" 
            rel="noopener noreferrer">
            Trustpilot
            </a>
        </div>
        <div className="text-4xl text-center">
            <a href={`http://localhost:3000/`} 
            /* target="_blank"  */
            rel="noopener noreferrer">
                Förtsätt handla
            </a>
        </div>
    </div>
);

TrustPilot.propTypes = {
    locale: PropTypes.string,
    templateId: PropTypes.string,
    businessunitId: PropTypes.string,
    theme: PropTypes.string,
    minReviewCount: PropTypes.number
};

TrustPilot.defaultProps = {
    locale: "sv-SE",
    templateId: "5419b6a8b0d04a076446a9ad",
    businessunitId: "64a2a1f6773d67e07559fb78",
    theme: "light",
    minReviewCount: 10
};

export default TrustPilot;
