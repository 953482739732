import {React, useRef, useEffect, useState} from 'react'
import Std_Master from '../../Master/Std_Master'
import Enox_ipad_iphon from '../../Gfx/Enox_ipad_iphon.png'
import top_image_new from "../../Gfx/top_image_new.webp";
import test_666_mobile from "../../Gfx/test_666_mobile.png";
import dela_nota from "../../Gfx/dela_nota.png";
import bordskarta from "../../Gfx/bordskarta.png";
import lager from "../../Gfx/lager.png";
import logo_new_text_black from '../../Gfx/logo_new_text_black.png'
import logo_new_text_white from '../../Gfx/logo_new_text_white.png'
import { FaCheckCircle } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';



export default function Kassa_Sto() {

  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
      
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    });
  }, []);
    return (
        <div className="bg-white">

          <Helmet replace={true}>
            <title>Kassasystem Stockholm | Kassaregister Stockholm | Smart Cash</title>
          </Helmet>
        
        <Std_Master 
         NavCss={"text-black"} TitleCss={"text-white text-[17px] ml-12 hidden xl:block 2xl:mt-[300px] 3xl:mt-[400px] custom-opacity rounded-lg xl:top-[220px]"}
         rubrikCss={"text-white"}
         logo_1={logo_new_text_black}
         mobileNavBarCssWhenScroll={"text-white"}
        mobileNavBarCssWhenNotScroll={"text-black"}
         logo={logo_new_text_black}
         /* mobileLogo={logo_new_text_white} mobileLogo_1={logo_new_text_white} */ mobileLogo={logo_new_text_black} mobileLogo_1={logo_new_text_white}
         rubrik={"Kassasystem Stockholm | Kassaregister Stockholm | Smart Cash"}
         Title={" Vi erbjuder säkra och pålitliga kassaregister skräddarsydda för behoven hos företag i Stockholm. Kassor som är smidiga och trygga att använda för era kunder. "} 
         img={top_image_new}
        img_mobile={test_666_mobile}
         ShowButton={true}
        inner={



          <div className="md:container md:mx-auto my-12 px-4 md:px-0" data-aos="zoom-in-up">

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center my-8 bg-white rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <div className="text-center md:text-left p-4">
                <h2 className="text-2xl sm:text-3xl md:text-2xl font-bold mb-4">Kassasystem Linköping – salong, café eller detaljhandel?</h2>
                <p className="text-base sm:text-lg md:text-lg ">Varje verksamhet är unik. Vi lyssnar alltid på våra kunder och försöker specialanpassa betalningsutrustningen så att den passar era användningsområden. Våra engagerade serviceexperter finns för att bistå dig att välja rätt kassalösning och tillbehör.</p>
              </div>
              <div className="p-4">
                <h2 className="text-xl sm:text-2xl md:text-2xl font-bold mb-4">Vi erbjuder skräddarsydda paket som är utformade för att tillgodose de särskilda behoven inom flera olika branscher såsom:</h2>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <div>
                    <p className="my-2 text-lg md:text-md font-semibold flex items-center space-x-2"><FaCheckCircle className="text-green-500 mx-1"/>Restaurang, café, bar och klubb</p>
                    <p className="text-lg md:text-md font-semibold flex items-center space-x-2"><FaCheckCircle className="mx-1 text-green-500"/>Kiosker och livsmedelsbutiker</p>
                  </div>
                  <div>
                    <p className="my-2 text-lg md:text-md font-semibold flex items-center space-x-2"><FaCheckCircle className="mx-1 text-green-500"/>Frisörer och skönhetssalonger</p>
                    <p className="text-lg md:text-md font-semibold flex items-center space-x-2"><FaCheckCircle className="mx-1 text-green-500"/>Kemtvätt och servicebutiker</p>
                  </div>
                  <div>
                    <p className="my-2 text-lg md:text-md font-semibold flex items-center space-x-2"><FaCheckCircle className="mx-1 text-green-500"/>Detaljhandel</p>
                    <p className="text-lg md:text-md font-semibold flex items-center space-x-2"><FaCheckCircle className="mx-1 text-green-500"/>Torghandel</p>
                  </div>
                  {/* <div>
                    <p className="my-2 text-lg md:text-md font-semibold flex items-center space-x-2"><FaCheckCircle className="mx-1 text-green-500"/>Torghandel och gatukök</p>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Skonhet.png"} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:mr-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Kassasystem Stockholm – pålitliga betalsystem</h2>
                <p className="text-lg mb-4 xl:mx-24">Vi erbjuder säkra och pålitliga kassaregister, skräddarsydda för behoven hos företag i Stockholm. Våra kassasystem är utformade med den senaste teknologin och har innovativa funktioner som gör det enkelt för dig att hantera försäljning, inventering och betalningar. 
                                                    Våra kassasystem är anpassbara för flera olika branscher och verksamheter och är enkla att använda med användarvänliga gränssnitt. Vi finns tillgängliga med support för installation, utbildning och service.
                </p>
              </div>
            </div>

            <div className="flex flex-col md:flex-row-reverse justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={Enox_ipad_iphon} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:ml-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Smidiga kassaregister för företag i Stockholm</h2>
                <p className="text-lg mb-4 xl:mx-24">Våra kassaregister hjälper er att smidigt bedriva er dagliga verksamhet. De är intuitiva, enkla att använda och ger er mer tid att fokusera på era kunder genom att snabbt och problemfritt hantera transaktioner. När ni har tid att ge bättre service kommer det snart synas på era dagsrapporter i form av större omsättning. Ni kan smidigt skanna produkter, hantera prissättning, registrera betalningar och skriva ut kvitton med bara några tryck. 
                                                    För företag med särskilda behov erbjuder vi anpassningsbara inställningar för att skapa unika produktkategorier, tillämpa rabatter, hantera flera betalningsmetoder och generera detaljerade försäljningsrapporter.
                </p>
                <div className="text-start mt-4 xl:mx-24"> {/* Added a margin-top for spacing. Adjust as per your need */}
                  <div className="">
                    <Link to="/AllOffersPage" className='flex justify-center bg-gradient-to-tr bg-[#8c52ff] text-xl text-white py-[8px] px-4 rounded-md xl:w-[220px] w-auto'> Se mer av våra paket </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/restaurang_enox_program.png"} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:mr-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Kassa Stockholm – ett system utan krångel</h2>
                <p className="text-lg mb-4 xl:mx-24">Problem med kassor som stänger ner och låser sig när de behövs som mest är tyvärr mycket vanligt. Kunder som får vänta länge riskerar att ångra sig och gå därifrån. Det är förstås varje butiksinnehavare och caféägares mardröm. Våra kassor är robusta och håller även för högt tryck. 
                                                    Vi erbjuder en prisvärd, stabil och effektiv kassalösning för företag i Stockholm. Med vårt kassasystem kan ni snabbt och smidigt hantera betalningar, så att ni kan driva ert företag utan stress och frustration. Vårt system är utformat med en användarvänlig design för att minimera inlärningstiden för era medarbetare.
                </p>
              </div>
            </div>

            <div className="flex flex-col md:flex-row-reverse justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={lager} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:ml-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Service och support från Smart Cash</h2>
                    <p className="text-lg mb-4 xl:mx-24">Vi strävar efter att bygga starka och långsiktiga partnerskap med våra kunder. Vi vill förstå era unika behov och utmaningar för att på så vis kunna skapa skräddarsydda lösningar som passar perfekt för just er. När ni behöver oss  finns vi tillgängliga för att erbjuda rådgivning så att ni kan optimera er pengahantering och maximera era resultat. När ni väljer att samarbeta med oss på Smart Cash kan ni vara säkra på att ni får den bästa servicen och supporten på marknaden.
                </p>
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={dela_nota} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:mr-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Säker kassa för er och era kunder</h2>
                <p className="text-lg mb-4 xl:mx-24">Vi erbjuder en säker kassa som skyddar era affärstransaktioner. Säkra betalningar ger er och era kunder sinnesfrid. Med avancerade säkerhetsfunktioner och implementering efter strikta branschstandarder för datasäkerhet kan ni vara trygga i vetskapen om att era betalningar är skyddade från hot och intrång. Med trygga betalningar kan ni behålla och stärka ert företags rykte och förtroende. 
                                                    För att ytterligare förstärka säkerheten erbjuder vi olika autentiseringsmetoder och behörighetsinställningar. Det ger er full kontroll över era försäljningstransaktioner och minimerar risken för obehörig åtkomst.
                </p>
              </div>
            </div>

            <div className="flex flex-col md:flex-row-reverse justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              
              <img src={bordskarta} alt="Göteborg Betalterminal" class="w-full md:w-1/2 p-12 object-cover rounded-lg shadow mb-4 md:mb-0 md:ml-4"/>

              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Säker kassa för er och era kunder</h2>
                <p className="text-lg mb-4 xl:mx-24">Vi erbjuder en säker kassa som skyddar era affärstransaktioner. Säkra betalningar ger er och era kunder sinnesfrid. Med avancerade säkerhetsfunktioner och implementering efter strikta branschstandarder för datasäkerhet kan ni vara trygga i vetskapen om att era betalningar är skyddade från hot och intrång. Med trygga betalningar kan ni behålla och stärka ert företags rykte och förtroende. 
                                                    För att ytterligare förstärka säkerheten erbjuder vi olika autentiseringsmetoder och behörighetsinställningar. Det ger er full kontroll över era försäljningstransaktioner och minimerar risken för obehörig åtkomst.
                </p>
                <div className="text-start mt-4 xl:mx-24"> {/* Added a margin-top for spacing. Adjust as per your need */}
                  <div className="">
                    <Link to="/AllOffersPage" className='flex justify-center bg-gradient-to-tr bg-[#8c52ff] text-xl text-white py-[8px] px-4 rounded-md xl:w-[220px] w-auto'> Se mer av våra paket </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={dela_nota} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:mr-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Snabb kassa – en konkurrensfördel i Stockholm</h2>
                <p className="text-lg mb-4 xl:mx-24">Vi förstår vikten av varje sekund i er bransch och har därför optimerat vårt kassasystem för att ge er blixtsnabb prestanda. Ni behöver inte längre oroa er för långa köer och ineffektiva transaktioner utan kan istället hantera fler kunder och öka er försäljning med en snabb och smidig betalningsprocess. 
                                                    Genom att investera i en effektiv kassa visar ni att ni prioriterar era kunders tid och komfort vilket skapar en positiv upplevelse för dem och stärker er som en serviceinriktad verksamhet.
                                                    Ge ert företag en konkurrensfördel i Stockholm med vår kvicka kassa. Kontakta oss idag för att lära er mer om hur vårt kassasystem kan förbättra er kundupplevelse!
                </p>
              </div>
            </div>

            <div className="flex flex-col md:flex-row-reverse justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              
              <img src={bordskarta} alt="Göteborg Betalterminal" class="w-full md:w-1/2 p-12 object-cover rounded-lg shadow mb-4 md:mb-0 md:ml-4"/>

              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Moderna och klassiska betalningslösningar</h2>
                <p className="text-lg mb-4 xl:mx-24">Oavsett om ni vill använda moderna eller traditionella betalningslösningar har vi det perfekta alternativet för er. Vårt utbud av betalningslösningar kombinerar det bästa av modern teknik och beprövade metoder. Med våra moderna betalningslösningar kan era kunder betala smidigt med sina digitala enheter, inklusive kontaktlösa betalningar och swish. 
                                                    Våra moderna lösningar är säkra, snabba och enkla att använda. För de som föredrar en mer traditionell approach så erbjuder vi också klassiska betalningslösningar som kontanter och kortbetalningar. Vi tillhandahåller tillförlitliga och säkra kortterminaler som stödjer alla typer av betalkort, och vi kan även hjälpa er att integrera era kassasystem med sedelförvaring och myntsorterare. 
                                                    Vi förstår vikten av att erbjuda era kunder olika alternativ och göra det enkelt för dem att betala. Det leder till ökad försäljning och nöjdare kunder. Vårt mål är att erbjuda er en komplett och flexibel betalningslösning som passar era unika behov och hjälper er att driva er verksamhet framåt.
                </p>
              </div>
            </div>






            

            {/* Continue for the other sections */}

            <div className="mt-10 flex justify-center" data-aos="zoom-in-up">
            <button
              className="px-8 py-4 rounded-lg bg-[#8c52ff] text-white font-bold hover:bg-[#7F00FF] transition duration-200"
              onClick={() => window.location.href = "https://www.smartcash.se/kontakt"}
            >
              Kontakt oss
            </button>
            </div>

            </div>

}
         />
        </div>
    )
}
