import React, { useEffect, useState, useRef } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import kund_1 from "../Gfx/kund_1.jpeg"
import kund_2 from "../Gfx/kund_2.jpeg"
import kund_3 from "../Gfx/kund_3.jpeg"

import kund_10 from "../Gfx/kund_10.jpg"
import kund_11 from "../Gfx/kund_11.png"
import kund_13 from "../Gfx/kund_13.jpg"

import kund_logo_1 from "../Gfx/kund_logo_1.png"
import kund_logo_2 from "../Gfx/kund_logo_2.png"
import kund_logo_3 from "../Gfx/kund_logo_3.png"
import kund_logo_4 from "../Gfx/kund_logo_4.png"
import kund_logo_5 from "../Gfx/kund_logo_5.png"
import kund_logo_6 from "../Gfx/kund_logo_6.png"
import kund_logo_7 from "../Gfx/kund_logo_7.png"
import kund_logo_8 from "../Gfx/kund_logo_8.png"
import kund_logo_9 from "../Gfx/kund_logo_9.png"
import kund_logo_10 from "../Gfx/kund_logo_10.png"
import kund_logo_11 from "../Gfx/kund_logo_11.png"
import kund_logo_12 from "../Gfx/kund_logo_12.png"
import kund_logo_13 from "../Gfx/kund_logo_13.png"
import kund_logo_14 from "../Gfx/kund_logo_14.png"
import kund_logo_15 from "../Gfx/kund_logo_15.png"

const MySlider = (props) => {
const responsive = {
  desktop: {
    breakpoint: { max: 5000, min: 1024 },
    items: 4,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 5,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};
return(
<Carousel
  arrows={false}
  swipeable={false}
  draggable={false}
  showDots={false}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlay={props.deviceType !== "mobile" ? true : false}
  autoPlaySpeed={1200}
  keyBoardControl={true}
  customTransition="transform 2400ms linear"
  transitionDuration={1200}
  containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile"]}
  deviceType={props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
>
  <div><img src={kund_logo_1} className=" mx-24 px-10" /></div>
  <div><img src={kund_logo_2} className=" mx-24 px-10" /></div>
  <div><img src={kund_logo_3} className=" mx-24 px-10" /></div>
  
  <div><img src={kund_logo_4} className=" mx-24 px-10" /></div>
  <div><img src={kund_logo_5} className=" mx-24 px-10" /></div>
  <div><img src={kund_logo_6} className=" mx-24 px-10" /></div>
  <div><img src={kund_logo_7} className=" mx-24 px-10" /></div>
  <div><img src={kund_logo_8} className=" mx-24 px-10" /></div>
  
  <div><img src={kund_logo_10} className=" mx-24 px-10" /></div>
  <div><img src={kund_logo_11} className=" mx-24 px-10" /></div>
  <div><img src={kund_logo_12} className=" mx-24 px-10" /></div>
  <div><img src={kund_logo_9} className=" mx-24 px-10" /></div>
  <div><img src={kund_logo_13} className=" mx-24 px-10" /></div>
  <div><img src={kund_logo_14} className=" mx-24 px-10" /></div>
  <div><img src={kund_logo_15} className=" mx-24 px-10" /></div>
  
</Carousel>
);

}
export default MySlider;

/* 
      <img src={kund_1} className="w-[300px] h-[120px] px-24" />
      <img src={kund_2} className="w-[300px] h-[120px] px-24" />
      <img src={kund_3} className="w-[300px] h-[120px] px-24" />
      <img src={kund_1} className="w-[300px] h-[120px] px-24" />
*/
