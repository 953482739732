import React, {useState, useRef, useEffect} from 'react';
import axios from 'axios'
import Side_Master from '../Master/Side_Master'
import Std_Master from '../Master/Std_Master'
import {setCookie ,getCookie, removeCookie, spliceCookie, spliceCookieCustomProduct } from '../Components/CookieHandler'
import Inputfield_std from '../Components/Inputfield_std'
import Btn_std from '../Components/Btn_std'
import Kontakt_2 from '../Gfx/Kontakt_2.png'
import emailjs from '@emailjs/browser'
import swal from 'sweetalert';

import { BsTrash } from "react-icons/bs";
import { Link } from 'react-router-dom';

import logo_new from "../Gfx/logo_new.png";
import logo_new_1 from "../Gfx/logo_new_1.png";

import Group_106 from "../Gfx/Group_106.png";

import logo_new_text_white from "../Gfx/logo_new_text_white.png";
import logo_new_text from "../Gfx/logo_new_text.png";
import { Helmet } from 'react-helmet';

export default function CheckOut() {
    
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [lastName, setLastName] = useState("")
    const [phone, setPhone] = useState("")
    const [msg, setMsg] = useState("")
    const [org_number, setOrg_number] = useState("")
    const [company_name, setCompany_name] = useState("")
    const [address, setAddress] = useState("")
    const [post_nummer, setPost_nummer] = useState("")
    const [county, setCounty] = useState("")
    const [productName, setProductName] = useState([])

    const [paket, setPaket] = useState([])
    const [customProduct, setCustomProduct] = useState("")
    const [check, setCheck] = useState(false)
    const [checkProduct, setCheckProduct] = useState(false)

    const [reload, setReload] = useState("")

    const [totalSumM, setTotalSumM] = useState(0)
    const [totalSumG, setTotalSumG] = useState(0)

    function Total(){
        var products = JSON.parse(getCookie("products"));
        var paket = JSON.parse(getCookie("paket"));
        
        var enGongSumma = 0

        var manadSumma = 0
        
        products.map((obj, key) => {
            obj.price ? manadSumma += parseInt(obj.price.replace(/\D/g,'') ) * (obj.count) : enGongSumma += parseInt(obj.enGong.replace(/\D/g,'')) * (obj.count)
        })

        setTotalSumM(manadSumma + parseInt(paket["paket"] ? paket["paket"].price : 0))
        setTotalSumG(enGongSumma)

        update();
    }

    useEffect(()=>{
        Total()
    }, [])


    function ShowTotalSum(){
        return(
            <div className=" grid grid-cols-3 font-semibold p-2 mx-5 text-gray-700 sm:text-lg text-sm">
                <div className="col-span-2 flex justify-start my-2">
                    <div>Totalt pris att betala per månad: </div>
                </div>
                <div className="col-span-1 flex justify-end my-2">
                    <div><p>{totalSumM} :-/mån</p></div>
                </div>
                <div className="col-span-2 flex justify-start my-2">
                    <div>En Gångkostnad: </div>
                </div>
                <div className="col-span-1 flex justify-end my-2">
                    <div><p>{totalSumG} :-</p></div>
                </div>
            </div>
        )
    }

    function update(){
        /* props.updateList(); */
        setReload(()=> reload == "d" ? setReload("xd") : setReload("d"))
    }
    //const [check, setCheck] = useState(false)

    function updatePaket(){
        var cookie = JSON.parse(getCookie("paket"));
        setPaket(cookie)
    }

  
      useEffect(() => {
          //console.log("hej")
          //var array = []
          var cookie = JSON.parse(getCookie("products"));
          cookie.map((obj, key) => {
              //array.push(String(obj.name) + String(obj.count) + String(obj.all_price)  ) + "<br/>")
            //setCheck(obj.checked)
            setProductName(prevState => [...prevState, obj.name, "<br/>", "Total:", obj.count, "<br/>", "Betalsätt:", obj.payAll ? "Betala på en gång" + "<br/>" + "<br/>" + "<br/>" : "Hyra" + "<br/>" + "<br/>" + "<br/>"] );
          })

          var products = JSON.parse(getCookie("products"));
          if(Object.keys(products).length > 0 ){
            setCheckProduct(true)
        }

          var paketProducts = JSON.parse(getCookie("paket"));
          if(Object.keys(paketProducts).length > 0 ){
            setCheck(true)
        }
        setPaket(paketProducts);
          //setProductName(array)
        }, []);


    function getOffert() {
        var cookie = JSON.parse(getCookie("paket"));
            return(
                <div className="bg-white h-fit w-full">
                    <div className=" w-full rounded-lg py-2" img={cookie["paket"].img} name={cookie["paket"].name}>

                        <div className="grid lg:grid-cols-12 grid-cols-4 ">
                            <div className="lg:col-span-3 col-span-2 flex justify-start bg-gray-200 sm:w-[200px] sm:h-[120px] my-2 mx-5">
                                {/* <div className=" flex justify-center items-center sm:bg-contain bg-contain bg-no-repeat sm:w-[470px] sm:h-[300px] md:w-[220px] md:h-[220px] w-[145px] h-[165px] bg-center  " style={{backgroundImage: "url(" + cookie["paket"].img + ")"}}></div> */}
                                <img className="object-cover " src={cookie["paket"].img} />
                            </div>
                            <div className='lg:col-span-6 col-span-1 py-2 mx-2 '>
                                <h2 className="px-1 sm:text-[18px] text-[12px] text-gray-700 font-semibold">{cookie["paket"].name}</h2>
                                <div className='lg:block hidden'>
                                    <p className="text-gray-600 mt-3 px-1">Paket innehåll:</p>
                                    <div className="flex flex-row flex-wrap sm:text-[13px] text-[10px]">
                                    {cookie["paket"].content.map((obj, key) => {
                                        return(
                                            <div key={key}>
                                                <p className=" text-gray-500 mr-2 flex my-1 bg-slate-100 rounded-lg px-2">{obj.name}</p>
                                            </div>
                                        )
                                    })}
                                    </div>
                                </div>
                                
                            </div>
                            <div className="lg:col-span-3 col-span-1 flex justify-end sm:mr-4">
                                <div className="grid grid-rows-2">
                                    <div className="row-span-1 flex justify-end p-1">
                                        <div onClick={()=>{setCookie("paket", "[]");setCheck(false);Total();}} className="py-2 cursor-pointer hover:-translate-y-1 hover:scale-100" ><div className="py-1"><BsTrash /> </div></div>
                                    </div>
                                    <div className="row-span-1 flex items-end p-1">
                                        <div className="sm:text-[18px] text-[12px] text-gray-700 font-bold px-1 rounded-lg">{cookie["paket"].price} :-/mån</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
      
          const form = useRef();
        
          const sendEmail = (e) => {
            e.preventDefault();
  
            emailjs.sendForm('service_2e8damt', 'template_wyvqvyw', form.current, 'hF2Szh2vLy0l-NBUc')
              .then((result) => {
                  clear();
              }, (error) => {
                  console.log(error.text);
              });
          };
  
          function sweetalertNotification(){
              swal({
                  title: "Vi har tagit emot din beställning",
                  text: "vi ska kontakta dig så fort som möjligt",
                  icon: "success",
              })
          }
  
          const clear = () => {
              /* window.location.reload(); */
              window.location.href = 'https://smartcash.se/Rate_us';
              /* window.location.href = 'http://localhost:3000/Rate_us'; */
              removeCookie('products')
              removeCookie('paket')
          }

    function getCartProds() {

        var cookie = JSON.parse(getCookie("products"));
        var toRet = cookie.map((obj, key) => {

            //console.log(obj.id)

            return(
            <div key={key}>
                <div className=" bg-white h-fit w-full">
                    <div className="relative grid lg:grid-cols-12 grid-cols-3 border-b border-t py-2" name={obj.name} price={obj.price} img={obj.img} count={obj.count} id={key}>
                        <div className="lg:col-span-3 col-span-1 flex justify-start bg-gray-200 sm:w-[200px] sm:h-[160px] my-2 mx-5">
                            <img className="object-cover " src={obj.img} />
                        </div>
                        <div className="lg:col-span-6 col-span-1 py-1">
                            <h2 className="px-1 sm:text-md text-sm text-gray-700 font-semibold">{obj.name}</h2>
                            <div className="flex justify-start sm:text-md text-sm text-gray-500 rounded-lg px-1 font-medium w-auto text-center">{obj.enGong ? "Betala på en gång" : "Hyra"} </div>
                            <div className="flex justify-start text-gray-500 rounded-lg px-1 font-medium sm:text-md text-sm w-auto text-center">Antal: <p className="sm:text-md text-sm mx-1 border-b-2">{obj.count}</p> </div>
                            
                        </div>
                        <div className="lg:col-span-3 col-span-1 flex justify-end ">
                                <div className="grid grid-rows-2 sm:mr-4">

                                    <div className="row-span-1 flex justify-end p-1">
                                        <div onClick={()=>{spliceCookie(key);update();Total();}} className=" py-2 cursor-pointer hover:-translate-y-1 hover:scale-100" ><p className="py-1"><BsTrash /> </p></div>
                                    </div>

                                    <div className="row-span-1 flex items-end p-1">
                                        <div className="sm:text-[18px] text-[12px] font-bold px-1 rounded-lg">{(parseInt(obj.price) ? parseInt(obj.price) * obj.count : parseInt(obj.enGong) * obj.count).toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{ obj.price ? ":-/mån" : ":-"} </div>
                                    </div>
                                
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            
            )
        });
      
        return toRet;
    }

    var cookie = JSON.parse(getCookie("products"));
    var paketProducts = JSON.parse(getCookie("paket"));
    return (
        <Std_Master logo_1={logo_new_text} logo={logo_new_text} mobileLogo={logo_new_text} mobileLogo_1={logo_new_text_white} NavCss={"text-black"} hideTheBgOfTheNav={"bg-[#F5F5F5] h-24"} hightOfTheNavBar={"bg-white h-24"} Title="." mobileNavBarCss={"text-black"} inner={


    <div className="flex justify-center sm:container sm:mx-auto 2xl:px-[250px] " >

              <Helmet replace={true}>
                  <title>Smart Cash - Check-out</title>
                </Helmet>

        <form ref={form} onSubmit={sendEmail}>
        <div className="grid md:grid-cols-7 grid-cols-1 h-auto lg:w-fit">
        <div className="md:col-span-7 col-span-7 h-[100px] font-bold flex justify-center py-8 text-3xl text-gray-700 ">Utcheckning</div>

            <div class="md:col-span-7 ">
            
                {/* <h2 className={ check || checkProduct ? "py-4 mb-6 border-b text-sm font-medium flex justify-center text-center bg-clip-text text-transparent bg-gradient-to-b from-purple-500 to-indigo-500  text-[25px]" : "hidden"}>Valda paket & proukter</h2> */} {/* text-[#F01E82] */}
                    
                {/* <Link to={document.URL.indexOf("http://localhost:3000/check-out") - 1 } className='text-black mx-6 flex justify-self-center my-1 relative'>       Go back    </Link> */}
                {/* <div className="relative mx-8 flex justify-center "> */}
                <div className="font-semibold py-2 text-lg">1. Din shoppingbag</div>
                <div className=" bg-white">

                    <div className="">
                        <div className="col-span-1">
                            {check ? getOffert() : ""}
                        </div> 
                        <div className="col-span-1">
                            {checkProduct ? getCartProds() : ""}
                        </div>
                        <div className="col-span-1">
                            {check || checkProduct ? ShowTotalSum() : ""}
                        </div>
                    </div>
                </div>
            
            </div>


            {/* <div className="font-semibold py-2 mt-12 text-lg">2. Dina uppgifter</div> */}
            <div className="grid grid-cols-4 w-full mt-12 bg-cover bg-center bg-no-repeat relative col-span-7 justify-center py-9 shadow-lg ease-in-out hover:shadow-2xl rounded-lg mb-12" style={{backgroundImage: "url(" + Group_106 + ")"}}>
                {/* <div className="grid grid-cols-4 w-full bg-cover md:bg-contain bg-center bg-no-repeat" style={{backgroundImage: "url(" + Group_106 + ")"}}> */}
                <div className="relative col-span-4 text-sm font-medium flex justify-center text-[#8c52ff] text-[20px] py-4">Vi kontaktar dig</div>

                                <div className="col-span-2 px-2 w-full">
                                      
                                  <input type="text" value={productName} name="info" placeholder="hehe" className="hidden w-full text-xs shadow-md focus:ring-gray-300 focus:border-gray-300 border-gray-100 rounded-md py-2 px-2" />

                                  <input type="text"  value={check ? paketProducts["paket"].name : ""} name="paket" placeholder="hehe" className="hidden w-full text-xs shadow-md focus:ring-gray-300 focus:border-gray-300 border-gray-100 rounded-md py-2 px-2" />
                                      <Inputfield_std name="user_name"  value={(e)=>{setName(e)}} placeholder="Namn" className="w-full text-xs rounded-md px-2" />
                                  </div>
                                  <div className="col-span-2 px-2 w-full">    
                                      <Inputfield_std name="company_name"  value={(e)=>{setCompany_name(e)}} placeholder="Företagsnamn" className="w-full text-xs rounded-md px-2 " />
                                  </div>
                                  <div className="col-span-2 px-2 w-full">
                                      <Inputfield_std name="user_email"  value={(e)=>{setEmail(e)}} placeholder="E-post" className="w-full text-xs rounded-md px-2" />
                                  </div>
                                  <div className="col-span-2 px-2 w-full ">
                                      <Inputfield_std name="user_phone"  value={(e)=>{setPhone(e)}} placeholder="Telefonnummer" className="w-full text-xs rounded-md px-2" />
                                  </div>
                                  {/* <div className="col-span-2 px-2 w-full">
                                      <Inputfield_std name="user_adress"  value={(e)=>{setAddress(e)}} placeholder="Gatuadress" className="w-full text-xs rounded-md px-2 "  />
                                  </div> */}
                                  <div className="col-span-2 px-2 w-full">    
                                      <Inputfield_std name="org_number"  value={(e)=>{setOrg_number(e)}} placeholder="Organisationsnummer" className="w-full text-xs rounded-md px-2 "/>
                                  </div>
                                  {/* <div className="col-span-1 px-2 w-full py-2">
                                      <Inputfield_std name="postnummer"  value={(e)=>{setPost_nummer(e)}} placeholder="Postnummer" className="w-full text-xs rounded-md px-2 " />
                                  </div> */}
                                  {/* <div className="col-span-1 px-2 w-full py-2">
                                      <Inputfield_std name="ort"  value={(e)=>{setCounty(e)}} placeholder="Ort" className="w-full text-xs rounded-md px-2 " />
                                  </div> */}

                                  <div className="col-span-4 px-2 mx-2 py-2 border-red-600">
                                    <textarea name="user_message" onChange={(e)=>{setMsg(e.target.value)}} rows="4" placeholder="Meddelande" className="sm:w-[400px] w-[300px] resize-none p-2 text-xs shadow-md focus:ring-gray-300 focus:border-gray-300 border border-gray-100 rounded-md px-2" />
                                      <div className="col-span-4 py-8 flex justify-center">
                                        <Btn_std type={"submit"} true={name.length  && phone.length && email.length && company_name.length && org_number.length != 0 ? false :true}
                                        value={"Send"}
                                        ClassName={name.length && phone.length && email.length && company_name.length && org_number.length != 0 ? " flex bg-[#8c52ff] text-white py-[6px] px-4 rounded-md w-[200px] font-medium pt-2 " : " opacity-25 cursor-not-allowed "}
                                        trigger={ name.length  && phone.length && email.length && company_name.length && org_number.length != 0 ? () =>  {sweetalertNotification()} : ()=>{}} inner={"Skicka"} />
                                      </div>
                                  </div>

                {/* </div> */}
            </div>
            {/* <div className="relative col-span-3 mx-8 flex py-4 flex-col shadow-lg my-2 ease-in-out hover:shadow-2xl rounded-lg w-[450px] h-[600px] overflow-auto">
            
                <h2 className="py-4 mb-6 border-b text-sm font-medium flex justify-center text-[#8c52ff] text-[20px]">Valda Enskilda produkter</h2>
                <div className="mx-8">
                    {getCartProds()}
                </div>
                
            </div> */}
        </div>
            </form>
    </div>
    }/>
    )
}