import logo from './logo.svg';
import './App.css';
import {useEffect} from 'react'
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import Std_Master from './Master/Std_Master';
import Home from './Pages/Home';
import Erbjudande from './Pages/Erbjudande';
import Inlosenavtal from './Pages/Inlosenavtal';
import OmOss from './Pages/OmOss';
import Kontakt from './Pages/Kontakt';
import Offert_checkout from './Pages/Offert_checkout';
import Restaurant_products from './Pages/Restaurant_products';
import Beauty_products from './Pages/Beauty_products';
import Grocery_products from './Pages/Grocery_products';
import Hardware_products from './Pages/Hardware_products';
import RestaurantAndCafe from './Pages/Offers/RestaurantAndCafe';
import BeautyAndHealth from './Pages/Offers/BeautyAndHealth';
import GroceryStores from './Pages/Offers/GroceryStores';

import Kassor from './Pages/Products/Kassor';
import Kortterminaler from './Pages/Products/Kortterminaler';
import Kvittohantering from './Pages/Products/Kvittohantering';
import Tillbehor from './Pages/Products/Tillbehor';
import AllProductsPage from './Pages/Products/AllProductsPage';
import AllOffersPage from './Pages/Offers/AllOffersPage';

import BetalTerminal from './Pages/Goteborg/BetalTerminal';
import Kassa from './Pages/Goteborg/Kassa';

import Betalterminal_Lin from './Pages/Linkoping/Betalterminal_Lin';
import Kassa_Lin from './Pages/Linkoping/Kassa_Lin';

import Betalterminal_Sto from './Pages/Stockholm/Betalterminal_Sto';
import Kassa_Sto from './Pages/Stockholm/Kassa_Sto';

import TrustPilot from './Pages/TrustPilot';

import CheckOut from './Pages/CheckOut';

function App() {
useEffect(() => {
  const script = document.createElement('script');
  script.src =
  'https://app.weply.chat/widget/aff82c3d70b807de82a9ce089dbbd0af';
  script.async = true;
  document.body.appendChild(script)

},[])

  return (
    <BrowserRouter>
    <div className=' font-lato bg-[#F5F5F5]' >
      <Routes>
        <Route exact path="/" element={<Home />} />
        {/* <Route exact path="/erbjudande" element={<Erbjudande />} /> */}
        <Route exact path="/restaurant-and-cafe" element={<RestaurantAndCafe />} />
        <Route exact path="/beauty-and-health" element={<BeautyAndHealth />} />
        <Route exact path="/grocery-stores" element={<GroceryStores />} />
        <Route exact path="/inlosenavtal" element={<Inlosenavtal />} />
        <Route exact path="/om_oss" element={<OmOss />} />
        <Route exact path="/kontakt" element={<Kontakt />} />
        <Route exact path="/offert" element={<Offert_checkout />} />
        <Route exact path="/restaurant-products" element={<Restaurant_products />} />
        <Route exact path="/beauty-products" element={<Beauty_products />} />
        <Route exact path="/grocery-products" element={<Grocery_products />} />
        <Route exact path="/hardware-products" element={<Hardware_products />} />


        <Route exact path="/Kassor" element={<Kassor />} />
        <Route exact path="/Kortterminaler" element={<Kortterminaler />} />
        <Route exact path="/Kvittohantering" element={<Kvittohantering />} />
        <Route exact path="/Tillbehor" element={<Tillbehor />} />

        <Route exact path="/AllProductsPage" element={<AllProductsPage />} />
        <Route exact path="/AllOffersPage" element={<AllOffersPage />} />

        <Route exact path="/BetalTerminal_goteborg" element={<BetalTerminal />} />
        <Route exact path="/kassa_goteborg" element={<Kassa />} />

        <Route exact path="/BetalTerminal_Linkoping" element={<Betalterminal_Lin />} />
        <Route exact path="/kassa_Linkoping" element={<Kassa_Lin />} />
        
        <Route exact path="/BetalTerminal_Stockholm" element={<Betalterminal_Sto />} />
        <Route exact path="/kassa_Stockholm" element={<Kassa_Sto />} />


        <Route exact path="/Rate_us" element={<TrustPilot />} />
        <Route exact path="/check-out" element={<CheckOut />} />


        {/* <Route exact path="/restaurant-and-cafe" element={<RestaurantAndCafe />} /> */}
      </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
