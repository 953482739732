import React from 'react';
import Std_Master from '../Master/Std_Master';

import InlosenAvtal_Card from '../Components/InlosenAvtal_Card';

import logo_new_text_white from "../Gfx/logo_new_text_white.png";
import logo_new_text from "../Gfx/logo_new_text.png";

export default function Home() {
  return(
    <Std_Master selectedNavItem={2} mobileNavBarCssWhenNotScroll={"text-black"} logo_1={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/logo_new_text_white.png"} logo={logo_new_text} mobileLogo={logo_new_text} mobileLogo_1={logo_new_text_white} NavCss={"text-white"} Title="." mobileNavBarCss={"text-black"} NavBg={"bg-[#8c52ff]"} inner={
      <div className='bg-white'>
        <div className='flex justify-center flex-row '>
          <InlosenAvtal_Card />
        </div>

        <div className='w-full flex justify-center mt-20'>
          <div className='grid-cols-2 w-full 2md:w-3/4 md:h-[22vw] 2md:h-[200px] lg:h-[450px] flex-wrap flex lg:grid flex-row mt-8 md:mt-16 my-4'>
            <div className='col-start-1 col-span-1 flex flex-col px-4 md:px-24 justify-center'>
              <h3 className='text-left text-3xl font-extrabold tracking-tight text-gray-900 xl:text-[20px]'>Ett inlösenavtal som täcker alla behov</h3>
              <p className='text-left text-md text-gray-500'>
              Med vårt inlösenavtal kan du ta emot betalningar i butik och online från världens största kortutgivare oavsett vilken bank du har då vi är obundna och jobbar med samtliga banker. 
              Med vårt inlösenavtal kan du hantera betalningar i över 100 olika valutor, samtidigt som du alltid får betalt i din egen valuta. 
              Vi på Smart Cash erbjuder dessutom de snabbaste utbetalningarna på marknaden, i regel sätts pengarna in redan nästa dag! 
              Vårt inlösenavtal erbjuder dessutom säkerhet i världsklass där vi tillsammans med våra samarbetspartners erbjuder ett riskteam som hela tiden arbetar med att övervaka dina transaktioner så vi tidigt kan upptäcka bedrägerier.

              </p>
            </div>
            <div className='col-start-2 col-span-1 flex items-center justify-center flex-wrap w-full mt-5'>
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d6/Visa_2021.svg/2560px-Visa_2021.svg.png" className='w-[350px] md:w-[500px] mt-12 sm:mt-0' />
            </div>
          </div>
        </div>

        <div className='w-full flex justify-center py-24'>
          <div className='grid-cols-2 w-full 2md:w-3/4 md:h-[22vw] 2md:h-[200px] lg:h-[450px] flex-wrap flex lg:grid flex-row mt-8 md:mt-16'>
            <div className='col-start-1 col-span-1 flex flex-col px-4 md:px-24 justify-center'>
              <h3 className='text-left text-3xl font-extrabold tracking-tight text-gray-900 xl:text-[20px]'>Hur fungerar det?</h3>
              <p className='text-left text-md text-gray-500'>
              Vi får uppgifter om dig och ditt företag enligt formuläret ovan. 
              Vår avdelning för inlösenavtal lägger upp en ansökan efter att vi mottagit alla era uppgifter.
              Ansökan granskas och godkänns av våra samarbetspartners samt er bank. 
              Ni som kund blir tilldelad ett redovisningsnummer som vi sedan kopplar samman med er kortterminal. 
              Nu är ni igång!
              </p>
            </div>
            <div className='col-start-2 col-span-1 flex items-center justify-center flex-wrap w-full my-2 '>
              <img src="https://upload.wikimedia.org/wikipedia/commons/2/2a/Mastercard-logo.svg" className='w-[300px] md:w-[300px] mt-12 sm:mt-0' />
            </div>
          </div>
        </div>

      </div>
    } />
  );
}
