import React, {useEffect, useState} from 'react'

import Product_card from '../../Components/Product_card'
import Std_Master from '../../Master/Std_Master'
import CardModal from '../../Components/CardModal';

import logo_new_text_white from "../../Gfx/logo_new_text_white.png";
import logo_new_text from "../../Gfx/logo_new_text.png";
import { Helmet } from 'react-helmet';

export default function Kassor() {
  const [isOpen, setIsOpen] = useState(false)
  const [reload, setReload] = useState('')


  const _setIsOpen = (_enGongPrice ,_price, _title, _img, _desc, _payAll, _show_checkbox) =>
  {
      setEnGongPrice(_enGongPrice)
      setPrice(_price)
      setTitle(_title)
      setImgs(_img)
      setDesc(_desc)
      setPayAll(_payAll)
      setShow_checkbox(_show_checkbox)
      setIsOpen(true);
  }

  const [enGongPrice, setEnGongPrice] = useState("");
  const [price, setPrice] = useState("");
  const [title, setTitle] = useState("");
  const [imgs, setImgs] = useState([""]);
  const [desc, setDesc] = useState("");

  const [payAll, setPayAll] = useState("");

  const [show_checkbox, setShow_checkbox] = useState(false);
  
  const isPopUp = () => {
      if(isOpen) {
        /* console.log(document.body.style.overflow)
        {document.body.style.overflow = 'hidden'}
        console.log(document.body.style.overflow) */
        return(
          <CardModal key={0} enGong={enGongPrice} hehe={imgs} hehe1={imgs} title={title} price={price} img={imgs} desc={desc} payAll={payAll} show_checkbox={show_checkbox} close={()=>{setIsOpen(false); setImgs("")}} />
          )
        }/* else{
          {document.body.style.overflow = 'unset'}
        } */
      }
      
      useEffect(()=> {
        
        if(isOpen){
          {document.body.style.overflow = 'hidden'}
          console.log(document.body.scroll)
        }else{
          {document.body.style.overflow = 'unset'}
          
        }
    },[isOpen])

    
    
  return (
    <div>
      {isPopUp()}

      <Std_Master NavBg={isOpen ? "hidden" : ""} logo_1={logo_new_text_white} logo={logo_new_text} mobileLogo={logo_new_text_white} mobileLogo_1={logo_new_text_white} classNameCss="w-full vh-full" vid={"https://smartcash-website.s3.eu-north-1.amazonaws.com/beauty_and_health_video.mp4"} inner={
            <div className="py-20 bg-white">

                <Helmet replace={true}>
                  <title>Smart Cash - Kassor</title>
                </Helmet>

                <div className="z-10 max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <h1 className="text-4xl sm:ml-10 mx-5 font-extrabold text-warm-gray-900 sm:text-4xl lg:text-4xl">Kassor</h1>
                    <p className="mt-6 sm:ml-11 ml-6 text-xl text-warm-gray-500 max-w-3xl"> Vi ser fram emot att höra från dig </p>
                </div>
                <div className="md:flex sm:flex flex-wrap -z-50 max-w-7xl mx-auto sm:px-6 lg:px-8 justify-center items-center">
                    
                    <Product_card trigger={()=>_setIsOpen("","890:-/mån",  "FEC 9715W", ["https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/kassa_2.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/kassa_2.png" ,"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/kassa_2.png" ], "Vår senaste hårdvara som kan prestera i alla typer av restaurang miljöer samt går det att anpassa efter din verksamhet. Till exemepel kunddisplay & magnetkortläsare")} 
                    update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/kassa_2.png"} price="890Kr" title="FEC 9715W" ribbon="890 :- /mån" description="Vår senaste hårdvara som kan prestera i alla typer av restaurang miljöer samt går det att anpassa efter din verksamhet. Till exemepel kunddisplay & magnetkortläsare"/>

                    <Product_card trigger={()=>_setIsOpen("16749 :-", "279 Kr/Mån", "Sam4s ER-260", ["https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/smart_260_1.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/smart_260_2.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/smart_260_3.png"], <div>Smidig och enkel knappkassa som du kan ha upp till 15 varugrupper med betalsätt kontant, swish & kort.</div>, <div className="flex">Betala på en gång <p className=" flex border-black border-b-2 mx-2">(16 749 Kr)</p></div>, true)}
                    update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/smart_260_1.png"} price="279Kr" title="Sam4s ER-260" ribbon="279 :- /mån" description="Smidig och enkel knappkassa som du kan ha upp till 15 varugrupper med betalsätt kontant, swish & kort."/>

                    <Product_card trigger={()=>_setIsOpen("14949 :-","249 Kr/Mån", "Sam4s ER-230", ["https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/smart_230_1.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/smart_230_2.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/smart_230_3.png"], <div>Smidig kassaregister som du kan använda på torg, gatukök & butik och körs på batteri och även strömkabel. Kan ha upp till 15 varugrupper med betalsätt Kontant, swish & kort.</div>, <div className="flex">Betala på en gång <p className=" flex border-black border-b-2 mx-2">(14 949 kr)</p></div>, true)}
                     update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/smart_230_1.png"} price="249Kr" title="Sam4s ER-230" ribbon="249 :- /mån" description="Smidig kassaregister som du kan använda på torg, gatukök & butik och körs på batteri och även strömkabel. Kan ha upp till 15 varugrupper med betalsätt Kontant, swish & kort."/>

                    <Product_card trigger={()=>_setIsOpen("","890 Kr/Mån", "TritonPOS 4", ["https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/treton_pos_1_1.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/treton_pos_2_1.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/treton_pos_3.png"], <div>Liten och smidig kassasystem som du kan ha i garderob samt vid mindre tillfällen. Hårdvaran är utrustad med Kunddisplay samt skrivare.</div>,<div className="flex">Betala på en gång <p className=" flex border-black border-b-2 mx-2"></p></div>)} 
                    update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx/treton_pos_1_1.png"} price="890Kr" title="TritonPOS 4" ribbon="890 :- /mån" description="Liten och smidig kassasystem som du kan ha i garderob samt vid mindre tillfällen. Hårdvaran är utrustad med Kunddisplay samt skrivare."/>                    
                </div>
            </div>
        } />
    </div>
  )
}
