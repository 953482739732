import React, { useState, Component, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { slideInRight } from "react-animations"
import { StyleSheet, css } from 'aphrodite';
import {getCookie, setCookie} from '../Components/CookieHandler';
import Cart_product from './Cart_product';

import { BsTrash } from "react-icons/bs";


export function CartProds(props) {

    const [reload, setReload] = useState("")

    function update(){
        props.setHappendChange(()=>props.happendChange == "d" ? props.setHappendChange("xd") : props.setHappendChange("d"))
        setReload(()=> reload == "d" ? setReload("xd") : setReload("d"))
    }

    let prods = [];
        var cookie = JSON.parse(getCookie("products"));
        for(let i = 0;i<cookie.length;i++){
            let obj = cookie[i];
            const getGUID = () => "id" + Math.random().toString(16).slice(2);
            prods.push(
                <div key={getGUID()}>
                    <Cart_product /* setHappendChange={(v)=>props.setHappendChange} happendChange={props.happendChange}  */updateList={()=>{update()}} name={obj.name} price={obj.price ? obj.price : obj.enGong} img={obj.img} count={obj.count} id={i}/>
                </div>);
        }

        useEffect(()=>{
            console.log(props.happendChange + "card products")
        },[props.happendChange])
    
        return <div>{prods}</div>;
}

  

  


export default function SideBar(props) {

    const [check, setCheck] = useState(false)
    const [reload, setReload] = useState("")

    const [totalSumM, setTotalSumM] = useState(0)
    const [totalSumG, setTotalSumG] = useState(0)

    const [happendChange, setHappendChange] = useState(0)


    function Total(){
        var products = JSON.parse(getCookie("products"));
        var paket = JSON.parse(getCookie("paket"));
        
        var enGongSumma = 0

        var manadSumma = 0
        
        products.map((obj, key) => {
            obj.price ? manadSumma += parseInt(obj.price.replace(/\D/g,'') ) * (obj.count) : enGongSumma += parseInt(obj.enGong.replace(/\D/g,'')) * (obj.count)
        })

        /* paket.map((obj, key) => { */
            /* obj.price ? manadSumma += parseInt(obj.price.replace(/\D/g,'')) * (obj.count) : enGongSumma += parseInt(obj.enGong.replace(/\D/g,'')) * (obj.count) */
            /* console.log(paket["paket"].price) */
        /* }) */

        setTotalSumM(manadSumma + parseInt(paket["paket"] ? paket["paket"].price : 0))
        setTotalSumG(enGongSumma)

        update();
    }

    useEffect(()=>{
        Total()
    }, [])


    useEffect(()=>{
        Total()
    }, [happendChange])


    function ShowTotalSum(){
        return(
            <div className=" grid grid-cols-3 font-semibold p-2 mx-5 text-gray-700 sm:text-sm text-sm">
                <div className="col-span-2 flex justify-start my-2">
                    <div>Totalt pris att betala per månad: </div>
                </div>
                <div className="col-span-1 flex justify-end my-2">
                    <div><p>{totalSumM} :-/mån</p></div>
                </div>
                <div className="col-span-2 flex justify-start my-2">
                    <div>En Gångkostnad: </div>
                </div>
                <div className="col-span-1 flex justify-end my-2">
                    <div><p>{totalSumG} :-</p></div>
                </div>

            </div>
        )
    }

    function getOffert() {
        var cookie = JSON.parse(getCookie("paket"));
            return(
                <div className="">
                    {cookie["paket"] ? <div className=" w-full p-4 mb-[40px] h-fit shadow-lg hover:shadow-2xl ease-in-out duration-300 rounded-lg  " img={cookie["paket"] ? cookie["paket"].img : ""} name={cookie["paket"] ? cookie["paket"].name : ""}>
                        <div className="grid grid-rows-7">
                            <div className="row-span-1">
                                <img className="object-cover object-center object-no-repeat " src={cookie["paket"] ? cookie["paket"].img : ""} />
                            </div>
                            <div className="row-span-4">
                                <h2 className="px-1 text-[20px] text-gray-700">{cookie["paket"] ? cookie["paket"].name : ""}</h2>
                                <p className="text-gray-600 mt-3 px-1">Paket innehåll:</p>
                                <div className="flex flex-row flex-wrap">
                                {cookie["paket"] ? cookie["paket"].content.map((obj, key) => {
                                    return(
                                        <div key={key}>
                                            <p className="my-2 text-gray-500 mr-2 text-[12px] flex-wrap bg-slate-200 rounded-lg px-2">{obj ? obj.name : ""}</p>
                                        </div>
                                    )
                                }) : ""}
                                </div>
                            </div>
                            <div className="row-span-2 flex justify-end items-center ">
                                <div className="flex">
                                    <p className="text-[25px] text-gray-600 bg-slate-200 px-1 rounded-lg">{cookie["paket"] ? cookie["paket"].price : ""} :-</p>
                                    <div onClick={()=>{setCookie("paket", "[]"); update(); setHappendChange(()=>happendChange == "d" ? setHappendChange("xd") : setHappendChange("d"))}} className="px-4 py-2 cursor-pointer hover:-translate-y-1 hover:scale-110" ><div className="py-1"><BsTrash /> </div></div>
                                </div>
                            </div>
                        </div>
                    </div> : <div></div>}
                </div>
            )
        }

    function update(){
        
        setReload(()=> reload == "d" ? setReload("xd") : setReload("d"))
    }

    useEffect(()=>{
        var cookie = JSON.parse(getCookie("paket"));

        if(Object.keys(cookie).length > 0 ){
            setCheck(true)
        }
    }, [])

    const styles = StyleSheet.create({
        slideInRight: {
            animationName: slideInRight,
            animationDuration: '1s'
        }
    })

    return (
        <div className="fixed w-full h-full bg-[#00000080] z-50 " >
            <div className="grid 2xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-2 grid-cols-1 w-full h-full ">
                
                <div className="h-full z-30 hidden sm:flex 2xl:col-span-4 lg:col-span-3 md:col-span-1 col-span-1" onClick={() => {props.hide()}} />
                
                <div className={"  flex flex-col w-full h-screen bg-slate-100 col-span-1 " + css(styles.slideInRight)}> {/* lg:w-[385px] md:w-[315px] w-[385px] */}
                    <div className=" overflow-auto">
                        <p className="text-[16px] font-bold border-b border-black w-full py-2 flex justify-center ">Varukorg</p>
                        <div className={"flex flex-col  w-full  items-start py-4 md:px-4 md:py-4 "} style={{backgroundImage: "url(" + props.img + ")"}}>
                            <div onClick={() => {props.hide()}} className="md:hidden flex ml-64 text-[#8c52ff] text-[20px]">X</div>
                        
                            <div className="w-full ">
                                {check ? getOffert() : ""}
                                <CartProds happendChange={happendChange} setHappendChange={(v)=>setHappendChange(v)}></CartProds>

                            </div>


                        </div>
                    </div>
                    
                        <div className=" flex flex-col p-3 justify-end bg-slate-200 w-full">
                            <div className=" flex justify-center ">
                                {ShowTotalSum()}
                            </div>
                            <div className=" flex justify-center ">
                                <Link to="/check-out"> <button onClick={()=>{window.scroll(0, 0); props.hide()}} className=" bg-black text-white py-[12px] px-4 rounded-md w-[300px]" >  Slutför <div className="mt-1 ml-4">  </div> </button> </Link>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    )
}

/* {document.URL.indexOf("https://www.smartcash.se/offert") == 0 || document.URL.indexOf("http://localhost:3000/offert") == 0 } */
