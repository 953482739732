import React, {useState, useRef, useEffect} from 'react';
import emailjs from '@emailjs/browser'
import swal from 'sweetalert'

export default function InlosenAvtal_Card(props) {

  const [name, setName] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [orgNumber, setOrgNumber] = useState("")
  const [address, setAddress] = useState("")
  const [accountNumber, setAccountNumber] = useState("")
  const [industryType, setIndustryType] = useState("")
  const [personNumber, setPersonNumber] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")

  useEffect(() => {
    console.log(name.length)
    console.log(orgNumber.length)
  },[])

  const form = useRef();
      
        const sendEmail = (e) => {
          e.preventDefault();
          if(name.length != 0 && companyName.length != 0 && orgNumber.length != 0 && address.length != 0 && accountNumber.length != 0 && industryType.length != 0 && personNumber.length != 0 && phone.length != 0 && email.length != 0)
          {
          emailjs.sendForm('service_2e8damt', 'template_gtgc6uc', form.current, 'hF2Szh2vLy0l-NBUc')
            .then((result) => {
                form.current.reset();
                clear();
            }, (error) => {
            });
          }
          else{
            form.current.reset();
          }
        };

        function sweetalertNotification(){
            swal({
                title: "Vi har tagit emot din beställning",
                text: "vi ska kontakta dig så fort som möjligt",
                icon: "success",
            })
        };

        function sweetalertNotification2(value){
          swal(`Please upload a file smaller than 500KB`);
        }

        const clear = () => {
            window.location.reload();
            //removeCookie('paket')
        }


        const [reload, setReload] = useState('');

        function update(){
          setReload(()=> reload == "d" ? setReload("xd") : setReload("d"))
      }


        const [attach, setAttach] = useState('');
        const uploadImage=(e)=>{
          
          let file = e.target.files[0];
            /* if (file && !file.name) {
               window.alert("Please select a file");
               return false;
            } */
            if (file.size > 0.5e6) {
              //window.alert("Please upload a file smaller than 500KB");
              sweetalertNotification2(file.size)
              setAttach("")
              update()
              return false;
            }
        }

        

  return (
    <div className=" w-full h-fit bg-contain mx-2" /* style={{backgroundImage: "url(" + inlosenavtal_bg_1 + ")"}} */>

<form encType="multipart/form-data" method="post" ref={form} onSubmit={sendEmail}>
      <div className={"h-[780px] lg:relative  rounded-2xl transition duration-500 shadow-2xl bg-white max-w-xl mx-auto flex justify-center " + props.className}> {/* hover:bg-white */}
        <div className="grid grid-cols-4 w-full gap-y-8 gap-x-6">

          <div className="col-span-4 bg-[#8c52ff] py-4 rounded-t-2xl">
            <div className=" h-auto text-center">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-200 sm:text-4xl">
                Ansök Direkt!
              </h2>
              </div>
            <div className=" h-auto text-center">
              <p className="mt-4 text-md leading-6 text-gray-200 px-2">
                Vill du också ha ett av marknadens absolut vassaste inlösenavtal?
                Fyll i uppgifterna nedan så hjälper vi er till en säker, prisvärd lösning!
              </p>
            </div>
          </div>
          
          <div className="relative col-span-2 py-2 px-6">
            <div className="py-4">
              <label className="block text-sm font-medium text-gray-700" >KONTAKTPERSON</label>
              <input name="kontakt_person" onChange={(e)=>{setName(e.target.value)}} className="mt-2 py-1 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-200 rounded-md"/>
            </div>
            <div className="py-4">
              <label className="block text-sm font-medium text-gray-700" >ORGANISATIONSNUMMER</label>
              <input name="organisations_nummber" onChange={(e)=>{setOrgNumber(e.target.value)}} className= "mt-2 py-1 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-200 rounded-md"/>
            </div>
            <div className="py-4">
              <label className="block text-sm font-medium text-gray-700" >TELEFON</label>
              <input name="telefon" onChange={(e)=>{setPhone(e.target.value)}} className= "mt-2 py-1 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-200 rounded-md"/>
            </div>
            <div className="py-4">
              <label className="block text-sm font-medium text-gray-700" >ADRESS</label>
              <input name="adress" onChange={(e)=>{setAddress(e.target.value)}} className= "mt-2 py-1 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-200 rounded-md"/>
            </div>
            <div className="py-4">
              <label className="block text-sm font-medium text-gray-700" >KONTONUMMER</label>
              <input name="konto_nummer" onChange={(e)=>{setAccountNumber(e.target.value)}} className= "mt-2 py-1 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-200 rounded-md"/>
            </div>
          </div>
          <div className="relative col-span-2 py-2 px-6">
            <div className="py-4">
              <label className="block text-sm font-medium text-gray-700" >FÖRETAGSNAMN</label>
              <input name="company_name" onChange={(e)=>{setCompanyName(e.target.value)}} className= "mt-2 py-1 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-200 rounded-md"/>
            </div>
            <div className="py-4">
              <label className="block text-sm font-medium text-gray-700" >TYP AV BRANSCH</label>
              <input name="type_of_industry" onChange={(e)=>{setIndustryType(e.target.value)}} className= "mt-2 py-1 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-200 rounded-md"/>
            </div>
            <div className="py-4">
              <label className="block text-sm font-medium text-gray-700" >EMAIL</label>
              <input name="email" onChange={(e)=>{setEmail(e.target.value)}} className= "mt-2 py-1 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-200 rounded-md"/>
            </div>
            <div className="py-4">
              <label className="block text-sm font-medium text-gray-700" >PERSONNUMMER</label>
              <input name="person_nummer" onChange={(e)=>{setPersonNumber(e.target.value)}} className= "mt-2 py-1 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-200 rounded-md"/>
            </div>
            <div className="py-4">
              <label className="block text-sm font-medium text-gray-700" >KONTOBEVIS</label>
              <div className="">
                <input className="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" onChange={(e)=>{setAttach(e.target.value); uploadImage(e)}} value={attach} max="1" type="file" name="my_file" accept=".jpg,.png,.pdf"/>

              </div>
            </div>
            
            <div className="py-[43px]">
                <button onClick={ name.length && companyName.length && orgNumber.length && address.length && accountNumber.length && industryType.length && personNumber.length && phone.length && email.length != 0 ? () =>  {sweetalertNotification()} : console.log("")} type={"submit"} value={"Send"} 
                className={name.length && companyName.length && orgNumber.length && address.length && accountNumber.length && industryType.length && personNumber.length && phone.length && email.length != 0 ? " flex bg-[#8c52ff] text-white py-[6px] px-4 rounded-md w-[200px] font-medium pt-2 " : " flex bg-[#8c52ff] text-white py-[6px] px-4 rounded-md w-fit font-medium pt-2 opacity-25 cursor-not-allowed "} /* className="flex justify-center bg-gradient-to-tr bg-[#8c52ff] text-white py-[6px] px-4 rounded-md w-full" */ > Skicka direkt </button>
            </div>
          </div>
        </div>
      </div>
      </form>
    </div>
    
  )
}
