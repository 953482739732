import {React, useRef, useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import Std_Master from '../../Master/Std_Master'

import Kontakt_card from '../../Components/Kontakt_card';
import Paket_Offert from '../../Components/Paket_Offert';

import Big_kassa_1 from '../../Gfx/Big_kassa_1.png'
import top_page_image_restaurang_mobile from '../../Gfx/top_page_image_restaurang_mobile.png'

import bordskarta from '../../Gfx/bordskarta.png'
import dela_nota from '../../Gfx/dela_nota.png'
import restaurang_enox_program from '../../Gfx/restaurang_enox_program.png'

import logo_new_text_white from '../../Gfx/logo_new_text_white.png'
import logo_new_text_black from '../../Gfx/logo_new_text_black.png'


import CardModalOffers from "../../Components/CardModalOffers";
import DetailCardModalOffers from "../../Components/DetailCardModalOffers";

import westpay_c100_1 from "../../Gfx/westpay_c100_1.png";

import pro_restaurang_1 from "../../Gfx/pro_restaurang_1.webp";
import top_page_restaurang from "../../Gfx/top_page_restaurang.webp";

import swal from 'sweetalert';



export default function RestaurantAndCafe(props) {
    const [isOpen, setIsOpen] = useState(false)
    const [isOpen_2, setIsOpen_2] = useState(false)
    const [reload, setReload] = useState('')

    const [paket, setPaket] = useState(null)

    const [paketProduct, setPaketProduct] = useState([""])

    const [products, setProducts] = useState([""]);



    const myElement = useRef(null);
    

    const _setIsOpen = (_paketProduct ,_paket, _products, _coockie) =>
    {
        //setPrice(_price)
        setPaketProduct(_paketProduct)
        setProducts(_products)
        setPaket(_paket)
        //setDesc(_desc)
        setIsOpen(true);
        
    }

    const _setIsOpen_2 = (_paketProduct ,_paket, _products) =>
    {
        //setPrice(_price)
        setPaketProduct(_paketProduct)
        setProducts(_products)
        setPaket(_paket)
        //setDesc(_desc)
        setIsOpen_2(true);
        
    }
    
    const isPopUp = () => {
        
        if(isOpen) {
            return < CardModalOffers key={0} productPaket={paketProduct} paket={paket} product={products} sent={()=> {setIsOpen(false)}} close={()=>{setIsOpen(false); setProducts("")}}/>
        }
      }
    const isPopUp_2 = () => {
        
        if(isOpen_2) {
            return < DetailCardModalOffers key={0} productPaket={paketProduct} paket={paket} close={()=>{setIsOpen_2(false); setProducts("")}}/>
        }
      }

    const update = ()=>{
        reload == "d" ? setReload("xd") : setReload("d")
    }

      function sweetalertNotification(){
        swal({
            title: "Tack!",
            text: "Produkten finns nu i din varukorg",
            icon: "success",
            button: "Klar",
            timer: 2000
            })
            setTimeout(function(){
                //window.location.reload(1);
             }, 2000);
    }

    /* useEffect(() => {
        if (isOpen_2) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'unset';
        }
      }, [isOpen_2]); */


      useEffect(() => {
        const element = document.getElementById('scroll-to-paket-restaurang');
        element.scrollIntoView();
      }, []);


    return (
        <div className="bg-white">
            {isPopUp()}
            {isPopUp_2()}
        <Std_Master
        NavCss={"text-black"} TitleCss={"text-slate-700 text-[17px] ml-12 hidden xl:block 2xl:mt-[300px] 3xl:mt-[400px]"}
        logo_1={logo_new_text_black}
        mobileNavBarCssWhenScroll={"text-white"}
        mobileNavBarCssWhenNotScroll={"text-black"}
        logo={logo_new_text_black}
        /* mobileLogo={logo_new_text_white} mobileLogo_1={logo_new_text_white} */ mobileLogo={logo_new_text_black} mobileLogo_1={logo_new_text_white}
        rubrik={"Ett kassasystem framtaget för restauranger!"}
        Title={" I vårt kassasystem har vi inte bara tänkt på alla viktiga funktioner våra kunder efterfrågar utan har även jobbat på designen av kassorna då vi anser att det behöver se snyggt och prydligt ut för att matcha er butik. Med Enox kassasystem kan du enkelt hålla koll på ditt lager samt sköta era bokningar på ett enkelt & smidigt sätt. Här kan ni erbjuda era kunder e-postbekräftelser efter varje bokning och med vår backoffice app hålla koll på hur försäljningen går samt vilka tider som är högst tryck oavsett om man sitter i solstolen på semester eller tar en snabb lunch på stan. För att få reda på exakt vad vi kan hjälpa er med tveka inte att kontakta oss för en Demo helt kostnadsfritt. "} 
        img={top_page_restaurang}
        img_mobile={top_page_image_restaurang_mobile}
        ShowButton={true}
        classNameCss="w-full vh-full" inner={
                <div id="scroll-to-paket-restaurang">
                    
                <div className="lg:col-span-6 md:col-span-10 col-span-1 sm:h-[100px] h-[80px] font-bold flex justify-center sm:py-8 py-6 text-3xl text-black ">Paket</div>
            <div className="grid gap-y-24 ">{/* mt-12 */}
            <div className="flex justify-center">
            <div className="grid grid-cols-1 lg:grid-cols-6 md:grid-cols-10 pt-9 xl:px-48 gap-8">
                    
                    <div className="relative lg:col-span-2 md:col-span-5 shadow-xl rounded-lg my-2 ease-in-out hover:shadow-2xl md:w-full w-[340px] mx-2 lg:mx-2 ">{/* md:mx-52 */}
                        <Paket_Offert css={""} ribbon={"579 kr/mån"} trigger={()=>_setIsOpen( /* css={"pt-8 md:pt-6 lg:pt-6 xl:pt-6 2xl:pt-8"} */
                            [{"id":0, "name": "Smart 260", "img":"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/sam4s_er_260.png", "desc":"Inbyggd skrivare & kassalåda"},
                            {"id":1, "name": "Kassakopplad kortterminal","img" : "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/westpay_1_new.png",},
                            {"id":2, "name": "Kontrollenhet","img" : "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Kontrollenhet.png",},
                        ],

                            {"id":0, "name": "Basic Restaurang", "img":"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/bas_restaurang_1.png", "price": "579"},
                            [
                            
                            {"name":"Trådlös kortterminal", "id": 0 ,"img":westpay_c100_1, "count": 0, "price": "495"},
                            /* {"name":"Backoffice", "id": 1 ,"img":"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Enox_app.png", "count": 0, "price": "99"}, */
                            
                            ],
                        )}
                            trigger_2={()=>_setIsOpen_2([{"id":0, "name": "Smart 260", "img":"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/sam4s_er_260.png"},
                            {"id":1, "name": "Kassakopplad kortterminal","img" : "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/westpay_1_new.png",},
                            {"id":2, "name": "Kontrollenhet","img" : "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Kontrollenhet.png",},
                            ],
                            {"id":0, "name": "Basic Restaurang", "img":"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/bas_restaurang_1.png"},
                            )}
                        img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/bas_restaurang_1.png"} title={"Basic Restaurang"}

                        text_1={"Kassaregister Smart 260"} 
                        text_2={"Kassakopplad kortterminal"}
                        text_3={"Kontrollenhet/Skattebox"} 
                        text_4={"Upp till 15st varugrupper"} 
                        text_5={"Ta emot betalningar via kort, swish, kontant m.m"}
                        text_6={"Elektronisk E-journal"}
                        
                            />
                    </div>
                    <div className="relative lg:col-span-2 md:col-span-5 shadow-xl rounded-lg my-2 ease-in-out hover:shadow-2xl md:w-full w-[340px] mx-2 lg:mx-2 ">{/* md:mx-52 */}
                        <Paket_Offert css={""} ribbon={"1290 kr/mån"} trigger={()=>_setIsOpen( /* css={"pt-8 md:pt-6 lg:pt-6 xl:pt-6 2xl:pt-8"} */
                            [{"id":0, "name": "Smart PC-Kassa", "img":"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Restaurang_kassa_1.png", "desc":"Enox kassaprogram"},
                            {"id":1, "name": "Kassakopplad kortterminal","img" : "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/westpay_1_new.png",},
                            {"id":2, "name": "Kontrollenhet","img" : "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Kontrollenhet.png",},
                            {"name":"Kassalåda ", "id": 3 ,"img":"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/kassa_loda_1.png", "count": 0},
                            {"name":"Smart printer ", "id": 4 ,"img":"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Kvittoskrivare_1_new.png"},
                        ],

                            {"id":0, "name": "Pro Restaurang", "img":"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Restaurang_standart_2.png", "price": "1290"},
                            [
                            
                            {"name":"Smart PC-Kassa ", "id": 0 ,"img":"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Restaurang_kassa_1.png","price":"890", "desc":"Ingår skattebox, skrivare, kassalåda & Enox kassaprogram", "count": 0},
                            {"name":"Trådlös kortterminal", "id": 1 ,"img":"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/westpay_1_new.png","price":"395", "count": 0},
                            {"name":"Smart printer ", "id": 2 ,"img":"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Kvittoskrivare_1_new.png","enGong":"2990", "count": 0, "price": "59"},
                            {"name":"Kitchen Video ", "id": 3 ,"img":"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Kitchen.png","price":"299", "count": 0},
                            {"name":"Backoffice", "id": 4 ,"img":"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Enox_app.png", "count": 0, "price": "99"},
                            
                            ]
                            )}
                            trigger_2={()=>_setIsOpen_2([
                            {"id":0, "name": "Smart PC-Kassa", "img":"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Restaurang_kassa_1.png", "desc":"Enox kassaprogram"},
                            {"id":1, "name": "Kassakopplad kortterminal","img" : "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/westpay_1_new.png",},
                            {"id":2, "name": "Kontrollenhet","img" : "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Kontrollenhet.png",},
                            {"name":"Kassalåda ", "id": 3 ,"img":"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/kassa_loda_1.png", "count": 0},
                            {"name":"Smart printer ", "id": 4 ,"img":"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Kvittoskrivare_1_new.png"},
                            ],
                            {"id":0, "name": "Pro Restaurang", "img":"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Restaurang_standart_2.png"},
                            )}
                        img={pro_restaurang_1} title={"Pro Restaurang"}

                        text_1={"Smart PC-Kassa-Kassa"} 
                        text_2={"Kassakopplad kortterminal"}
                        text_3={"Kontrollenhet/Skattebox"}
                        text_4={"Bordshantering"} 
                        text_5={"Köksbongar"} 
                        text_6={"Enox kassaprogram"}
                            />
                    </div>
                    <div className="relative lg:col-span-2 md:col-span-5 shadow-xl rounded-lg my-2 ease-in-out hover:shadow-2xl md:w-full w-[340px] mx-2 lg:mx-2 ">{/* md:mx-52 */}
                        <Paket_Offert css={""}  ribbon={"1990 kr/mån"} trigger={()=>_setIsOpen( /* css={"pt-8 md:pt-6 lg:pt-6 xl:pt-6 2xl:pt-8"} */
                            [{"id":0, "name": "Självbetjäningskassa", "img":Big_kassa_1, "desc":"Ingår inbyggd skrivare & Enox kassaprogram"},
                            {"id":1, "name": "Kontrollenhet","img" : "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Kontrollenhet.png",},
                            {"id":2, "name": "Kassakopplad kortterminal","img" : "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/westpay_1_new.png",},
                            {"name":"Smart köksprinter", "id": 3 ,"img":"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Kvittoskrivare_1_new.png", "count": 0},
                        ],

                            {"id":0, "name": "Premium Restaurang", "img":Big_kassa_1, "price": "1790"},
                            [
                            {"name":"Smart PC-Kassa ", "id": 0 ,"img":"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Restaurang_kassa_1.png","price":"890", "desc":"Ingår skattebox, skrivare, kassalåda & Enox kassaprogram", "count": 0},
                            {"name": "Självbetjäningskassa", "id":1, "img":Big_kassa_1, "desc":"Ingår kassakopplad kortterminal, skattebox, inbyggd skrivare & Enox kassaprogram","price":"1490","count": 0},
                            {"name":"Kitchen Video ", "id": 2 ,"img":"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Kitchen.png","price":"299", "count": 0},
                            {"name":"Trådlös kortterminal ", "id": 3 ,"img":"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/westpay_1_new.png","price":"395", "count": 0},
                            /* {"name":"Kassalåda ", "id": 4 , "desc":"Vid beställning av Smart PC-Kassa" ,"img":"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/kassa_loda_1.png","enGong":"1250", "count": 0}, */
                            {"name":"Backoffice", "id": 4 ,"img":"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Enox_app.png", "count": 0, "price": "99"},
                            ]
                            )}
                            trigger_2={()=>_setIsOpen_2([
                            {"id":0, "name": "Självbetjäningskassa", "img":Big_kassa_1, "desc":"Ingår inbyggd skrivare & Enox kassaprogram"},
                            {"id":1, "name": "Kassakopplad kortterminal","img" : "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/westpay_1_new.png",},
                            {"id":2, "name": "Kontrollenhet","img" : "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Kontrollenhet.png",},
                            ],
                            {"id":0, "name": "Premium Restaurang", "img":Big_kassa_1},
                            )}
                        img={Big_kassa_1} title={"Premium Restaurang"}

                        text_1={"Smart självbetjäning"} 
                        text_2={"Integrerad kortterminal"}
                        text_3={"Kontollenhet/Skattebox"}
                        text_4={"Integrerad skrivare & kökskrivare"}
                        text_5={"Alla funktioner i pro paketet ingår"}
                        text_6={"Enox kassaprogram"}
                        
                            />
                    </div>
                </div>
            </div>

                    <div className="flex text-md text-gray-500 justify-center animate-bounce"> {/* Added a margin-top for spacing. Adjust as per your need */}
                        <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/kassa_Stockholm" className='flex justify-center bg-gradient-to-tr bg-[#8c52ff] opacity-80 text-md text-white py-[8px] px-4 rounded-xl xl:w-[340px] w-auto'> Kassalösningar till ditt företag i Stockholm </Link>
                    </div>


                <div className="grid grid-cols-5 w-full xl:px-48 items-center border-b gap-4 xl:hidden">
                <div className="lg:col-span-3 col-span-5 ">
                        <div className="col-span-1 md:h-[500px] xl:h-[500px] 2xl:h-[600px] h-[280px] bg-contain bg-no-repeat" style={{backgroundImage: "url(" + "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Restaurant_new_pic_1.png" + ")"}}></div>
                    </div>
                    <div className="lg:col-span-2 col-span-5 px-2 mx-2 relative">
                    <p className="hidden md:block xl:text-[25px] 2xl:text-3xl font-extrabold tracking-tight text-gray-900 sm:text-3xl ">Ett kassasystem skapad för<br/> Restaurang, bar & caféverksamhet!</p>
                            <p className="block md:hidden text-3xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">Ett kassasystem skapad för Restaurang, Bar Caféverksamhet!</p>
                            <div className="md:w-full mt-4">
                                <h2 className="font-medium xl:text-[17px] text-gray-500">
                                    Med vårt kassasystem Enox kan du räkna med en stor effektivisering i hur arbetet flyter inom er verksamhet. <br/>
                                    Designat och utvecklat av människor med lång erfarenhet inom restaurang och café branschen så vi vet vad som underlättar just er vardag. 
                                    Du kan enkelt använda bordskartan för att spara bordsbeställningar, dela era notor och bonga beställningar till köket och så mycket mer. 
                                    Kolla gårdagens resultat och storsäljare? Inga problem med vår backoffice app oavsett vart man befinner sig. Här erbjuds en kassa som verkligen underlättar din försäljning. 
                                    Boka tid för en demo helt kostnadsfritt.
                                </h2>

                                <div className="flex justify-end w-full py-8 relative">
                                <Link to="/kontakt" className='flex justify-center bg-gradient-to-tr bg-[#8c52ff] text-white py-[6px] px-4 rounded-md w-[180px]'> Jag vill veta mer </Link>
                            </div>
            
                            </div>
                    </div>
                    
                </div>
                <div className=" flex justify-center items-center text-3xl sm:h-[100px] h-[80px] sm:py-8 py-6 text-white border-b font-bold bg-[#8c52ff]">Integrationer</div>
                <div className="flex justify-center">
                    <div className="grid sm:grid-cols-2 xl:grid-cols-3 grid-cols-2 sm:h-[600px]"> {/* xl:px-[340px] */}
                    {/* <div className="lg:col-span-3 md:col-span-10 col-span-1 h-[100px] font-bold flex justify-center py-8 text-[40px] text-gray-700 border-b ">Integrationer</div> */}
                        <div className="col-span-1 flex justify-center items-center sm:bg-cover bg-cover bg-no-repeat md:w-[370px] md:h-[240px] w-[180px] h-[200px] bg-center " style={{backgroundImage: "url(" + "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Integration_1.png" + ")"}}></div> {/* sm:w-[470px] sm:h-[300px] */}
                        <div className="col-span-1 flex justify-center items-center sm:bg-cover bg-cover bg-no-repeat md:w-[370px] md:h-[240px] w-[180px] h-[200px] bg-center " style={{backgroundImage: "url(" + "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Integration_2.png" + ")"}}></div> {/* sm:w-[470px] sm:h-[300px] */}
                        <div className="col-span-1 flex justify-center items-center sm:bg-cover bg-cover bg-no-repeat md:w-[370px] md:h-[240px] w-[180px] h-[200px] bg-center " style={{backgroundImage: "url(" + "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Integration_3.png" + ")"}}></div> {/* sm:w-[470px] sm:h-[300px] */}
                        
                        <div className="col-span-1 flex justify-center items-center sm:bg-cover bg-cover bg-no-repeat md:w-[370px] md:h-[240px] w-[180px] h-[200px] bg-center " style={{backgroundImage: "url(" + "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Integration_4.png" + ")"}}></div> {/* sm:w-[470px] sm:h-[300px] */}
                        <div className="col-span-1 flex justify-center items-center sm:bg-cover bg-cover bg-no-repeat md:w-[370px] md:h-[240px] w-[180px] h-[200px] bg-center " style={{backgroundImage: "url(" + "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Integration_5.png" + ")"}}></div> {/* sm:w-[470px] sm:h-[300px] */}
                        <div className="col-span-1 flex justify-center items-center sm:bg-cover bg-cover bg-no-repeat md:w-[370px] md:h-[240px] w-[180px] h-[200px] bg-center " style={{backgroundImage: "url(" + "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Integration_6.png" + ")"}}></div> {/* sm:w-[470px] sm:h-[300px] */}
                        
                    </div>
                </div>

            

                <div className="bg-gray-50 bg-contain bg-center"> {/* style={{backgroundImage: "url(" + bg_1 + ")"}} */}
                <div className=" flex justify-center items-center text-3xl sm:h-[100px] h-[80px] sm:py-8 py-6 text-white border-b font-bold bg-[#8c52ff]">FUNKTIONER</div>
                    <div className="grid md:grid-cols-2 grid-cols-1 xl:mx-60 py-20">
                    
                        <div className="grid-cols-1 ">
                            <div className="grid grid-rows-3">
                                <div className="row-span-1 flex justify-start h-[350px] relative">
                                    <div className=" px-6 py-4">
                                        <div className="flex mt-2 text-md text-gray-500">
                                            <div className="mt-[3px] mr-2"><div className="bg-[#8c52ff] w-[12px] h-[12px] mt-1 mr-2 rounded-full"></div></div> Multipay (för kunder som vill kunna ta flera betalningar samtidigt)
                                        </div>
                                        <div className="flex mt-2 text-md text-gray-500">
                                            <div className="mt-[3px] mr-2"><div className="bg-[#8c52ff] w-[12px] h-[12px] mt-1 mr-2 rounded-full"></div></div> Pay@Table ( öppna upp notor vid bordet och ta betalt direkt i kortterminalen)
                                        </div>
                                        <div className="flex mt-2 text-md text-gray-500">
                                            <div className="mt-[3px] mr-2"><div className="bg-[#8c52ff] w-[12px] h-[12px] mt-1 mr-2 rounded-full"></div></div> Presentkort/Rabattkort & Lunchkort (pappersform eller plastkort)
                                        </div>
                                        <div className="flex mt-2 text-md text-gray-500">
                                            <div className="mt-[3px] mr-2"><div className="bg-[#8c52ff] w-[12px] h-[12px] mt-1 mr-2 rounded-full"></div></div> Sökfält inbyggt i kassan för att snabbt kunna söka upp kvitton, kunder & artiklar
                                        </div>
                                        <div className="flex mt-2 text-md text-gray-500">
                                            <div className="mt-[3px] mr-2"><div className="bg-[#8c52ff] w-[12px] h-[12px] mt-1 mr-2 rounded-full"></div></div> Drickshantering enligt Skatteverkets nya krav
                                        </div>
                                        <div className="flex mt-2 text-md text-gray-500">
                                            <div className="mt-[3px] mr-2"><div className="bg-[#8c52ff] w-[12px] h-[12px] mt-1 mr-2 rounded-full"></div></div> Bokningskalender med e-post bekräftelse
                                        </div>
                                        <div className="flex mt-5 text-md text-gray-500 animate-bounce"> {/* Added a margin-top for spacing. Adjust as per your need */}
                                            <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/kassa_Linkoping" className='flex justify-center bg-gradient-to-tr bg-[#8c52ff] opacity-80 text-md text-white py-[8px] px-4 rounded-xl xl:w-[340px] w-auto'> Kassalösningar till ditt företag i Linköping </Link>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row-span-1 flex justify-start h-[350px] relative bg-contain bg-center bg-no-repeat mx-2" style={{backgroundImage: "url(" + bordskarta + ")"}}>
                                </div>
                                <div className="row-span-1 flex justify-start h-[350px] relative">
                                    <div className=" px-6 py-4 pt-16">
                                        <div className="flex mt-2 text-md text-gray-500">
                                            <div className="mt-[3px] mr-2"><div className="bg-[#8c52ff] w-[12px] h-[12px] mt-1 mr-2 rounded-full"></div></div> Smidigt dela bordsläge
                                        </div>
                                        <div className="flex mt-2 text-md text-gray-500">
                                            <div className="mt-[3px] mr-2"><div className="bg-[#8c52ff] w-[12px] h-[12px] mt-1 mr-2 rounded-full"></div></div> Elektroniskt kvitto (välj att antingen mejla kvittot eller arkivera med ett knapptryck)
                                        </div>
                                        <div className="flex mt-2 text-md text-gray-500">
                                            <div className="mt-[3px] mr-2"><div className="bg-[#8c52ff] w-[12px] h-[12px] mt-1 mr-2 rounded-full"></div></div> Inbyggt fakturasystem
                                        </div>
                                        <div className="flex mt-2 text-md text-gray-500">
                                            <div className="mt-[3px] mr-2"><div className="bg-[#8c52ff] w-[12px] h-[12px] mt-1 mr-2 rounded-full"></div></div> Backoffice
                                        </div>
                                        <div className="flex mt-2 text-md text-gray-500">
                                            <div className="mt-[3px] mr-2"><div className="bg-[#8c52ff] w-[12px] h-[12px] mt-1 mr-2 rounded-full"></div></div> Rapporter i pdf format skickat direkt till din mejladress & er ekonomiansvarig/revisor
                                        </div>
                                        <div className="flex mt-2 text-md text-gray-500">
                                            <div className="mt-[3px] mr-2"><div className="bg-[#8c52ff] w-[12px] h-[12px] mt-1 mr-2 rounded-full"></div></div> Artiklar kopplade till meddelanden
                                        </div>
                                        <div className="flex mt-5 text-md text-gray-500 animate-bounce"> {/* Added a margin-top for spacing. Adjust as per your need */}
                                            <Link onClick={()=>window.scrollTo({top: 1000, behavior: 'smooth'})} to="/kassa_Goteborg" className='flex justify-center bg-gradient-to-tr bg-[#8c52ff] opacity-80 text-md text-white py-[8px] px-4 rounded-xl xl:w-[340px] w-auto'> Kassalösningar till ditt företag i Göteborg </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid-cols-1">
                            <div className="grid grid-rows-3">
                                <div className="row-span-1 flex justify-start h-[300px] mt-14 sm:mt-0 relative bg-contain bg-center bg-no-repeat mx-2 " style={{backgroundImage: "url(" + restaurang_enox_program + ")"}}>
                                </div>
                                <div className="row-span-1 flex justify-start h-[350px] relative">
                                    <div className=" px-6 py-4 pt-16">
                                        <div className="flex mt-2 text-md text-gray-500">
                                            <div className="mt-[3px] mr-2"><div className="bg-[#8c52ff] w-[12px] h-[12px] mt-1 mr-2 rounded-full"></div></div> Bordshantering
                                        </div>
                                        <div className="flex mt-2 text-md text-gray-500">
                                            <div className="mt-[3px] mr-2"><div className="bg-[#8c52ff] w-[12px] h-[12px] mt-1 mr-2 rounded-full"></div></div> Bongsystem för köket (koppla hur många bongar du vill)
                                        </div>
                                        <div className="flex mt-2 text-md text-gray-500">
                                            <div className="mt-[3px] mr-2"><div className="bg-[#8c52ff] w-[12px] h-[12px] mt-1 mr-2 rounded-full"></div></div> Personalliggare
                                        </div>
                                        <div className="flex mt-2 text-md text-gray-500">
                                            <div className="mt-[3px] mr-2"><div className="bg-[#8c52ff] w-[12px] h-[12px] mt-1 mr-2 rounded-full"></div></div> Ordersystem med nummer (via skärm eller pappersformat)
                                        </div>
                                        <div className="flex mt-2 text-md text-gray-500">
                                            <div className="mt-[3px] mr-2"><div className="bg-[#8c52ff] w-[12px] h-[12px] mt-1 mr-2 rounded-full"></div></div> Pucksystem där du kan ange pucknummer som skrivs ut på kvittot
                                        </div>
                                        <div className="flex mt-2 text-md text-gray-500">
                                            <div className="mt-[3px] mr-2"><div className="bg-[#8c52ff] w-[12px] h-[12px] mt-1 mr-2 rounded-full"></div></div> Samla bongar för att skicka ordrar tillsammans
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="row-span-1 flex justify-start h-[350px] relative bg-contain bg-center bg-no-repeat mx-2" style={{backgroundImage: "url(" + dela_nota + ")"}}></div>
                            </div>
                        </div>
                    </div>

                </div>
                
                
        </div>

            <div className='max-w-7xl mx-auto py-16 px-4 sm:py-16 sm:px-6 lg:px-8'>
                <Kontakt_card />
            </div>
            </div>
        } />
        </div>
    )
}






