import {React, useRef, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Std_Master from '../../Master/Std_Master';
import InfoCard from '../../Components/InfoCard';
import logo_new_text from "../../Gfx/logo_new_text.png";
import logo_new_text_white from "../../Gfx/logo_new_text_white.png";


export default function AllOffersPage(props) {

  const handleClickToRestaurang = () => {
    window.location.hash = '#scroll-to-paket-restaurang';
  }
  const handleClickToBeauty = () => {
    window.location.hash = '#scroll-to-paket-beauty';
  }
  const handleClickToLivsMedel = () => {
    window.location.hash = '#scroll-to-paket-livsmedel';
  }
  
  return(
    <Std_Master selectedNavItem={0} logo_1={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/logo_new_text_white.png"} logo={logo_new_text} mobileLogo={logo_new_text} mobileLogo_1={logo_new_text_white} NavCss={"text-white"} Title="." mobileNavBarCss={"text-black"} NavBg={"bg-[#8c52ff]"} inner={
    <div className="bg-white pb-44">

      
      <div className='flex items-center justify-center w-full lg:py-[97px]'> {/* mt-8 */}
        <div className='py-4 flex grid-cols-5 flex-wrap justify-center gap-x-6'>
          <div className='col-start-2 col-span-1 lg:relative' onClick={()=>handleClickToRestaurang()}>
            <Link  /* onClick={()=>window.scrollTo({top: 900, behavior: 'smooth'})} */ to="/restaurant-and-cafe">
              <InfoCard main_css={"md:w-[350px] md:h-[450px] w-[250px] h-[350px]"} css={"md:mt-52 mt-36"} content_css={"md:mt-48 mt-32"} check={"1"} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Restaurang_home_page.png"} title_2={"RESTAURANG & CAFE"} />
            </Link>
          </div>
          <div className='col-start-2 col-span-1 lg:relative' onClick={()=>handleClickToBeauty()}>
            <Link /* onClick={()=>window.scrollTo({top: 900, behavior: 'smooth'})} */ to="/beauty-and-health">
              <InfoCard main_css={"md:w-[350px] md:h-[450px] w-[250px] h-[350px]"} css={"md:mt-52 mt-36"} content_css={"md:mt-48 mt-32"} check={"1"} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Sko%CC%88nhet_home_page_1.png"} title_2={"SKÖNHET & HÄLSA"} />
            </Link>
          </div>
          <div className='col-start-2 col-span-1 lg:relative' onClick={()=>handleClickToLivsMedel()}>
            <Link /* onClick={()=>window.scrollTo({top: 900, behavior: 'smooth'})} */ to="/grocery-stores">
              <InfoCard main_css={"md:w-[350px] md:h-[450px] w-[250px] h-[350px]"} css={"md:mt-52 mt-36"} content_css={"md:mt-48 mt-32"} check={"1"} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Livs_medel_home_page.png"} title_2={"LIVSMEDEL & KIOSK"} />
            </Link>
          </div>
        </div>
      </div>
      </div>

  }/>
  );
}
