import React, {useState, useEffect} from 'react'
import { getCookie , setCookie} from './CookieHandler';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { AiOutlineArrowLeft } from 'react-icons/ai';

import swal from 'sweetalert';

export default function CardModal(props) {

    /* const [imgi, setImgi] = useState(0) */
    const [changeImg, setChangeImg] = useState(0)

    const [reload, setReload] = useState("")

    const [checked, setChecked] = useState(false);

    function sweetalertNotification(){
        swal({
            title: "Tack!",
            text: "Produkten finns nu i din varukorg",
            icon: "success",
            button: "Klar",
            timer: 2000
            })
            setTimeout(function(){
                {reload == "d" ? setReload("md") : setReload("d")}
                //window.location.reload(1);
             }, 2000);
    }

    useEffect(() => {
        setReload("d")
    })

    return (
        <div className="fixed z-20 w-full h-full bg-[#00000050] " onClick={()=>{props.close()}}>
            <div className="sm:w-full sm:h-full flex justify-center items-center ">
                <div onClick={(e) => e.stopPropagation()} className="mx-4 grid grid-rows-7 sm:w-[1250px] sm:h-auto h-[600px] mt-24 sm:mt-0 bg-white shadow-md rounded-lg">
                    <div className="grid sm:grid-cols-3 grid-cols-2">
                        <div className="col-span-2">
                            <div className="grid grid-rows-2 sm:grid-rows-3">
                                <div className="row-span-2 flex bg-gray-200">
                                    <div className="grid grid-cols-7">
                                        <div className="col-span-1 flex justify-center">
                                            <div onClick={() => setChangeImg(changeImg - 1)} className={changeImg == 0 ? " invisible w-24 h-24" : "w-12 h-11  p-1 cursor-pointer mt-[220px] bg-[#8c52ff] text-white text-center rounded-md ml-3"}><AiOutlineArrowLeft size={35}/></div>
                                        </div>
                                        <div className="col-span-5">
                                            <img className="object-cover object-center lg:w-[800px] sm:w-[600px] sm:h-[400px] w-[400px] lg:h-[500px] h-[250px] select-none" src={changeImg == 0 ? props.img[0] : "" || changeImg == 1 ? props.img[1] : props.img[2]}/>
                                        </div>
                                        <div className="col-span-1 flex justify-center">
                                            <div onClick={() => setChangeImg(changeImg + 1)} className={changeImg == 2 ? "sm:w-24 sm:h-24 w-4 h-4 invisible" : "my-2 w-12 h-11 p-1 cursor-pointer mt-[220px] bg-[#8c52ff] text-white text-center rounded-md mr-3"}><AiOutlineArrowRight size={35}/></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sm:block hidden bg-gray-100">
                                    <div className="row-span-1 h-full w-full flex justify-center items-center mx-4">
                                        <div onClick={() => setChangeImg(0)} className={changeImg == 0 ? "shadow-lg duration-300 ease-in-out hover:-translate-y-1 hover:scale-110 px-4 py-4 rounded-lg text-white mx-2 bg-gray-300 " :
                                        "shadow-lg duration-300 ease-in-out hover:-translate-y-1 hover:scale-110 px-4 py-4 rounded-lg text-white mx-2"}>
                                            <img className="rounded-lg flex justify-start object-cover w-[150px] h-[150px] select-none" src={props.img[0]}/> {/* mx-8 p-1 */}
                                        </div>
                                        <div onClick={() => setChangeImg(1)} className={ changeImg == 1 ? "shadow-lg duration-300 ease-in-out hover:-translate-y-1 hover:scale-110 px-4 py-4 rounded-lg text-white mx-2 bg-gray-300" : 
                                        "shadow-lg duration-300 ease-in-out hover:-translate-y-1 hover:scale-110 px-4 py-4 rounded-lg text-white mx-2"}>
                                            <img className="rounded-lg flex justify-start object-cover w-[150px] h-[150px] select-none" src={props.img[1]}/> {/* mx-8 p-1 */}
                                        </div>
                                        <div onClick={() => setChangeImg(2)} className={ changeImg == 2 ? "shadow-lg duration-300 ease-in-out hover:-translate-y-1 hover:scale-110 px-4 py-4 rounded-lg text-white mx-2 bg-gray-300" :
                                        "shadow-lg duration-300 ease-in-out hover:-translate-y-1 hover:scale-110 px-4 py-4 rounded-lg text-white mx-2"}>
                                            <img className="rounded-lg flex justify-start object-cover w-[150px] h-[150px] select-none" src={props.img[2]}/> {/* mx-8 p-1 */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-1">
                        <div className="grid grid-rows-3">
                            <div className="row-span-1 my-8 ">
                                <h1 className="text-bold text-[23px] flex justify-between select-none mx-4">{props.title} <p className="bg-slate-300 text-gray-700 rounded-lg p-2 text-sm hidden">{props.price} - {props.enGong}</p></h1>
                                <div className="text-gray-700 text-sm mx-4">{props.desc}</div>
                                <br/>
                                {props.show_checkbox ? 
                                <div className={checked ? "flex justify-center bg-green-400 rounded-lg py-1 px-2 text-red text-white w-fit mx-2 font-semibold" : "flex justify-center py-1 px-2 w-fit mx-2 text-gray-800"}>{props.payAll}<input type="checkbox"
                                className=" items-center accent-[#8c52ff] w-4 h-4 mr-4 mt-1 mx-2"
                                    defaultChecked={checked}
                                    onChange={() => setChecked(!checked)}
                                /></div> : <div></div>}
                                
                                
                            </div>
                                <div className="row-span-1 flex justify-center h-[40px] ">
                                    <button className=" flex justify-center rounded-lg h-10 shadow-sm hover:shadow-md bg-slate-300 text-white py-[6px] px-4 sm:w-[160px] w-[135px] font-medium pt-2 mx-2" onClick={()=>{props.close()}}>Avbryt</button>
                                    {/* <button className=" bg-[#8c52ff] text-white py-[8px] px-4 rounded-md sm:w-[170px] w-[150px] mx-2 font-semibold"  onClick={()=>{props.add()}}>Lägg till</button> */}
                                    <button onClick={()=>{
                                    sweetalertNotification()
                                    //setAbort(true)

                                    let customProduct = !checked && props.title != "Foodora Kvittorullar" && !checked && props.title != "Kassaregister Kvittorullar" && !checked && props.title != "Kortterminal Kvittorullar" && !checked && props.title != "Pc-kassa Kvittorullar" &&
                                    !checked && props.title != "Handskanner" && !checked && props.title != "GIANT100" && !checked && props.title != "U80 Printer" && !checked && props.title != "Kassalåda" ?

                                    {name:props.title,price:props.price,img:props.img[0],count:1, payAll:checked}
                                    :
                                    {name:props.title,enGong:props.enGong,img:props.img[0],count:1, payAll:checked};


                                    if(getCookie("products").length>0){ //cookie?
                                        //yey there was a cookie :)
                                       //lets add to it
                                       let products = JSON.parse(getCookie("products"));
                        
                                        const duplicates = products.filter(prod => prod.name === customProduct.name && prod.price === customProduct.price);

                                        if(duplicates.length == 0){
                                            products.push(customProduct);
                                        }
                                        else{
                                            
                                            duplicates[0].count += 1
                                        }
                                        setCookie("products",JSON.stringify(products));

                                    }
                                    else{
                                        //there was no cookie :(
                                        //lets create a cookie
                                        let prod = [];
                                        prod.push(customProduct);
                                        setCookie("products",JSON.stringify(prod));
                                    }
                                   
                                }} className='flex justify-center rounded-lg h-10 shadow-sm hover:shadow-md bg-[#8c52ff] text-white py-[6px] px-4 sm:w-[160px] w-[135px] font-medium pt-2 mx-2' to=""> Lägg till</button>
                            </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
    )
}