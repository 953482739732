import {React, useRef, useEffect, useState} from 'react'
import Std_Master from '../../Master/Std_Master'
import Enox_ipad_iphon from '../../Gfx/Enox_ipad_iphon.png'
import top_image_new from "../../Gfx/top_image_new.webp";
import test_666_mobile from "../../Gfx/test_666_mobile.png";
import dela_nota from "../../Gfx/dela_nota.png";
import bordskarta from "../../Gfx/bordskarta.png";
import lager from "../../Gfx/lager.png";
import logo_new_text_black from '../../Gfx/logo_new_text_black.png'
import logo_new_text_white from '../../Gfx/logo_new_text_white.png'
import { FaCheckCircle } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';



export default function Kassa_Lin() {

  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
      
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    });
  }, []);
    return (
        <div className="bg-white">

          <Helmet replace={true}>
            <title>Kassasystem Linköping | Kassaregister Linköping | Smart Cash</title>
          </Helmet>
        
        <Std_Master 
         NavCss={"text-black"} TitleCss={"text-white text-[17px] ml-12 hidden xl:block 2xl:mt-[300px] 3xl:mt-[400px] custom-opacity rounded-lg xl:top-[220px]"}
         rubrikCss={"text-white"}
         logo_1={logo_new_text_black}
         mobileNavBarCssWhenScroll={"text-white"}
        mobileNavBarCssWhenNotScroll={"text-black"}
         logo={logo_new_text_black}
         /* mobileLogo={logo_new_text_white} mobileLogo_1={logo_new_text_white} */ mobileLogo={logo_new_text_black} mobileLogo_1={logo_new_text_white}
         rubrik={"Kassasystem Linköping | Kassaregister Linköping | Smart Cash"}
         Title={" Våra kassasystem är försedda med de senaste tekniska lösningarna för att kunna möta era önskningar. Högkvalitativa kassaregister för företag i Linköping. "}
         img={top_image_new}
        img_mobile={test_666_mobile}
         ShowButton={true}
        inner={



          <div className="md:container md:mx-auto my-12 px-4 md:px-0" data-aos="zoom-in-up">

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center my-8 bg-white rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <div className="text-center md:text-left p-4">
                <h2 className="text-2xl sm:text-3xl md:text-2xl font-bold mb-4">Kassasystem Linköping – salong, café eller detaljhandel?</h2>
                <p className="text-base sm:text-lg md:text-lg ">Varje verksamhet är unik. Vi lyssnar alltid på våra kunder och försöker specialanpassa betalningsutrustningen så att den passar era användningsområden. Våra engagerade serviceexperter finns för att bistå dig att välja rätt kassalösning och tillbehör.</p>
              </div>
              <div className="p-4">
                <h2 className="text-xl sm:text-2xl md:text-2xl font-bold mb-4">Vi erbjuder skräddarsydda paket som är utformade för att tillgodose de särskilda behoven inom flera olika branscher såsom:</h2>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <div>
                    <p className="my-2 text-lg md:text-md font-semibold flex items-center space-x-2"><FaCheckCircle className="text-green-500 mx-1"/>Restaurang, café, bar och klubb</p>
                    <p className="text-lg md:text-md font-semibold flex items-center space-x-2"><FaCheckCircle className="mx-1 text-green-500"/>Kiosker och livsmedelsbutiker</p>
                  </div>
                  <div>
                    <p className="my-2 text-lg md:text-md font-semibold flex items-center space-x-2"><FaCheckCircle className="mx-1 text-green-500"/>Frisörer och skönhetssalonger</p>
                    <p className="text-lg md:text-md font-semibold flex items-center space-x-2"><FaCheckCircle className="mx-1 text-green-500"/>Kemtvätt och servicebutiker</p>
                  </div>
                  <div>
                    <p className="my-2 text-lg md:text-md font-semibold flex items-center space-x-2"><FaCheckCircle className="mx-1 text-green-500"/>Detaljhandel</p>
                    <p className="text-lg md:text-md font-semibold flex items-center space-x-2"><FaCheckCircle className="mx-1 text-green-500"/>Torghandel</p>
                  </div>
                  {/* <div>
                    <p className="my-2 text-lg md:text-md font-semibold flex items-center space-x-2"><FaCheckCircle className="mx-1 text-green-500"/>Torghandel och gatukök</p>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Skonhet.png"} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:mr-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Kassasystem Linköping – säkra och smidiga system</h2>
                <p className="text-lg mb-4 xl:mx-24">Vi erbjuder innovativa lösningar som garanterar trygghet, smidighet och säkerhet för ditt företag. Våra kassasystem är försedda med de senaste tekniska lösningarna för att kunna möta era önskningar. Vi skapar skräddarsydda lösningar som passar just ert företag, oavsett om ni driver ett café, salong eller annan typ av verksamhet. Våra engagerade servicetekniker finns tillgängliga för att bistå er med installation och support. </p>

                <div className="text-start mt-4 xl:mx-24"> {/* Added a margin-top for spacing. Adjust as per your need */}
                  <div className="">
                    <Link to="/AllOffersPage" className='flex justify-center bg-gradient-to-tr bg-[#8c52ff] text-xl text-white py-[8px] px-4 rounded-md xl:w-[220px] w-auto'> Se mer av våra paket </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row-reverse justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={Enox_ipad_iphon} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:ml-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Kassaregister Linköping – skalbara och flexibla</h2>
                <p className="text-lg mb-4 xl:mx-24">Välj ett betalsystem som kan växa med ditt företag. Hos oss hittar ni flexibla och skalbara kassor som är perfekta för verksamheter i service- och detaljhandeln.
                                                    Oavsett om du driver en kiosk eller restaurangkedja har vi lösningar för ert företag. Vi förstår att dina behov kan förändras över tid och därför har vi anpassningsbara kassasystem som kan uppgraderas när din verksamhet växer. Med användarvänliga funktioner och intuitiva interface kan dina medarbetare snabbt och smidigt lära sig att hantera er kassa.
                                                    Det är viktigt att kunden har möjlighet att betala på olika sätt. Med våra kassasystem finns det flera betalningsalternativ - både traditionella och digitala. Det bidrar till en smidigare hantering och nöjda kunder!
                </p>
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/restaurang_enox_program.png"} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:mr-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Den smidigaste kassan i Linköping</h2>
                <p className="text-lg mb-4 xl:mx-24">Snabba och säkra transaktioner är viktigt för att minimera kötiden för era kunder. Våra kassasystem erbjuder just det - utan att för den delen vara krångliga och svåra att använda. På så vis kan ni vara redo att använda kassan på en gång. Med våra lättanvända kassor hanterar ni specialerbjudande och prissättning direkt.
                                                    Säkerhet är en otroligt viktig aspekt när det kommer till kassasystem. Våra kassor är försedda med modernt utvecklade säkerhetsfunktioner som skyddar företagets data och historik. Ni kan känna er trygga med att våra system uppdateras kontinuerligt och följer alla lagar och regler. 

                </p>
                <div className="text-start mt-4 xl:mx-24"> {/* Added a margin-top for spacing. Adjust as per your need */}
                  <div className="">
                    <Link to="/AllOffersPage" className='flex justify-center bg-gradient-to-tr bg-[#8c52ff] text-xl text-white py-[8px] px-4 rounded-md xl:w-[220px] w-auto'> Se mer av våra paket </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row-reverse justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={lager} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:ml-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Mer än bara en kassa</h2>
                <p className="text-lg mb-4 xl:mx-24">Med våra användarvänliga system kommer din verksamhet att fungera smidigt utan onödiga uppehåll och hinder. Det är alltför vanligt att kortterminaler krånglar, vilket gör att flödet stannar upp. Det i sin tur kan orsaka köbildning. Vid rusningstider kan det vara rent förödande att inte kunna ta betalt då det kan leda till förlorade intäkter. Dessutom får kunderna ett dåligt intryck då betalningsmekaniskmen inte verkar fullt pålitlig.
                            Smidiga kortterminaler har istället motsatt effekt. Utan köer blir er service snabbare. På så vis har ni möjlighet att ta bättre hand om era kunder och bygga upp ett gott rykte.
                </p>
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={dela_nota} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:mr-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Moderna och klassiska lösningar</h2>
                <p className="text-lg mb-4 xl:mx-24">Utforska vårt imponerande utbud av kassatillbehör som scanners, vågar, och skrivare - kvalitativa och stabila tillbehör som är gjorda för att maximera er produktivitet.
                                                    Har man en livsmedelsbutik eller kiosk är en pålitlig våg en essentiell del av en välfungerande kassa. Vi erbjuder vågar som ger er noggranna mätningar av viktbaserade produkter. Med enkel användning och pålitliga resultat kan ni snabbt väga och prissätta era varor.
                                                    För att kunna läsa streckkoder är en högpresterande scanner ett oumbärligt tillbehör till ert kassasystem. Med en snabb avläsning kan ni smidigt och enkelt registrera varor och ta betalt. Det resulterar i bättre service och mindre kötid för dina kunder. 
                                                    Förutom vågar och scanners har vi också andra tillbehör såsom kassalådor. Vi har valt ut tillbehör av mycket hög kvalitet för att möta de önskemål och behov vi stött på under våra många år som leverantörer av kassasystem.
                </p>
              </div>
            </div>

            <div className="flex flex-col md:flex-row-reverse justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              
              <img src={bordskarta} alt="Göteborg Betalterminal" class="w-full md:w-1/2 p-12 object-cover rounded-lg shadow mb-4 md:mb-0 md:ml-4"/>

              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Koncentrera er på det ni gör bäst</h2>
                <p className="text-lg mb-4 xl:mx-24">Genom att låta oss ta ansvaret för ert kassasystem kan ni frigöra resurser till att vidareutveckla och förbättra kärnverksamheten. Vi tar hand om allt från installation till service och uppdatering av ert kassasystem. Med våra moderna lösningar kan ni vara trygga med att ni har tillgång till ett överskådligt, säkert och användarvänligt system. 
                                                    Låt oss ta hand om ert betalningssystem så att ni kan fokusera på er verksamhet och på att ge bra kundservice.
                </p>
                <div className="text-start mt-4 xl:mx-24"> {/* Added a margin-top for spacing. Adjust as per your need */}
                  <div className="">
                    <Link to="/AllOffersPage" className='flex justify-center bg-gradient-to-tr bg-[#8c52ff] text-xl text-white py-[8px] px-4 rounded-md xl:w-[220px] w-auto'> Se mer av våra paket </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={dela_nota} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:mr-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Engagemang och service</h2>
                <p className="text-lg mb-4 xl:mx-24">Vi är ett företag med gedigen erfarenhet inom service och kassasystem. Sedan vi startade 2010 har vi levererat högkvalitativa lösningar och god service till företag i Linköping och resten av landet.
                                                    Vi förstår vikten av att skapa hållbara kundrelationer och att tillhandahålla en god service som överträffar våra kunders förväntningar. Därför gör vi alltid vårt bästa för att se till att våra kunder är nöjda och har det som de behöver för att smidigt kunna driva sin verksamhet. Låt oss vara er partner och stötta er.
                </p>
              </div>
            </div>

            {/* Continue for the other sections */}

            <div className="mt-10 flex justify-center" data-aos="zoom-in-up">
            <button
              className="px-8 py-4 rounded-lg bg-[#8c52ff] text-white font-bold hover:bg-[#7F00FF] transition duration-200"
              onClick={() => window.location.href = "https://www.smartcash.se/kontakt"}
            >
              Kontakt oss
            </button>
            </div>

            </div>

}
         />
        </div>
    )
}
