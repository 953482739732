import React from 'react';
import Std_Master from '../Master/Std_Master';
import about_us from "../Gfx/about_us.png"

import About_Us_11 from "../Gfx/About_Us_11.png"
import About_Us_22 from "../Gfx/About_Us_22.png"
import About_Us_33 from "../Gfx/About_Us_33.png"
import About_Us_44 from "../Gfx/About_Us_44.png"

import Footer from "../Gfx/Footer.jpg"

import { Employees } from './Kontakt';


import logo_new_text from "../Gfx/logo_new_text.png";
import logo_new_text_white from "../Gfx/logo_new_text_white.png";

export default function Home() {
  return(
    <Std_Master selectedNavItem={3} mobileNavBarCssWhenNotScroll={"text-black"} logo_1={logo_new_text} logo={logo_new_text} mobileLogo={logo_new_text} mobileLogo_1={logo_new_text_white} NavCss={"text-black"} Title="." mobileNavBarCss={"text-black"} inner={
      <div className=" bg-white">
        
        <div className="lg:block hidden xl:mx-52 relative">
          <div className="grid grid-cols-2 ">

          <div className="col-span-2 font-bold mb-12">
            <p className="text-[65px] flex justify-center">Om Oss</p>
            <p className="text-[20px] flex justify-center text-gray-700 text-center">Ett sympatiskt och kompetent gäng som sätter service till kunden före allt.</p>
          </div>
            
            <div className="col-span-1 text-center h-[400px]">
              <div className="grid 2xl:grid-cols-4 grid-cols-1 mx-8 h-full rounded-lg py-12 2xl:gap-x-80 sm:bg-contain bg-cover bg-center bg-no-repeat" style={{backgroundImage: "url(" + About_Us_11 + ")"}}></div>
            </div>
            <div className="col-span-1 text-center px-4 py-4">
              <div className="my-16">
              <h2 className="2xl:text-[40px] text-[25px] flex justify-center mb-4">Bakgrund</h2>
              <div className="flex justify-center">
                <p className="text-[15px] text-gray-700 text-center ">Smart Cash grundades 2010 och är idag ett väletablerat bolag inom kassabranschen. Vi har genom åren byggt upp en gedigen kompetens för att tillgodose behovet av ett säkert och tillförlitligt betalsystem åt våra kunder.</p>
              </div>
              </div>
            </div>
            
            <div className="col-span-1 text-center px-4 py-4">
              <div className="my-16">
              <h2 className="2xl:text-[40px] text-[25px] flex justify-center mb-4">Affärside</h2>
              <div className="flex justify-center ">
              <p className="text-[15px] text-gray-700 text-center">Vi på Smart Cash vill erbjuda en unik kundupplevelse med förstklassig service och trygghet till alla våra kunder oavsett om man är en stor organisation eller en ägare av en liten livsmedelsbutik.
                                                                                        Vår strävan är att erbjuda de absolut senaste funktionerna och teknik som marknaden efterfrågar och genom det på ett effektivt sätt sänka våra kunders utgifter på betaltjänster.
                                                                                        Vi tror på att underlätta för våra kunder genom att samla alla kostnader på en faktura för en mer överskådlig ekonomisk bild och slippa få flertalet fakturor ifrån olika leverantörer för de nödvändiga tjänster som idag krävs i en verksamhet. Smidighet hela vägen ifrån beställning till driftsättande!</p>
              </div>
            </div>
            </div>
            <div className="col-span-1 text-center h-[400px]">
              <div className="grid 2xl:grid-cols-4 grid-cols-1 mx-8 h-full rounded-lg py-12 2xl:gap-x-80 sm:bg-contain bg-cover bg-center bg-no-repeat" style={{backgroundImage: "url(" + About_Us_22 + ")"}}></div>
            </div>

            <div className="col-span-1 text-center h-[400px]">
              <div className="grid 2xl:grid-cols-4 grid-cols-1 mx-8 h-full rounded-lg py-12 2xl:gap-x-80 sm:bg-contain bg-cover bg-center bg-no-repeat" style={{backgroundImage: "url(" + About_Us_33 + ")"}}></div>
            </div>
            <div className="col-span-1 text-center px-4 py-4">
              <div className="my-16">
              <h2 className="2xl:text-[40px] text-[25px] flex justify-center mb-4">Mål/Vision</h2>
              <div className="flex justify-center">
              <p className="text-[15px] text-gray-700 text-center ">Kunden i fokus är inte bara ett motto hos oss utan vår huvudregel! 
                                                                              Vi kommer alltid att jobba med långsiktiga relationer och att alla våra kunder skall känna sig prioriterade.
                                                                              Vårt unika servicetänk innebär en helt annan tillgänglighet till våra kunder än vad någon annan idag kan erbjuda på marknaden. Detta tänk är en del av vår företagspolicy som vi jobbar efter idag och alltid kommer fortsätta jobba efter.</p>
              </div>
            </div>
            </div>

            <div className="col-span-1 text-center px-4 py-4">
            <div className="my-16">
            <h2 className="2xl:text-[40px] text-[25px] flex justify-center mb-4">Vår kompetens</h2>
            <div className="flex justify-center">
              <p className="text-[15px] text-gray-700 text-center">Vi håller utbildningar med alla serviceteknikers kontinuerligt för att hålla dom uppdaterade inom nya lagar samt regler som tillkommer varje år. Vi består idag av ett team på 6st säljare samt 7st serviceteknikers. Hos oss utlovas en säkerhet för våra kunder!</p>
            </div>
          </div>
            </div>
            <div className="col-span-1 text-center h-[400px]">
              <div className="grid 2xl:grid-cols-4 grid-cols-1 mx-8 h-full rounded-lg py-12 2xl:gap-x-80 sm:bg-contain bg-cover bg-center bg-no-repeat" style={{backgroundImage: "url(" + About_Us_44 + ")"}}></div>
            </div>


            {/* <div className="col-span-1 text-center h-[400px]">
              <div className="grid 2xl:grid-cols-4 grid-cols-1 mx-8 h-full rounded-lg py-12 2xl:gap-x-80 bg-contain bg-center bg-no-repeat" style={{backgroundImage: "url(" + Footer + ")"}}></div>
            </div> */}

            <div className="col-span-2 text-center px-4 py-4">
            <div className="sm:my-16">
            <h2 className="2xl:text-[40px] text-[25px] flex justify-center mb-4">Vi lyssnar på våra kunder</h2>
            <div className="flex justify-center">
              <p className="text-[15px] text-gray-700 text-center">Vi är inte omöjliga! När våra kunder har önskemål på funktioner så lyssnar vi och tar till oss det och i mån av tid så brukar vi framställa dom inom 1-3 månader. Vårt kassasystem är idag anpassat mot verksamheter som Restaurang Bar & Nattklubb, Livsmedelsbutiker & Kiosker, Skönhetssalonger & Frisörer, Kemtvättar, Servicebutiker och Klädbutiker men klarar självklart av andra verksamheter, så tveka inte på att höra av er för att få en professionell rådgivning över vad just du/ni behöver.</p>
            </div>
          
            </div>
            </div>


          </div>
        </div>

        <div className="lg:hidden xl:mx-52 relative">
          <div className="grid grid-cols-2 ">
            
            
            <div className="sm:col-span-1 col-span-2 text-center px-4 py-4">
              <div className="sm:my-16">
              <h2 className="2xl:text-[40px] text-[25px] flex justify-center mb-4">Bakgrund</h2>
              <div className="flex justify-center">
                <p className="text-[15px] text-gray-700 text-center ">Smart Cash grundades 2010 och är idag ett väletablerat bolag inom kassabranschen. Vi har genom åren byggt upp en gedigen kompetens för att tillgodose behovet av ett säkert och tillförlitligt betalsystem åt våra kunder.</p>
              </div>
              </div>
            </div>
            <div className="sm:col-span-1 col-span-2 text-center h-[400px]">
              <div className="grid 2xl:grid-cols-4 grid-cols-1 mx-8 h-full rounded-lg py-12 2xl:gap-x-80 sm:bg-contain bg-cover bg-center bg-no-repeat" style={{backgroundImage: "url(" + About_Us_11 + ")"}}></div>
            </div>

            
            <div className="sm:col-span-1 col-span-2 text-center px-4 py-4">
              <div className="sm:my-16">
              <h2 className="2xl:text-[40px] text-[25px] flex justify-center mb-4">Affärside</h2>
              <div className="flex justify-center ">
              <p className="text-[15px] text-gray-700 text-center">Vi på Smart Cash vill erbjuda en unik kundupplevelse med förstklassig service och trygghet till alla våra kunder oavsett om man är en stor organisation eller en ägare av en liten livsmedelsbutik.
                                                                                        Vår strävan är att erbjuda de absolut senaste funktionerna och teknik som marknaden efterfrågar och genom det på ett effektivt sätt sänka våra kunders utgifter på betaltjänster.
                                                                                        Vi tror på att underlätta för våra kunder genom att samla alla kostnader på en faktura för en mer överskådlig ekonomisk bild och slippa få flertalet fakturor ifrån olika leverantörer för de nödvändiga tjänster som idag krävs i en verksamhet. Smidighet hela vägen ifrån beställning till driftsättande!</p>
              </div>
            </div>
            </div>
            <div className="sm:col-span-1 col-span-2 text-center h-[400px]">
              <div className="grid 2xl:grid-cols-4 grid-cols-1 mx-8 h-full rounded-lg py-12 2xl:gap-x-80 sm:bg-contain bg-cover bg-center bg-no-repeat" style={{backgroundImage: "url(" + About_Us_22 + ")"}}></div>
            </div>

            
            <div className="sm:col-span-1 col-span-2 text-center px-4 py-4">
              <div className="sm:my-16">
              <h2 className="2xl:text-[40px] text-[25px] flex justify-center mb-4">Mål/Vision</h2>
              <div className="flex justify-center">
              <p className="text-[15px] text-gray-700 text-center ">Kunden i fokus är inte bara ett motto hos oss utan vår huvudregel! 
                                                                              Vi kommer alltid att jobba med långsiktiga relationer och att alla våra kunder skall känna sig prioriterade.
                                                                              Vårt unika servicetänk innebär en helt annan tillgänglighet till våra kunder än vad någon annan idag kan erbjuda på marknaden. Detta tänk är en del av vår företagspolicy som vi jobbar efter idag och alltid kommer fortsätta jobba efter.</p>
              </div>
            </div>
            </div>
            <div className="sm:col-span-1 col-span-2 text-center h-[400px]">
              <div className="grid 2xl:grid-cols-4 grid-cols-1 mx-8 h-full rounded-lg py-12 2xl:gap-x-80 sm:bg-contain bg-cover bg-center bg-no-repeat" style={{backgroundImage: "url(" + About_Us_33 + ")"}}></div>
            </div>

            
            <div className="sm:col-span-1 col-span-2 text-center px-4 py-4">
            <div className="sm:my-16">
            <h2 className="2xl:text-[40px] text-[25px] flex justify-center mb-4">Vår kompetens</h2>
            <div className="flex justify-center">
              <p className="text-[15px] text-gray-700 text-center">Vi håller utbildningar med alla serviceteknikers kontinuerligt för att hålla dom uppdaterade inom nya lagar samt regler som tillkommer varje år. Vi består idag av ett team på 6st säljare samt 7st serviceteknikers. Hos oss utlovas en säkerhet för våra kunder!</p>
            </div>
          </div>
            </div>
            <div className="sm:col-span-1 col-span-2 text-center h-[400px]">
              <div className="grid 2xl:grid-cols-4 grid-cols-1 mx-8 h-full rounded-lg py-12 2xl:gap-x-80 sm:bg-contain bg-cover bg-center bg-no-repeat" style={{backgroundImage: "url(" + About_Us_44 + ")"}}></div>
            </div>
            
            <div className="col-span-2 text-center px-4 py-4">
            <div className="sm:my-16">
            <h2 className="2xl:text-[40px] text-[25px] flex justify-center mb-4">Vi lyssnar på våra kunder</h2>
            <div className="flex justify-center">
              <p className="text-[15px] text-gray-700 text-center">Vi är inte omöjliga! När våra kunder har önskemål på funktioner så lyssnar vi och tar till oss det och i mån av tid så brukar vi framställa dom inom 1-3 månader. Vårt kassasystem är idag anpassat mot verksamheter som Restaurang Bar & Nattklubb, Livsmedelsbutiker & Kiosker, Skönhetssalonger & Frisörer, Kemtvättar, Servicebutiker och Klädbutiker men klarar självklart av andra verksamheter, så tveka inte på att höra av er för att få en professionell rådgivning över vad just du/ni behöver.</p>
            </div>
          
            </div>
            </div>


          </div>

        </div>

            <div>
              <Employees />
            </div>
        </div>
        
    } />
  );
}
