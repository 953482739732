import React from 'react';

export default function Desc(props) {
  return(
    <div className='w-4/5 lg:w-full relative rounded-t-2xl shadow-2xl xl:shadow-none bg-white xl:bg-[#FFFFFF00] md:mt-52 mt-28 lg:mt-0 '>
      <div className=' py-6 text-left'>
        <div className='flex md:hidden flex-wrap w-full justify-center mt-4 '>
          
        </div>
        <div className='flex-wrap w-full justify-center mt-4 '>
        <h2 className="text-lg sm:text-4xl font-extrabold tracking-tight xl:text-white text-gray-600 px-4 text-center" data-v-0dd2ab72=""> {props.title} </h2>
        <p className="mt-4 text-lg sm:text-lg xl:text-white text-gray-600 font-medium px-4 text-center" data-v-0dd2ab72=""> {props.description} </p>

        </div>
      </div>
    </div>
  )
}
