import {React, useRef, useEffect, useState} from 'react'
import {setCookie ,getCookie, removeCookie, spliceCookie, spliceCookieCustomProduct } from '../Components/CookieHandler'
import { spliceCookieOffert } from '../Components/CookieHandler';
import { BsTrash } from "react-icons/bs";
import Inputfield_std from './Inputfield_std'
import Btn_std from './Btn_std'
import Group_16 from '../Gfx/Group_16.png'
import Group_105 from '../Gfx/Group_105.png'
import bg_kontakt_card_1 from '../Gfx/bg_kontakt_card_1.png'
/* import bg_contact_card from '../Gfx/bg_contact_card.png' */
import kontakt_logo from '../Gfx/kontakt_logo.png'
import emailjs from '@emailjs/browser'
import swal from 'sweetalert'

import { Link } from 'react-router-dom';


export default function Kontakt_card(props) {
  /* NEWW */

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [lastName, setLastName] = useState("")
  const [phone, setPhone] = useState("")
  const [msg, setMsg] = useState("")
  const [paket, setPaket] = useState([])

  const [img, setImg] = useState("")
  
  const [reload, setReload] = useState("")

  const [customProduct, setCustomProduct] = useState("")

  const [check, setCheck] = useState(false)

  function updatePaket(){
      var cookie = JSON.parse(getCookie("paket"));
      setPaket(cookie)
  }

  //console.log(customProduct);
    /* useEffect(() => {
        var cookie = JSON.parse(getCookie("paket"));
        if(Object.keys(cookie).length > 0 || cookie["customProduct"]){
            setCheck(true)
            cookie["customProduct"].map((obj, key) => {
                setCustomProduct(prevState => [...prevState, obj.name, "total:", obj.count]);
            })
        }
        setPaket(cookie);
    }, []); */

    /* function getOffert() {            
        var cookie = JSON.parse(getCookie("paket"));

            return(
            <div >
              <div className=" p-4 mb-[40px] shadow-lg hover:shadow-2xl ease-in-out duration-300 mx-4 rounded-lg relative grid grid-cols-6 " img={cookie["paket"].img} name={cookie["paket"].name} >
                  <div className="col-span-6">
                    <div className="flex justify-center text-gray-700 rounded-lg mx-2 mt-2 font-bold text-[18px] w-auto text-center">{cookie["paket"].name} </div>
                    <img className="object-cover object-center object-no-repeat " src={cookie["paket"].img} />
                    <div className="flex justify-center items-center">
                        <div onClick={()=>{setCookie("paket", "[]");setCheck(false);update()}} className="px-4 py-2 cursor-pointer hover:-translate-y-1 hover:scale-110" ><div className="py-1"><BsTrash /> </div></div>
                    </div>
                    
                  </div>

                
                </div>
            </div>
          )
    } */

    /* function getCustomProduct(){
    var cookie = JSON.parse(getCookie("paket"));
    return(cookie["customProduct"].map((obj, key) => {
                return(
                <div className=" m-1 rounded-lg" key={key}>
                    <div className="p-4 mx-5 shadow-lg ease-in-out hover:shadow-md rounded-lg lg:h-[120px] lg:w-[420px] xl:h-[120px] xl:w-[550px]  ">
                            <div className="grid grid-cols-6">
                            <div className="col-span-2 flex text-center items-center">
                                <img className=" bg-contain lg:h-[100px] lg:w-[170px]" src={obj.img} />
                            </div>
                            <div className="col-span-2 flex text-center items-center">
                                <div className="sm:text-[16px] text-sm">{obj.name}</div>
                            </div>
                            <div className="col-span-1 flex text-center items-center">
                                <div className="col-span-1 text-sm">Total <p className="">{obj.count}</p></div>
                            </div>
                            <div className="col-span-1 flex text-center items-center">
                                <div onClick={()=>{spliceCookieCustomProduct(obj.id); updatePaket(); update();}} className=" px-4 py-2 cursor-pointer hover:-translate-y-1 hover:scale-110" ><div className="py-1"><BsTrash /> </div></div>
                            </div>
                            </div>
                        
                    </div>
                </div>
                )
        }))
        
    } */

    
        const form = useRef();
      
        const sendEmail = (e) => {
          e.preventDefault();

          emailjs.sendForm('service_2e8damt', 'template_zzcapnx', form.current, 'hF2Szh2vLy0l-NBUc')
            .then((result) => {
                form.current.reset();
                clear();
            }, (error) => {
            });
        };

        function sweetalertNotification(){
            swal({
                title: "Vi har mottagit din order",
                text: "vi kontaktar dig så fort som möjligt",
                icon: "success",
            })
        }

        const clear = () => {
            window.location.reload();
            //removeCookie('paket')
        }

        function update(){
            setReload(()=> reload == "d" ? setReload("xd") : setReload("d"))
        }
        var cookie = JSON.parse(getCookie("paket"));
        


  return (
    <div>
            <div className={document.URL.indexOf("https://www.smartcash.se/kontakt") == 0 && paket.length != 0 ? "" : "hidden"}>{/* http://localhost:3000/kontakt  https://www.smartcash.se/kontakt*/} {/* "https://master.d3a0gj6i3mm8l7.amplifyapp.com/kontakt" */}
            
                {/* <h2 className={"py-4 mb-6 border-b text-sm font-medium flex justify-center text-[#F01E82] text-[20px]"}>Valda paket</h2> */}
                    
                {/* <Link to={document.URL.indexOf("http://localhost:3000/kontakt") - 1 } className='text-black mx-6 flex justify-self-center my-1 relative'>       Go back    </Link> */}
                <div className="relative mx-8 flex justify-center ">
                    <div className="grid lg:grid-cols-2 grid-cols-1">

                    {/* <div className="col-span-1">
                        <div className="row-span-1 text-center text-gray-600 text-[25px]"> Paket </div>
                        <div className=" flex flex-wrap text-gray-700 rounded-lg mx-2 mt-2 font-bold text-[18px] lg:w-[600px] w-full text-center">
                            {check ? getOffert() : ""}
                        </div>
                    </div> */}

                    {/* <div className="col-span-1">
                        <div className="row-span-1 text-center text-gray-600 text-[25px]"> Extra Produkter </div>
                            {check ? getCustomProduct() : ""}
                    </div> */}
                    
                    {/* <div className="ml-[300px]">
                        {getCustomProduct()}
                    </div> */}
                    </div>
                    
                    
                </div>
            </div>
            <form encType="multipart/form-data" method="post" ref={form} onSubmit={sendEmail}>
        <div className="flex justify-center items-center text-center">
            <div className="lg:block hidden relative bg-gray-100 w-[1000px] h-[700px] rounded-lg mt-10 bg-cover" style={{backgroundImage: "url(" + bg_kontakt_card_1 + ")"}}>
                    <div className="bg-gray-50 w-[800px] h-[500px] mt-[100px] mx-[100px] ease-in-out shadow-2xl rounded-lg py-6">
                            <div className="grid grid-cols-4 mx-12" /* style={{backgroundImage: "url(" + group_8 + ")"}} */>
                              <div className="absolute top-[-40px] left-0 z-0 w-[75px] h-[75px] mt-[100px] mx-[460px] rounded-full py-6 bg-cover bg-center boreder-2  " style={{backgroundImage: "url(" + kontakt_logo + ")"}}></div>
                                  <div className="flex justify-center col-span-4 text-center">
                                     {/*  <h2 className="flex justify-center text-gray-800 text-2xl font-bold tracking-tight mt-4">
                                          VILL DU VETA MER? <br /> SKICKA EN FÖRFRÅGAN IDAG.
                                      </h2>
                                  </div>
                                  <div className="flex justify-center col-span-4 pb-8">
                                      <h2 className="font-medium text-gray-700">
                                          Vi ringer up dig
                                      </h2> */}
                                      <div className="relative col-span-4 text-sm font-medium flex justify-center text-black text-[25px] py-8">Fyll i formuläret så kontaktar vi dig direkt</div>
                                  </div>
                        
                                  <div className="col-span-2 px-2 w-full">
                                  {/* <input type="file" id="img" name="img" accept="image/*"/> */}
                                      <input type="text"  value={cookie.length != 0 ? cookie["paket"].name : ""} name="paket" placeholder="hehe" className="hidden w-full text-xs shadow-md focus:ring-gray-300 focus:border-gray-300 border-gray-100 rounded-md py-2 px-2" />
                                      
                                      <Inputfield_std name="user_name"  value={(e)=>{setName(e)}} placeholder="Namn" className="w-full text-xs rounded-md px-2" />
                                  </div>
                                  <div className="col-span-2 px-2 w-full">    
                                      <Inputfield_std name="user_last_name"  value={(e)=>{setLastName(e)}} placeholder="Företagsnamn" className="w-full text-xs rounded-md px-2" />
                                  </div>
                                  
                                  <div className="col-span-2 px-2 w-full py-6">
                                      <Inputfield_std name="user_email"  value={(e)=>{setEmail(e)}} placeholder="E-post" className="w-full text-xs rounded-md px-2" />
                                  </div>
                                  <div className="col-span-2 px-2 w-full py-6">
                                      <Inputfield_std name="user_phone"  value={(e)=>{setPhone(e)}} placeholder="Telefonnummer" className="w-full text-xs rounded-md px-2" />
                                  </div>



                                  <div className="col-span-4 px-2 mx-2 py-6">
                                    <textarea name="user_message" onChange={(e)=>{setMsg(e.target.value)}} rows="4" placeholder="MEDDELANDE" className="w-[400px] resize-none p-2 text-xs shadow-md focus:ring-gray-300 focus:border-gray-300 border-gray-100 rounded-md px-2" />
                                      <div className="col-span-4 py-8 flex justify-center">
                                        <Btn_std type={"submit"} true={name.length && lastName.length && phone.length && email.length && msg.length != 0 ? false :true}  value={"Send"} ClassName={name.length && lastName.length && phone.length && email.length && msg.length != 0 ? " flex bg-[#8c52ff] text-white py-[6px] px-4 rounded-md w-[200px] font-medium pt-2 " : " opacity-25 cursor-not-allowed "} trigger={ name.length && lastName.length && phone.length && email.length && msg.length != 0 ? () =>  {sweetalertNotification(); setCookie("paket", "[]")} : ()=>{}} inner={"Skicka"} />
                                      </div>
                                  </div>
                              </div>
                    </div>

            </div>
        </div>
                    {/* </form> */}


            {/* Mobile */}
          <div className="relative lg:hidden flex justify-center px-2 bg-gray-50 mx-4 mt-8 ease-in-out hover:shadow-2xl rounded-lg py-6 bg-no-repeat bg-cover bg-center " style={{backgroundImage: "url(" + bg_kontakt_card_1 + ")"}}>
            {/* <form ref={form} onSubmit={sendEmail}> */}
                <div className="flex justify-center relative ">
                    <div className="absolute  top-[-70px]  z-0 w-[75px] h-[75px] rounded-full py-6 bg-cover bg-center " style={{backgroundImage: "url(" + kontakt_logo + ")"}}></div>
                    <div className="grid grid-cols-4 w-full md:mx-12 justify-center items-center  " /* style={{backgroundImage: "url(" + group_8 + ")"}} */>
                        <div className="flex justify-center col-span-4 text-center">
                            <h2 className="flex justify-center text-gray-800 text-2xl font-bold tracking-tight mt-4">
                                VILL DU VETA MER? <br /> SKICKA EN FÖRFRÅGAN IDAG.
                            </h2>
                        </div>
                        <div className="flex justify-center col-span-4 pb-8">
                            <h2 className="font-medium text-gray-700">
                                Vi ringer up dig
                            </h2>
                        </div>
                        <div className="col-span-2 md:px-2 md:mx-2 w-full">
                            <input type="text" value={paket} name="paket" placeholder="hehe" className="hidden w-full text-xs shadow-md focus:ring-gray-300 focus:border-gray-300 border-gray-100 rounded-md py-2 px-2" />
                            <Inputfield_std name="user_name"  value={(e)=>{setName(e)}} placeholder="First name" className="w-full text-xs rounded-md px-2" />
                        </div>
                        <div className="col-span-2 md:px-2 md:mx-2 w-full">
                            
                            <Inputfield_std name="user_last_name"  value={(e)=>{setLastName(e)}} placeholder="Last name" className="w-full text-xs rounded-md px-2" />
                        </div>
                        <div className="col-span-2 md:px-2 md:mx-2 w-full py-6">
                            <Inputfield_std name="user_email"  value={(e)=>{setEmail(e)}} placeholder="Email" className="w-full text-xs rounded-md px-2" />
                        </div>
                        <div className="col-span-2 md:px-2 md:mx-2 w-full py-6">
                            <Inputfield_std name="user_phone"  value={(e)=>{setPhone(e)}} placeholder="Phone" className="w-full text-xs rounded-md px-2" />
                        </div>
                        <div className="col-span-4 md:px-2 md:mx-2 py-6 ">
                                <textarea name="user_message" onChange={(e)=>{setMsg(e.target.value)}} rows="4" placeholder="Meddelande" className="w-full resize-none p-2 text-xs shadow-md focus:ring-gray-300 focus:border-gray-300 border-gray-100 rounded-md px-2" />
                            <div className="col-span-4 py-8 flex justify-center">
                            <Btn_std type={"submit"} true={name.length && phone.length && email.length != 0 ? false :true}  value={"Send"} ClassName={name.length && phone.length && email.length != 0 ? " flex bg-white text-[#8c52ff] py-[6px] px-4 rounded-md w-[200px] font-medium pt-2 " : " bg-gray-300 opacity-80 cursor-not-allowed "} trigger={ name.length && phone.length && email.length != 0 ? () => {sweetalertNotification()} : ()=>{}} inner={"Skicka"} />
                            </div>
                        </div>

                        
                    </div>
                </div>
          </div>
          </form>
                      
    </div>
  )
}
