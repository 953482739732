import {React, useRef, useEffect, useState} from 'react'
import Std_Master from '../../Master/Std_Master'
import Enox_ipad_iphon from '../../Gfx/Enox_ipad_iphon.png'
import top_image_new from "../../Gfx/top_image_new.webp";
import test_666_mobile from "../../Gfx/test_666_mobile.png";
import dela_nota from "../../Gfx/dela_nota.png";
import bordskarta from "../../Gfx/bordskarta.png";
import lager from "../../Gfx/lager.png";
import logo_new_text_black from '../../Gfx/logo_new_text_black.png'
import logo_new_text_white from '../../Gfx/logo_new_text_white.png'
import { FaCheckCircle } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';



export default function Kassa() {

  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
      
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    });
  }, []);
    return (
        <div className="bg-white">

          <Helmet replace={true}>
            <title>Kassasystem Göteborg | Kassaregister Göteborg | Smart Cash</title>
          </Helmet>
        
        <Std_Master 
         NavCss={"text-black"} TitleCss={"text-white text-[17px] ml-12 hidden xl:block 2xl:mt-[300px] 3xl:mt-[400px] custom-opacity rounded-lg xl:top-[220px]"}
         rubrikCss={"text-white"}
         logo_1={logo_new_text_black}
         mobileNavBarCssWhenScroll={"text-white"}
        mobileNavBarCssWhenNotScroll={"text-black"}
         logo={logo_new_text_black}
         /* mobileLogo={logo_new_text_white} mobileLogo_1={logo_new_text_white} */ mobileLogo={logo_new_text_black} mobileLogo_1={logo_new_text_white}
         rubrik={"Kassasystem Göteborg | Kassaregister Göteborg | Smart Cash"}
         Title={" ​Behöver ni som företag i Göteborg ett pålitligt och smidigt kassasystem eller kassaregister? Vi erbjuder skalbara och användarvänliga system som ni kan lita på "} 
         img={top_image_new}
        img_mobile={test_666_mobile}
         ShowButton={true}
        inner={



          <div className="md:container md:mx-auto my-12 px-4 md:px-0" data-aos="zoom-in-up">

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center my-8 bg-white rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <div className="text-center md:text-left p-4">
                <h2 className="text-2xl sm:text-3xl md:text-2xl font-bold mb-4">Tillbehör till er kassa</h2>
                <p className="text-base sm:text-lg md:text-lg ">Om man skall öppna en egen butik, restaurang eller salong kan det vara svårt att veta exakt vad man behöver och vad man har nytta av. Ibland växer insikten långsamt fram. Hos oss finns det flera tillbehör som går att köpa in direkt eller i efterhand och som kan vara praktiska för er. Självklart är de kompatibla med de kassasystem som vi tillhandahåller.</p>
              </div>
              <div className="p-4">
                <h2 className="text-xl sm:text-2xl md:text-2xl font-bold mb-4">Exempelvis har vi: </h2>
                <div className="grid grid-cols-1 gap-4">
                  <div>
                    <p className="my-2 text-lg md:text-md font-semibold flex items-center "><FaCheckCircle className="text-green-500 mx-1"/>Kvittoskrivare som även kan fungera som köksskrivare</p>
                    <p className="text-lg md:text-md font-semibold flex items-center "><FaCheckCircle className="mx-1 text-green-500"/>Scanner för att snabbt kunna läsa av streckkoder eller medlemskort</p>
                    <p className="my-2 text-lg md:text-md font-semibold flex items-center "><FaCheckCircle className="mx-1 text-green-500"/>Våg som kan väga lösgodis, frukt, nötter eller annat som säljs i lösvikt.</p>
                  </div>
                  <div>
                    {/* <p className="text-lg md:text-md font-semibold flex items-center space-x-2"><FaCheckCircle className="mx-1 text-green-500"/>Kemtvätt</p> */}
                  </div>
                  
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Skonhet.png"} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:mr-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Kassasystem Göteborg – anpassat efter er</h2>
                <p className="text-lg mb-4 xl:mx-24">Behöver ni ett pålitligt och smidigt kassasystem? Hos oss får ni det och mycket mer! Vi erbjuder skalbara och användarvänliga system som ni kan lita på. Krångliga kassor gör det svårt att hålla uppe god service då mycket tid och kraft går till att bara ta betalt. Dyrbar arbetstid slösas då personalen behöver anpassa sig efter ett dysfunktionellt kassasystem. 
                                                    Vi på Smart Cash tror på att betalsystemet skall vara anpassat efter er verksamhet och inte tvärtom. Hos oss hittar du flera färdiga paket som är framtagna efter de förfrågningar vi oftast stöter på inom respektive bransch. Självklart kan vi också ta fram helt skräddarsydda lösningar för ert företag!
                                                    Kontakta oss idag för att få veta mer och för att boka ett första möte!
                </p>
                <div className="text-start mt-4 xl:mx-24"> {/* Added a margin-top for spacing. Adjust as per your need */}
                  <div className="">
                    <Link to="/AllOffersPage" className='flex justify-center bg-gradient-to-tr bg-[#8c52ff] text-xl text-white py-[8px] px-4 rounded-md xl:w-[220px] w-auto'> Se mer av våra paket </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row-reverse justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={Enox_ipad_iphon} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:ml-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Kassaregister för ert företag i Göteborg</h2>
                <p className="text-lg mb-4 xl:mx-24">Att ha en kassa som passar verksamheten är viktigt. Den ska hålla för miljön där den används, vara lätt att förstå för både kunder och personal samt passa till det som den ska användas för. 
                                                    De kassasystem som vi tillhandahåller finns i flera olika utförande och är anpassade för att klara olika typer av miljöer. Exempelvis har vi det lilla kassasystemet Sam4s ER-230 som passar utmärkt för gatukök och torghandel. Den kassan går att använda med endast batteri vilket är bra då det inte finns några andra strömkällor. Dessutom har den en inbyggd kvittoskrivare och 15 varugrupper. Kunden har möjlighet att betala kontant, kort eller swish. 
                                                    Hos oss finns även den flexibla och lättplacerade kassan TritonPOS4 som passar utmärkt för den lilla butiken eller för garderober. Den har touch screen, inbyggd skrivare och kunddisplay som tydligt visar hur mycket som skall betalas.
                </p>
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/restaurang_enox_program.png"} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:mr-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Skalbart kassasystem</h2>
                <p className="text-lg mb-4 xl:mx-24">Många affärsrörelser har som mål att växa och utvidga sin verksamhet. Med våra skalbara kassasystem är det möjligt att lägga till nya kortterminaler och kassor utan att behöva byta ut hela systemet. 
                                                    Skaffa en större lokal, anställ mer personal och ta emot fler kunder utan tekniskt krångel. Att kunna använda samma kortterminaler och kassor gör transitionen sömnlös så att ni kan koncentrera er på det ni gör bäst - oavsett om det är att baka bröd, sälja skor eller klippa hår.

                  <div className="text-start mt-4"> {/* Added a margin-top for spacing. Adjust as per your need */}
                    <div className="">
                      <Link to="/AllOffersPage" className='flex justify-center bg-gradient-to-tr bg-[#8c52ff] text-xl text-white py-[8px] px-4 rounded-md xl:w-[220px] w-auto'> Se mer av våra paket </Link>
                    </div>
                </div>
                </p>
              </div>
            </div>

            <div className="flex flex-col md:flex-row-reverse justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={lager} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:ml-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Kassasystem Göteborg – bygg en hållbar verksamhet</h2>
                <p className="text-lg mb-4 xl:mx-24">Med våra användarvänliga system kommer din verksamhet att fungera smidigt utan onödiga uppehåll och hinder. Det är alltför vanligt att kortterminaler krånglar, vilket gör att flödet stannar upp. Det i sin tur kan orsaka köbildning. Vid rusningstider kan det vara rent förödande att inte kunna ta betalt då det kan leda till förlorade intäkter. Dessutom får kunderna ett dåligt intryck då betalningsmekaniskmen inte verkar fullt pålitlig.
                            Smidiga kortterminaler har istället motsatt effekt. Utan köer blir er service snabbare. På så vis har ni möjlighet att ta bättre hand om era kunder och bygga upp ett gott rykte.
                </p>
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              <img src={dela_nota} alt="Göteborg Betalterminal" className="w-full md:w-1/2 object-cover rounded-lg shadow mb-4 md:mb-0 md:mr-4"/>
              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Moderna och klassiska lösningar</h2>
                <p className="text-lg mb-4 xl:mx-24">För att kunna bygga sin rörelse på en stabil grund är det bra att försöka göra så mycket som möjligt rätt från början. Att ta betalt är en av grundpelarna i att driva ett företag. Betalningen behöver kunna skötas på ett enkelt sätt. Att kunna redovisa sin inkomst och tydligt se vad som har hänt under dagen är också mycket viktigt. Det ska vara lätt att sköta den ekonomiska biten. En kassa ska inte ta en massa tid i anspråk utan snarare hjälpa till så att er verksamhet kan flyta på smidigt. 
                                                    Våra kassasystem ger er en god start och låter er ha en bra överblick på era affärer. Vi erbjuder er okomplicerade och trygga lösningar till ett bra pris!

                <div className="text-start mt-4"> {/* Added a margin-top for spacing. Adjust as per your need */}
                  <div className="">
                    <Link to="/AllOffersPage" className='flex justify-center bg-gradient-to-tr bg-[#8c52ff] text-xl text-white py-[8px] px-4 rounded-md xl:w-[220px] w-auto'> Se mer av våra paket </Link>
                  </div>
                </div>
                </p>
              </div>
            </div>

            <div className="flex flex-col md:flex-row-reverse justify-between items-center my-8 bg-gray-100 rounded-lg p-6 shadow-lg" data-aos="zoom-in-up">
              
              <img src={bordskarta} alt="Göteborg Betalterminal" class="w-full md:w-1/2 p-12 object-cover rounded-lg shadow mb-4 md:mb-0 md:ml-4"/>

              <div className="text-center md:text-left">
                <h2 className="text-4xl font-bold mb-4 xl:mx-24">Vår erfarenhet och engagemang</h2>
                <p className="text-lg mb-4 xl:mx-24">Från starten 2010 har vi alltid haft som mål att vara ett serviceinriktat och engagerat företag som ser till våra kunder behov och får deras verksamhet att växa. Våra kunders framgång är en del av vår egen framgång. 
                                                    Vi är alltid intresserade av att höra från er. Genom en bra kommunikation får vi reda på vad våra kunder har för behov vilket gör att vi kan utveckla våra produkter och tjänster för att passa behoven bättre. Skulle det dyka upp några frågor eller problem så försöker vi lösa dem så fort som möjligt
                </p>
              </div>
            </div>






            

            {/* Continue for the other sections */}

            <div className='flex justify-center text-4xl font-semibold text-gray-700'>
                Kontakta oss idag för att få veta mer om oss, våra tjänster och våra produkter!
            </div>
            <div className="mt-10 flex justify-center" data-aos="zoom-in-up">
                <div>
                    <button
                    className="px-8 py-4 rounded-lg bg-[#8c52ff] text-white font-bold hover:bg-[#7F00FF] transition duration-200"
                    onClick={() => window.location.href = "https://www.smartcash.se/kontakt"}
                    >
                    Kontakt oss
                    </button>
                </div>
            </div>

            </div>

}
         />
        </div>
    )
}
