import React, {useState, useEffect, useRef} from 'react'
import { AiOutlineNotification } from "react-icons/ai";
import { FaLaptopCode, FaShippingFast } from "react-icons/fa";
import { BiSupport } from "react-icons/bi";
import { GiTeacher } from "react-icons/gi";

import bg_kontakt_card_1 from '../Gfx/bg_kontakt_card_1.png'


export default function DetailCardModalOffers(props) {

    const [paketProduct, setPaketProduct] = useState(props.productPaket)
    const [paketName, setPaketName] = useState(props.paket)

    return (
        <div className="fixed z-50 w-full h-full bg-[#00000050] " onClick={()=>{props.close()}}>
            <div className="sm:w-full sm:h-full flex justify-center items-center ">
                <div onClick={(e) => e.stopPropagation()} className="mx-4 grid grid-rows-7 sm:w-[495px] w-[650px] h-auto bg-gray-100 shadow-md rounded-lg bg-cover" style={{backgroundImage: "url(" +  bg_kontakt_card_1 + ")"}}>
                <div className="flex justify-center text-center mb-4 font-semibold text-[26px] m-2 p-2 text-gray-600 border-b-2"> Paketinnehåll {paketName.name} </div>
                    <div className="grid grid-cols-2 py-2">
                        <div className="col-span-2  ">

                            <div className="mx-7 shadow-lg py-2 my-2 ease-in-out rounded-lg border h-fit w-fit sm:w-[428px] bg-gray-50  transition duration-500 hover:shadow-2xl grid grid-cols-2 mb-8">
                                <div className="  flex justify-center">
                                    <div className="col-span-1 md:col-span-3 ">
                                        <p className=" col-span-2 mt-1 flex justify-center text-sm text-gray-600 font-bold">
                                            Tjänster:
                                        </p>
                                        <p className="mt-3 font-medium text-sm text-gray-600 flex ml-7" >
                                            <div className="mx-1 text-[16px] mt-[2px]">
                                                
                                                <AiOutlineNotification />
                                            </div>
                                            Anmälan till skatteverket
                                        </p>
                                        <p className="mt-1 font-medium text-sm text-gray-600 flex ml-7">
                                            <div className="mx-1 text-[16px] mt-[2px]">
                                                    <FaLaptopCode />

                                                
                                            </div>
                                            Programmering
                                        </p>
                                        <p className="mt-1 font-medium text-sm text-gray-600 flex ml-7">
                                            <div className="mx-1 text-[16px] mt-[2px]">
                                                
                                                    <GiTeacher />

                                                
                                            </div>
                                            Utbildning
                                        </p>
                                        <p className="my-1 font-medium text-sm text-gray-600 flex ml-7">
                                            <div className="mx-1 text-[16px] mt-[2px]">
                                                
                                                    <FaShippingFast />

                                                
                                            </div>
                                            Frakt
                                        </p>
                                    </div>
                                </div>
                                <div className="  flex justify-center">
                                    <div className="col-span-1 md:col-span-3 ">
                                        <p className=" col-span-2 mt-1 flex justify-center text-sm text-gray-600 font-bold">
                                            Support:
                                        </p>
                                        
                                        <p className="mt-3 font-medium text-sm text-gray-600 flex">
                                            <div className="mx-1 text-[21px]">
                                                <BiSupport />
                                            </div>
                                            Mån-Tors 08-22
                                        </p>
                                        <p className="mt-1 font-medium text-sm text-gray-600 flex">
                                            <div className="mx-1 text-[21px] mr-[28px]">
                                            </div>
                                            Fre-Lör 10-03
                                        </p>
                                        <p className="mt-1 font-medium text-sm text-gray-600 flex">
                                            <div className="mx-1 text-[21px] mr-[28px]">
                                            </div>
                                            Sön 10-22
                                        </p>                     
                                    </div>
                                </div>
                                

                            </div>
                             
                            
                            <div className="shadow-lg my-2 ease-in-out rounded-lg border h-auto w-fit sm:w-fit sm:mx-8 mx-2 bg-gray-50 transition duration-500 hover:shadow-2xl">
                            {paketProduct.map((obj, key)=>{
                                {return (
                                    <div className="flex justify-center items-center text-center mx-5 my-3 " key={key}>
                                        <div className="p-2 border duration-300 ease-in-out rounded-lg h-[90px] w-fit transition shadow-md bg-white" > {/* hover:-translate-y-1 */} {/* p-2 shadow-lg duration-300 ease-in-out rounded-lg h-[100px] w-[450px] border */}
                                            <div className="flex text-center  ">
                                                <div className="grid grid-cols-5 h-fit ">
                                                    <img className="col-span-2 bg-no-repeat bg-contain sm:h-[80px] sm:w-[145px]" src={obj.img}/>
                                                    <div className="col-span-2 text-gray-700 font-semibold text-start items-start mt-2 text-[15px] "> {obj.name} <p className="text-[13px] text-gray-400">{obj.desc}</p> </div>
                                                    <div className="col-span-1 text-gray-700 ml-6 mt-2 text-[15px]"> Antal <p className="">1</p> </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            })}

                        </div>
                        </div>

                
                        <div onClick={()=>{props.close()}} className="col-span-2 flex text-sm text-gray-500 justify-center items-center duration-300 ease-in-out hover:-translate-y-1 hover:scale-110">
                            <div  className=' flex justify-center text-white bg-gray-300 px-4 rounded-md w-[180px] border-6 py-[10px] cursor-pointer my-2' to=""> Avbryt </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}


