import React, {useState} from 'react'

import Product_card from '../../Components/Product_card'
import Std_Master from '../../Master/Std_Master'
import CardModal from '../../Components/CardModal';

import logo_new_text_white from "../../Gfx/logo_new_text_white.png";
import logo_new_text from "../../Gfx/logo_new_text.png";
import { Helmet } from 'react-helmet';

export default function Kvittohantering() {
    const [isOpen, setIsOpen] = useState(false)
    const [reload, setReload] = useState('')


  const _setIsOpen = (_enGongPrice, _price, _title, _img, _desc, _payAll, _show_checkbox) =>
  {
      setEnGongPrice(_enGongPrice)
      setPrice(_price)
      setTitle(_title)
      setImgs(_img)
      setDesc(_desc)
      setPayAll(_payAll)
      setShow_checkbox(_show_checkbox)
      setIsOpen(true);
  }

  const [enGongPrice, setEnGongPrice] = useState("");
  const [price, setPrice] = useState("");
  const [title, setTitle] = useState("");
  const [imgs, setImgs] = useState([""]);
  const [desc, setDesc] = useState("");

  const [payAll, setPayAll] = useState("");

  const [show_checkbox, setShow_checkbox] = useState(false);
  
  const isPopUp = () => {
      
      if(isOpen) {
          return < CardModal key={0} enGong={enGongPrice} hehe={imgs} hehe1={imgs} title={title} price={price} img={imgs} desc={desc} payAll={payAll} show_checkbox={show_checkbox} close={()=>{setIsOpen(false); setImgs("")}} />
      }
    }
    
  return (
    <div>
        {isPopUp()}
        <Std_Master logo_1={logo_new_text_white} logo={logo_new_text} mobileLogo={logo_new_text_white} mobileLogo_1={logo_new_text_white} classNameCss="w-full vh-full" vid={"https://smartcash-website.s3.eu-north-1.amazonaws.com/beauty_and_health_video.mp4"} inner={
            <div className="py-20 bg-white">

                <Helmet replace={true}>
                    <title>Smart Cash - Kvittohantering</title>
                </Helmet>

                <div className="z-10 max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <h1 className="text-4xl sm:ml-10 mx-5 font-extrabold text-warm-gray-900 sm:text-4xl lg:text-4xl">Kvittorullar</h1>
                    <p className="mt-6 sm:ml-11 ml-6 text-xl text-warm-gray-500 max-w-3xl"> Vi ser fram emot att höra från dig </p>
                </div>
                <div className="md:flex sm:flex flex-wrap -z-50 max-w-7xl mx-auto sm:px-6 lg:px-8 justify-center items-center">
                    
                    <Product_card trigger={()=>_setIsOpen("325","325 Kr", "Foodora Kvittorullar", ["https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Foodora_Kvittorullar.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Foodora_Kvittorullar.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Foodora_Kvittorullar.png"], <div> 18M x 57 Kassa (50st/Låda) <br/> Kan användas för kassan och Foodora terminaler.</div>)}
                     update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Foodora_Kvittorullar.png"} price="325Kr" title="Foodora Kvittorullar " ribbon="325 :-" description={<div> 18M x 57 Kassa (50st/Låda) <br/> Kan användas för kassan och Foodora terminaler.</div>}/>

                    <Product_card trigger={()=>_setIsOpen("450","450 Kr", "Kassaregister Kvittorullar", ["https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Kassaregister+Kvittorullar.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Kassaregister+Kvittorullar.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Kassaregister+Kvittorullar.png"], <div>57mm x 50m-(50st/Låda) <br/> Kan användas för kassan.</div>)}
                     update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Kassaregister+Kvittorullar.png"} price="450 Kr" title="Kassaregister Kvittorullar " ribbon="450 :-" description={<div>57mm x 50m-(50st/Låda) <br/> Kan användas för kassan.</div>}/>

                    <Product_card trigger={()=>_setIsOpen("350","350 Kr", "Kortterminal Kvittorullar", ["https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Kortterminal_Kvittorullar_1.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Kortterminal_Kvittorullar_1.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Kortterminal_Kvittorullar_1.png"], <div>Kan användas för kortterminaler.</div>)}
                     update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Kortterminal_Kvittorullar_1.png"} price="350 Kr" title="Kortterminal Kvittorullar " ribbon="350 :-" description={<div>18m Ej kvitto på köp (50st/Låda) <br/> Kan användas för kortterminaler.</div>}/>

                    <Product_card trigger={()=>_setIsOpen("450","450 Kr", "Pc-kassa Kvittorullar", ["https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Pc_kassa_Kvittorullar.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Pc_kassa_Kvittorullar.png", "https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Pc_kassa_Kvittorullar.png"], <div>Kan användas för kassan och köksskrivare.</div>)}
                     update={()=> reload == "d" ? setReload("xd") : setReload("d")} img={"https://smartcash-website.s3.eu-north-1.amazonaws.com/Gfx_2/Pc_kassa_Kvittorullar.png"} price="450 Kr" title="Pc-kassa Kvittorullar " ribbon="450 :-" description={<div>80mm x 57m (30st/Låda) <br/> Kan användas för kassan och köksskrivare.</div>}/>

                    
                </div>
            </div>
        } />
    </div>
  )
}
